import {
  Button,
  Col,
  Drawer,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse, ITableColumn } from "../../models";
import { ICustomField, ICustomValue, ICustoValuesObject, IEquipment } from "../../models/all";
import { Link, useLocation } from "react-router-dom";
import { getEqipmentXHR } from "../../store/reducers/all/allActionCreator";
import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import ProductForm, { IEquipmentForm } from "./EquipmentForm";
import api from "../../services";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import { allSlice } from "../../store/reducers/all";
import moment from 'moment'

const Products: React.FC = () => {

  // Hooks
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Variables
  const [search, set_search] = useState<string>("");
  const { getEqipmentStatus, equipments } = useAppSelector(state => state.allReducer);
  const [equipment, set_equipment] = useState<IEquipmentForm>();

  // Table
  const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IEquipment>[]>([
    {
      title: "Naziv",
      dataIndex: "name",
      render: (text: string, value: IEquipment) => (
        <Link to={`${value.id}`}>{value.name}</Link>
      ),
    },
    { title: "Proizvođač", dataIndex: "oem" },
    { title: "Serijski broj", dataIndex: "serial_number" },
    {
      title: "Kategorija",
      dataIndex: "category",
      render: (text: string, value: IEquipment) => {
        return value.category?.name
      }
    },
    { 
      title: "Vrijeme sljedeće kalibracije", 
      dataIndex: "next_calibration",
      render: (text: string, value: IEquipment) => {
        return moment(value.next_calibration).isValid() ? 
          moment(value.next_calibration).format('LLLL') : 
          "-"
      }
    },
    {
      title: "Kalibracijsko tijelo",
      dataIndex: "calibration_body",
    }
  ]);

  // Methods
  useEffect(() => {
    getEquipment();
  },[])


  const getEquipment = () => {
    getEqipmentXHR({ errorCallback: (data: any) => message.error("Greška na dohvati podataka...")},
      dispatch
    );
  };


  let filtredData: IEquipment[] = [];


  if (search) {
    filtredData = equipments?.filter((x) => x.name.toLowerCase().includes(search));
  } else {
    filtredData = equipments;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "18px 0",
        }}
      >
        <div className="spaceBetweenRow">
          <Typography.Title level={5}>Pregled opreme</Typography.Title>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={"Pretraži po nazivu."}>
              <InfoCircleOutlined />
            </Tooltip>
            <div style={{ width: 12 }} />

            <Input.Search
              onChange={({target: {value}}) => {
                if(!value){
                  set_search("");
                  return;
                }
                set_search(value.toLowerCase())
              }}
              style={{ marginRight: 18 }}
              allowClear
            />
          </div>
          <Button
            onClick={() =>
              set_equipment({
                id: 0,
                name: "",
                oem: "",
                type: "",
                category: null,
                serial_number: "",
                calibration_every: null,
                calibration_unit: "day",
                next_calibration: undefined,
                calibration_required: false,
                certificate_number: null,
                measuring_range: null,
                measurable_insecurity: null,
                calibration_body: ""
              })
            }
            type="primary"
          >
            Dodaj
          </Button>
        </div>
      </div>

      <Row>
        <Col span={24}>
          <Spin spinning={getEqipmentStatus === "loading"}>
            <Table
              rowKey={(item) => item.id}
              dataSource={filtredData}
              columns={COLUMNS}
            />
          </Spin>
        </Col>
      </Row>

      {/* <Row>
        <Col span={12}>
          <Spin spinning={getEqipmentStatus === "loading"}>
            <List
              dataSource={filtredData}
              loading={getEqipmentStatus === "loading" || loading}
              header={
                <div className="spaceBetweenRow">
                  <Typography.Title level={4}>Oprema</Typography.Title>
                </div>
              }
              renderItem={(item: IEquipment, index: number) => (
                <List.Item
                  actions={[
                    <Popconfirm
                      onConfirm={() => deleteItem(item.id)}
                      title="Nastaviti sa akcijom..."
                      cancelText="Odustani"
                    >
                      <Button danger type="dashed">
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>,
                    <Button
                      onClick={() =>
                        set_equipment({
                          id: item.id,
                          name: item.name,
                          oem: item.oem,
                          category: item.category.id,
                          serial_number: item.serial_number,
                          calibration_every: item.calibration_every,
                          calibration_unit: item.calibration_unit,
                          next_calibration: moment(item.next_calibration).isValid() ? moment(item.next_calibration) : item.next_calibration,
                          calibration_required: item.calibration_required,
                          certificate_number: item.certificate_number,
                          measuring_range: item.measuring_range,
                          measurable_insecurity: item.measurable_insecurity
                        })
                      }
                    >
                      <EditOutlined />
                    </Button>,
                  ]}
                >
                  <List.Item.Meta title={item.name} />
                </List.Item>
              )}
            />
          </Spin>
        </Col>
        <Col span={12}></Col>
      </Row> */}
      <Drawer
        title={"Oprema"}
        width={540}
        destroyOnClose={true}
        children={
          <ProductForm 
            equipment={equipment} 
            close={() => set_equipment(undefined)} 
          />
        }
        visible={!!equipment}
        onClose={() => set_equipment(undefined)}
      />
    </div>
  );
};

export default Products;

export function generateCustomFieldValues(customFields: ICustomField[], customValues: ICustomValue[]) : ICustoValuesObject {


  let tmp: ICustoValuesObject = {};
  customFields.forEach(x => {
    tmp[x.key] = customValues.find(y => y.key === x.key)?.value || "" 
  })

  return tmp
}