import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import { ITestModelFormValues, ITry } from "../../../../../../models/all";
import { saveModelFormsXHR } from "../../../../../../store/reducers/all/allActionCreator";

interface IProps {
    triesPrepare: ITry[];
    triesOther: ITry[];
    modelFormID: number,
    setActiveModelForm: (modelForm: ITestModelFormValues | undefined) => void;
}

const AddTriesButton: React.FC<IProps> = ({triesPrepare, triesOther, setActiveModelForm, modelFormID}) => {


  const { testingTest } = useAppSelector((state) => state.allReducer);

  const [modelForm, setModelForm] = React.useState<ITestModelFormValues | undefined>(testingTest?.model_forms.find(x => x.id === modelFormID));
  const [loading, set_loading] = React.useState<boolean>(false);


  const dispatch = useAppDispatch();

  useEffect(() => {
    setModelForm(testingTest?.model_forms.find(x => x.id === modelFormID));
  }, [modelFormID])



    const add = (tries: ITry[]) => {
      set_loading(true);
        const uniqueTries = new Set(tries.map((obj) => obj.try));
const count = uniqueTries.size;
        let tmp = [...tries];
        for (let index = 0; index < tries.filter(x => x.try === 1).length; index++) {
          const element: ITry = {
            ...tmp[index],
            value: null,
            try: count + 1,
            check: false,
          };
          tmp.push(element);
        }

        return tmp;
    }

    const onSave = () => {

      if(modelForm === undefined) {
        message.error("Dogodila se greška...");
        return;
      }
          
            let body: ITestModelFormValues= {
                ...modelForm,
                prepare_tries: add(triesPrepare),
                other_custom_fields_tries: add(triesOther)
              }
              saveModelFormsXHR(
                {
                  body,
                  id: modelForm.id || 0,
                  errorCallback: () => {
                    set_loading(false);
                    message.error("Dogodila se greška...");
                  },
                  successCallback: ({results}) => {
                    setActiveModelForm(results.model_forms.find(x => x.id === modelForm.id) || undefined);
                    set_loading(false);
                  }
                },
                dispatch
              );
      
            return;

    }


    return  <Button
    onClick={onSave}
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      border: "1px dashed #d9d9d9",
      margin: "3px",
      height: "100%",
      width: 52,
      cursor: "pointer",
    }}
  >
    <PlusCircleOutlined style={{ fontSize: 21 }} />
  </Button>;
    };

export default AddTriesButton;