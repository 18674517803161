import {
  Checkbox,
  DatePicker,
  Descriptions,
  Divider,
  Input,
  message,
  PageHeader,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { updateTestingTestXHR } from "../../../../../store/reducers/all/allActionCreator";
import {  ITry } from "../../../../../models/all";

export type UpdateData = {
  environmental_conditions: string;
  equipment: number[];
  test_date: string;
  tested_by: number[];
  note: string;
  is_success: boolean;
  testing_success: boolean,
  tries: ITry[];
  color: string,
  prepare_tries: ITry[], 
  other_custom_fields_tries: ITry[]
};

const Header: React.FC = () => {

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { test_id } = useParams();

  // Variables
  const [note, set_note] = useState("");
  const { equipments, accounts, testingTest, updateTestingTestStatus } = useAppSelector(state => state.allReducer);
  
  // Methods
  useEffect(() => {
    set_note(testingTest?.note || "");
  }, [testingTest?.note])

  const updateData = (values: Partial<UpdateData>) => {

    if(!testingTest){
      return;
    }

    updateTestingTestXHR({
      body: { ...values},
      id: test_id || 0,
      errorCallback: () => {
        message.error("Dogodila se greška...")
      },
      successCallback: () => {
      }
    }, dispatch)
  };

  const equipmentCategories = useMemo(() => {
    return testingTest?.test.equipment_categories.map(category => category.id)
  }, [testingTest?.test.equipment_categories])



  return (
    <div>
      <PageHeader
        className="page-header"
        style={{ padding: 0 }}
        onBack={() => navigate(-1)}
        title={testingTest?.test.name}
        subTitle={""}
      >
        <Spin spinning={updateTestingTestStatus === "loading"}>
        {/* Tags */}
        <Space style={{ marginBottom: 8, marginTop: -4 }}>
          {/* Material type */}
          { testingTest?.test_product.material_type && <Tag className="tag">{ testingTest.test_product.material_type.name }</Tag> }
          {/* Exam method */}
          { testingTest?.test.exam_method && <Tag className="tag">{ testingTest.test.exam_method.name }</Tag> }
          {/* Color */}
          { testingTest?.color && <Tag className="tag">{ testingTest.color.name }</Tag> }
        </Space>

        {/* Description */}
        <Descriptions size="small" column={3}> 
          {/* Uvjeti sredine */}
          <Descriptions.Item label={"Uvjeti sredine"}>
            <Typography.Text
              editable={{
                onChange: (value) =>
                  updateData({ environmental_conditions: value }),
              }}
            >
              {testingTest?.environmental_conditions || ""}
            </Typography.Text>
          </Descriptions.Item>

          {/* Ispitna oprema */}
          <Descriptions.Item label={"Ispitna oprema"}>
            <Select
              mode="tags"
              maxTagCount={1}
              value={testingTest?.equipment.map(x => x.id) || []}
              onChange={(v: any) => {
                updateData({ equipment: v })
              }}
                filterOption={(input, option) => {
                  if (typeof option?.children === "string") {
                    return (option?.children as string)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch
                style={{ minWidth: 180 }}
            >
              {
                equipments
                  .filter(equipment => equipmentCategories?.includes(equipment.category?.id || -1))
                  .map((x) => {
                    return <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>;
                  })
              }
            </Select>
          </Descriptions.Item>

          {/* Datum ispitivanja */}
          <Descriptions.Item label={"Datum ispitivanja"}>
            <DatePicker
              format="DD.MM.YYYY"
              value={testingTest?.test_date ? moment(testingTest.test_date) as any : null}
              onChange={(a, b) => {
                let d = a?.format("YYYY-MM-DD") || "";
                if (d) {
                  updateData({ test_date: d });
                }
              }}
            />
          </Descriptions.Item>

          {/* Ispitano od */}
          <Descriptions.Item label={"Ispitano od"}>
            <Select
              mode="tags"
              maxTagCount={1}
              onChange={(v: any) => {
                updateData({ tested_by: v })
              }}
              value={testingTest?.tested_by.map(x => x.id) || []}
                filterOption={(input, option) => { 
                  if (typeof option?.children === "string") {
                    return (option?.children as string)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }
                  return false;
                }}
              showSearch
              style={{ minWidth: 180 }}
            >
              {accounts?.map((x) => {
                return <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>;
              })}
            </Select>
          </Descriptions.Item>

          {/* Uspješno testiranje */}
          <Descriptions.Item style={{fontSize: 12}} label={"Testiranje zadovoljava zahtjeve standarda"}>
            <Checkbox checked={testingTest?.is_success}  onChange={() => {
              updateData({is_success: !testingTest?.is_success});
            }}  />
          </Descriptions.Item>
          <Descriptions.Item style={{fontSize: 12}} label={"Uspješno testiranje"}>
            <Checkbox checked={testingTest?.testing_success}  onChange={() => {
              updateData({testing_success: !testingTest?.testing_success});
            }}  />
          </Descriptions.Item>

          {/* Napomena */}
          <Descriptions.Item label={"Napomena"} span={13}>
            <Input.TextArea
              rows={1}
              style={{ width: 320 }}
              value={note}
              onChange={value => {
                set_note(value.target.value);
              }} 
              onBlur={() => {
                updateData({note: note});
              }} 
            />
          </Descriptions.Item>    
        </Descriptions>
        </Spin>
      </PageHeader>
      
    </div>
  );
};

export default Header;
