import { Button, Form, Input, message, Select, Spin, TreeSelect } from "antd";
import React, { useEffect } from "react";
import { createTreeSelect } from "../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addTestClustersXHR, getEquipmentCategoryXHR, getMaterialTypesXHR, getProductCategoriesXHR, getTestMethodsXHR, updateTestClusterXHR } from "../../store/reducers/all/allActionCreator";

interface IProps {
  test: undefined | ITestClusterForm;
  close: () => void;
}

export interface ITestClusterForm{
  id: number;
  name: string;
  product_category: number | null;
  exam_method: number | null;
  equipment_categories: number[] | null;
  copy?: number
}


export const RULES = [{required: true, message: "Ovo polje je obavezno"}]

const {Option} = Select;

const TestClusterForm: React.FC<IProps> = ({ test, close }) => {


  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  useEffect(() => { 
    getProductCategoriesXHR({}, dispatch);
    getMaterialTypesXHR({}, dispatch);
    getTestMethodsXHR({}, dispatch);
    getEquipmentCategoryXHR({}, dispatch);
  }, []);

  const {getProductCategoriesStatus, getMaterialTypesStatus, getTestMethodsStatus, 
    getEquipmentCategoryStatus, productCategories, materialTypes, testMethods, equipmentCategories, addTestClustersStatus} = useAppSelector(state => state.allReducer);

  const onFinish = async (values: ITestClusterForm) => {
    // console.log(values, "KKKK");
    // console.log(test);
    if(test?.id !== 0){
      updateTestClusterXHR({id: test?.id.toString(), body: values,  errorCallback: () => message.error("Dogodila se greška na spremanju"), successCallback: () => close()}, dispatch);
      return;
    }
    addTestClustersXHR({body: {...values, copy: test?.copy}, errorCallback: () => message.error("Dogodila se greška na spremanju"), successCallback: () => close()}, dispatch)
  }

  if(getProductCategoriesStatus === "loading" ||
    getMaterialTypesStatus === "loading" ||
    getTestMethodsStatus === "loading" ||
    getEquipmentCategoryStatus === "loading"
  ){
  return <Spin />
  }


  return <div>
    <Form initialValues={test} layout="vertical" onFinish={onFinish} form={form} >
      <Form.Item rules={RULES} name={"name"} label="Naziv" >
      <Input  />
      </Form.Item>
      <Form.Item rules={RULES}  name="product_category" label="Kategorija proizvoda">
      <Select >
      {productCategories.map(x => <Option key={x.id} value={x.id} >{x.name}</Option>)}
      </Select>
      </Form.Item>
      <Form.Item rules={RULES}  name="exam_method" label="Metoda">
      <Select>
      {testMethods.map(x => <Option key={x.id} value={x.id} >{x.name}</Option>)}
      </Select>
      </Form.Item>
      <Form.Item rules={RULES}  name="equipment_categories" label="Kategorija opreme">
      <Select mode="multiple" allowClear>
      {equipmentCategories.map(x => <Option key={x.id} value={x.id} >{x.name}</Option>)}
      </Select>
      </Form.Item>
      <Form.Item >
        <Button loading={addTestClustersStatus === "loading"} type="primary" htmlType="submit" >Spremi</Button>
      </Form.Item>
    </Form>
  </div>
};

export default TestClusterForm;
