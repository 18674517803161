import { Button, Form, Input, message, Select, Spin } from "antd";
import { useState } from "react";
import { ICustomField } from "../../models/all";

export const CUSTOM_FIELD_HALF_KEY = "_customFieldHalfKey"; // dobijem formu za custom fieldsovima kao objekt sve te kljuceve moram strpat u array pomocou ovoga znam koji kljuc pripada za custom field

interface ITypeName {
  [key: string]: string | undefined
}

export const TYPES = ["text", "number"];
export const TYPE_NAME: ITypeName = {
  text: "tekst",
  number: "broj"
}

export interface IPartCategoryForm {
  name: string;
}

interface IProps {
  onSave: (value: ICustomField[]) => void;
  selectableList?: {id:number, name: string}[];
  selectableListName: string,
  customFieldUpdateIndex: number;
  xyForm: any;
  disabled?: boolean,
  exsitingFields: ICustomField[];
  reset: () => void
}

const CustomFieldForm: React.FC<IProps> = ({ onSave, selectableList, xyForm, reset, selectableListName, disabled = false, customFieldUpdateIndex, exsitingFields }) => {
  const [loading, set_loading] = useState<boolean>(false);

  const onFinish = (values: ICustomField) => {
    let tmp = [...exsitingFields];
    if(customFieldUpdateIndex === -1){
      values.key = values.name.replace(/\s/g, "")
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      .replace(/\s{2,}/g, " ")
      .replace("`", "")
      .replace("'", "")
      .replace('"', "");
      values.key = values.key + new Date().getTime().toString() + "_" + CUSTOM_FIELD_HALF_KEY ;
      if(exsitingFields?.filter(x => x.key === values.key)?.length > 0){
        message.error("Kolona postoji"  + " " + CUSTOM_FIELD_HALF_KEY + "!"); 
        return;
      }
      tmp.push(values);
    }else{
      values.key = exsitingFields[customFieldUpdateIndex].key;
      tmp[customFieldUpdateIndex] = values;
    }
    onSave(tmp);
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          initialValues={{ type: "string" }}
          onFinish={onFinish}
          form={xyForm}
        >
          <Form.Item
            rules={[
              { required: true, message: "Ovo polje je obavezno" },
            ]}
            name="name"
            label="Naziv polja"
          >
            <Input />
          </Form.Item>
          {selectableList && (
            <Form.Item
              rules={[
                { required: true, message: "Ovo polje je obavezno" },
              ]}
              name={"selectableList"}
              label={"t(selectableListName)"}
            >
              <Select mode="multiple" >
                {selectableList.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            rules={[
              { required: true, message: "Ovo polje je obavezno" },
            ]}
            name="type"
            label="Tip podatka koji će se unositi u polje"
          >
            <Select>
              {TYPES.map((x) => (
                <Select.Option value={x} key={x}>
                  {TYPE_NAME[x]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="spaceBetweenRow">
            <Form.Item noStyle>
              <Button type="primary" loading={loading} htmlType="submit">
                {"Spremi"}
              </Button>
            </Form.Item>
            <Form.Item noStyle>
              <Button onClick={reset} type="dashed">
                {"Poništi"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default CustomFieldForm;
