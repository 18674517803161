import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Form,
  Input,
  message,
  Popconfirm,
  Select,
  Space,
  Table as T,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ICustomField, ModelItemType } from "../../models/all";
import { allSlice } from "../../store/reducers/all";
import { getUnitOfMesureXHR } from "../../store/reducers/all/allActionCreator";
import {
  CUSTOM_FIELD_HALF_KEY,
  TYPES,
  TYPE_NAME,
} from "../Settings/CustomFieldForm";
import { IValues } from "./ModelItem";

interface IProps {
  data: ICustomField[] | undefined;
  onFinish: (values: IValues, del: boolean, close: () => void, fieldsType: ModelItemType) => void;
  modelFieldsType: ModelItemType;
  title: string,
  delLoading?: boolean;
  hideButtons?: boolean;
}

export const UNITS_OF_MESURE = ["mm", "kg", "%", "N/mm2", "min", "g", "°C"];

const Table: React.FC<IProps> = ({
  data,
  onFinish,
  modelFieldsType,
  delLoading,
  hideButtons,
  title
}) => {
  const { updateTestModelFormStatus, indexToUpdateTestModel, units } = useAppSelector(
    (state) => state.allReducer
  );
  const [formVisible, set_formVisible] = useState<ICustomField>();
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  useEffect(() => {
    getUnitOfMesureXHR({
      errorCallback: () => message.error("Dogodila se greška kod dohvata mjernih jedinica.")
    }, dispatch)
  }, [])

  useEffect(() => {
    form.setFieldsValue({ type: "number" });
  }, [formVisible]);

  const COLUMNS =  modelFieldsType !== "standard" ? [
    {
      title: "Naziv",
      dataIndex: "name",
      render: (text: string, value: ICustomField) => {
        return (
          <Space>
          <Typography.Text strong>
            {value.friendly_id}
          </Typography.Text>
          <Typography.Text >
            {value.name}
          </Typography.Text>
          </Space>
        );
      },
    },
    {
      title: "Formula",
      dataIndex: "formula"
    },
    {
      title: "Tip",
      dataIndex: "type",
      render: (text: string) => TYPE_NAME[text],
    },
    {
      title: "Mjerna jedinica",
      dataIndex: "uom",
    },
    {
      title: "",
      dataIndex: "",
      render: (text: string, value: ICustomField, index: number) => {
        if(hideButtons) return null;
        return (
          <div style={{ width: 120 }}>
            <Button
              onClick={() => {
                unstable_batchedUpdates(() => {
                  if (index !== undefined) {
                    //globalni index za upravljanjem update ili dodavanjem
                    dispatch(
                      allSlice.actions.setIndexOfItemForTestModelForm(index)
                    );
                    //
                    set_formVisible({
                      name: value.name,
                      type: value.type,
                      key: value.key,
                      uom: value.uom,
                      friendly_id: value.friendly_id,
                      formula: value.formula
                    });

                    //postavi formu
                    form.setFieldsValue({
                      name: value.name,
                      type: value.type,
                      key: value.key,
                      uom: value.uom,
                      friendly_id: value.friendly_id,
                      formula: value.formula
                    });
                  } else {
                    message.error("Nesto nije dobro...");
                  }
                }, []);
              }}
            >
              <EditFilled />
            </Button>
            <Popconfirm
              onConfirm={() => onDeleteColumn(index)}
              title={"Nastavi sa akcijom"}
              cancelText="Odustani"
              onVisibleChange={(a) => {
                //u istom potezu pozvati akciju da postavi index i odmah da brise ne radi iz razloga sto se sve ne update u isto vrijeme
                //stoga na promjenu vidljivosti postavljamo index koji je za brisati
                if(a){
                  dispatch(allSlice.actions.setIndexOfItemForTestModelForm(index));
                }else{
                  dispatch(allSlice.actions.setIndexOfItemForTestModelForm(index));
                }
              }}
            >
              <Button danger type="dashed" style={{ marginLeft: 9 }}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ] : [
    {
      title: "Naziv",
      dataIndex: "name",
      render: (text: string, value: ICustomField) => {
        return (
          <Space>
          <Typography.Text strong>
            {value.friendly_id}
          </Typography.Text>
          <Typography.Text >
            {value.name}
          </Typography.Text>
          </Space>
        );
      },
    },
    {
      title: "Tip",
      dataIndex: "type",
      render: (text: string) => TYPE_NAME[text],
    },
    {
      title: "Mjerna jedinica",
      dataIndex: "uom",
    },
    {
      title: "",
      dataIndex: "",
      render: (text: string, value: ICustomField, index: number) => {
        if(hideButtons) return null;
        return (
          <div style={{ width: 120 }}>
            <Button
              onClick={() => {
                unstable_batchedUpdates(() => {
                  if (index !== undefined) {
                    //globalni index za upravljanjem update ili dodavanjem
                    dispatch(
                      allSlice.actions.setIndexOfItemForTestModelForm(index)
                    );
                    //
                    set_formVisible({
                      name: value.name,
                      type: value.type,
                      key: value.key,
                      uom: value.uom,
                      friendly_id: value.friendly_id,
                      formula: value.formula
                    });

                    //postavi formu
                    form.setFieldsValue({
                      name: value.name,
                      type: value.type,
                      key: value.key,
                      uom: value.uom,
                      friendly_id: value.friendly_id,
                      formula: value.formula
                    });
                  } else {
                    message.error("Nesto nije dobro...");
                  }
                }, []);
              }}
            >
              <EditFilled />
            </Button>
            <Popconfirm
              onConfirm={() => onDeleteColumn(index)}
              title={"Nastavi sa akcijom"}
              cancelText="Odustani"
              onVisibleChange={(a) => {
                //u istom potezu pozvati akciju da postavi index i odmah da brise ne radi iz razloga sto se sve ne update u isto vrijeme
                //stoga na promjenu vidljivosti postavljamo index koji je za brisati
                if(a){
                  dispatch(allSlice.actions.setIndexOfItemForTestModelForm(index));
                }else{
                  dispatch(allSlice.actions.setIndexOfItemForTestModelForm(index));
                }
              }}
            >
              <Button danger type="dashed" style={{ marginLeft: 9 }}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onDeleteColumn = (index: number) => {
    if (index === -1 || index === undefined) {
      message.error("Nesto ne ide kako treba...");
      return;
    }

    onFinish(
      {
        name: "",
        type: undefined,
        key: "",
        uom: "",
        modelFieldsType: modelFieldsType, // ne koristi se vise ja mislim
        friendly_id: "",
        formula: "",
      },
      true,
      () => {
        //callback koji se opali nakon uspjesno obavljenog posla
        dispatch(allSlice.actions.setIndexOfItemForTestModelForm(-1));
      },
      modelFieldsType
    );
  };

  const _onFinish = (values: ICustomField) => {

    if (indexToUpdateTestModel === -1) {
      // ukoliko se radi o dodavanju postaviti key
      let tmpName = values.name;
      values.key = tmpName
        .replace(/\s/g, "")
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .replace(/\s{2,}/g, " ")
        .replace("`", "")
        .replace("'", "")
        .replace(" ", "")
        .replace('"', "");

      values.key = values.key + CUSTOM_FIELD_HALF_KEY + new Date().getTime();
      values.friendly_id = "no_id";

      if (data && data.filter((x) => x.key === values.key).length > 0) {
        message.error("Ključ ovog polja već postoji!");
        return;
      }
    } else {
      //ukoliko se radi o update-u postavlja se stari kljuc
      if (formVisible) {
        values.key = formVisible.key;
      }
    }


    const newValues: IValues = { ...values, modelFieldsType: modelFieldsType };

    onFinish(newValues, false, () => {
      set_formVisible(undefined);
      dispatch(allSlice.actions.setIndexOfItemForTestModelForm(-1));
    },
    modelFieldsType
    );

    form.resetFields();
  };

  const onOpenDrawer = () => {
    dispatch(allSlice.actions.setIndexOfItemForTestModelForm(-1));
    unstable_batchedUpdates(() => {
      set_formVisible({
        name: "",
        key: "",
        type: undefined,
        uom: "",
        friendly_id: ""
      });
      form.setFieldsValue({
        name: "",
        key: "",
        type: undefined,
        uom: "",
        friendly_id: ""
      });
    }, []);
  };

  return (
    <>
      <div className="spaceBetweenRow">
        <div>
        <Typography.Title level={5}>
          {title}
        </Typography.Title>
        </div>
       {!hideButtons && <Button style={{ marginBottom: 12 }} onClick={onOpenDrawer}>
          Dodaj
        </Button>}
      </div>
      <T loading={delLoading} dataSource={data} columns={COLUMNS} />
      <Drawer
        children={
          <Form onFinish={_onFinish} layout="vertical" form={form}>
            <Form.Item
              rules={[{ required: true, message: "Ovo polje je obavezno" }]}
              name="name"
              label="Naziv polja"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "Ovo polje je obavezno" }]}
              name="type"
              label="Tip podatka koji će se unositi u polje"
            >
              <Select>
                {TYPES.map((x) => (
                  <Select.Option value={x} key={x}>
                    {TYPE_NAME[x]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{display: "none"}} name={"friendly_id"}>
              <Input />
            </Form.Item>
            <Form.Item
              name="uom"
              label="Mjerna jedinica"
            >
              <Select>
                {units.map((x) => (
                  <Select.Option value={x.name} key={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {modelFieldsType !== "standard" &&<Form.Item
              name="formula"
              label="Formula"
            >
              <Input />
            </Form.Item>}
            <div className="spaceBetweenRow">
              <Form.Item noStyle>
                <Button
                  type="primary"
                  loading={updateTestModelFormStatus === "loading"}
                  htmlType="submit"
                >
                  {"Spremi"}
                </Button>
              </Form.Item>
            </div>
          </Form>
        }
        title={modelFieldsType === "standard" ? "Zahtjevi standarda" : "Rezultati"}
        onClose={() => set_formVisible(undefined)}
        destroyOnClose={false}
        forceRender={true}
        visible={!!formVisible}
      />
    </>
  );
};

export default Table;
