import { Button, Divider, List, message, Modal, Table, Typography } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks";
import { IApiResponse } from "../../models";
import { ITestModelForm } from "../../models/all";
import api from "../../services";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import ModelItem from "./ModelItem";

interface IProps {
  model_form: ITestModelForm;
}

const TestModelFormHistory: React.FC<IProps> = ({ model_form }) => {
  const [loading, set_loading] = React.useState(true);
  const [models, set_models] = React.useState<ITestModelForm[]>([]);
  const [model, set_model] = React.useState<ITestModelForm>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getHistory();
  }, []);

  const getHistory = async () => {
    set_loading(true);
    let token = await localStorage.getItem("token");
    try {
      let response = await api().get<IApiResponse<ITestModelForm[]>>(
        `laboapp/model_form/update_history`,
        {
          params: {
            model_form: model_form.id,
          },
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      set_models(response.data.results);

      set_loading(false);
    } catch (error: any) {
      set_loading(false);
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }
      message.error("Greska na dohvat...");
    }
  };
  return (
    <div>
        <Table dataSource={models}
            pagination={false}
            loading={loading}
            columns={[
                {
                    title: "Verzija obrasca",
                    render: (text, record) => <Button onClick={() => set_model(record)} type="link">{record.version}</Button> ,
                },
                {
                    title: "Nastala",
                    render: (text, record) =>  moment(record.created_at).format("LLL")
                },
                {
                    title: "Umirovljena",
                    render: (text, record) =>  moment(record.updated_at).format("LLL")
                }
            ]}
        />
        <Modal
            footer={null}
            visible={!!model}
            onCancel={() => set_model(undefined)}
            title={`Detalji verzije - ${model?.name}`} 
        >
           {!!model && <>
            <div className="spaceBetweenRow">
                    <div style={{ paddingTop: 12, paddingBottom: 0 }}>
                      <Typography.Text style={{ opacity: 0.8 }}>
                        Oznaka obrasca
                      </Typography.Text>
                      <Typography.Title level={4} style={{ marginTop: 2 }}>
                        {model.label}
                      </Typography.Title>
                      <Typography.Text style={{ opacity: 0.8 }}>
                        Verzija obrasca
                      </Typography.Text><br />
                      <Typography.Text style={{ marginTop: 2 }}>
                        {model.version}
                      </Typography.Text>
                      <div />
                      <Typography.Text type="secondary" style={{ marginTop: 2 }}>
                        {moment(model.datetime).format("LLL")}
                      </Typography.Text>
                    
                    </div>
                  </div>
                  <Divider />
                  <ModelItem hideButtons model={model} /></>}
        </Modal>
    </div>
  );
};

export default TestModelFormHistory;
