import { Descriptions, Typography } from "antd";
import React from "react";
import { ICustomField, IRefValues } from "../../../../../models/all";

interface IProps{
    refValues: IRefValues[],
    fields: ICustomField[]
}

const StandardRequiremnts:React.FC<IProps> = ({refValues, fields}) => {



    return <div>
        <Typography.Title level={4}>Zahtjevi standarda</Typography.Title>

        <Descriptions>
                {fields.map((y) => (
                <Descriptions.Item key={y.key} label={<><strong>{y.friendly_id}</strong><Typography.Text style={{marginLeft: 3}}>{`${y.name} ${y.uom ? `(${y.uom})` : ''}`}</Typography.Text></>}>
                    <div style={{display: "flex"}}>
                        <Typography.Text>{ refValues.find(ref => ref.key === y.key)?.value || ""}</Typography.Text>
                    </div>
                 
                </Descriptions.Item>
                ))}
            </Descriptions>
    </div>
}

export default StandardRequiremnts;