import { Tabs } from "antd";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { Routes } from '../../navigation/routes'

const { TabPane } = Tabs;

const Settings: React.FC = () => {

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Variables
  const { token } = useAppSelector(state => state.userReducer.user)
 
  // Methods
  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };

  return (
    <>
      <div>
        <Tabs
          activeKey={location.pathname.split("/")[3]}
          onChange={onTabChange}
        >
          {Routes(!!token).children
            ?.find((x) => x.path === "settings")
            ?.children?.map((x) => (
              x.name !== null &&
              <TabPane
                tab={
                  <Link to={x.path}>
                    { x.name }
                  </Link>
                }
                key={x.path}
              />
            ))}
        </Tabs>

        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Settings;
