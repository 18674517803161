import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../models";
import { IUser, ICurrentUser } from "../../../models/all";

const initialUser: ICurrentUser = {
  token: { access: "", refresh: "" },
  account: {
    id: 0,
    email: "",
    name: "",
    phone_number: ""
  },
};

// Define a type for the slice state
interface UserState {
  user: ICurrentUser;
  loginStatus: string;
  registrationStatus: string;
  changePasswordStatus: string;
  meStatus: string;
  confirmAndSetPasswordStatus: string;
}

// Define the initial state using that type
const initialState = {
  user: initialUser,
  loginStatus: "",
  registrationStatus: "",
  changePasswordStatus: "",
  meStatus: "loading",
  confirmAndSetPasswordStatus: "",
} as UserState;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      state.user = initialUser;
    },
    login(state) {
      state.loginStatus = "loading";
    },
    loginSucceess(state, action: PayloadAction<IApiResponse<ICurrentUser>>) {
      state.user = action.payload.results;
      state.loginStatus = "";
    },
    loginFail(state, action: PayloadAction<string>) {
      state.loginStatus = "";
    },
    // -----

    registration(state) {
      state.registrationStatus = "loading";
    },
    registartionSuccess(state, action: PayloadAction<IApiResponse<ICurrentUser>>) {
      state.registrationStatus = "";
      state.user = action.payload.results;
    },
    registrationFail(state, action: PayloadAction<string>) {
      state.registrationStatus = action.payload;
    },
    // -----

    changePassword(state) {
      state.changePasswordStatus = "loading";
    },
    changePasswordSuccess(state, action: PayloadAction<IApiResponse<string>>) {
      state.changePasswordStatus = "";
    },
    changePasswordFail(state, action: PayloadAction<string>) {
      state.changePasswordStatus = action.payload;
    },

    // -----

    me(state) {
      state.meStatus = "loading";
    },
    meSuccess(state, action: PayloadAction<IApiResponse<ICurrentUser>>) {
      state.user = action.payload.results;
      state.meStatus = "";
    },
    meFail(state, action: PayloadAction<string>) {
      state.meStatus = action.payload;
    },

    // -----
  },
});

// Thunk function

export default userSlice.reducer;
