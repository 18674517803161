import { Row, Col, message, Typography } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  ICustomField,
  ICustomValue,
  ITestModelForm,
  ModelItemType,
} from "../../models/all";
import { updateTestModelFormXHR } from "../../store/reducers/all/allActionCreator";
import Table from "./Table";

interface IProps {
  model: ITestModelForm;
  hideButtons?: boolean;
}

export type IValues = {
  modelFieldsType: ModelItemType;
} & ICustomField;

const ModelItem: React.FC<IProps> = ({ model, hideButtons }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [delLoading, set_delLoading] = useState<boolean>(false);
  const { indexToUpdateTestModel } = useAppSelector(
    (state) => state.allReducer
  );

  const getFormulaLetter = (fieldsType: ModelItemType) => fieldsType === "others" ? "C" : "B";

  const onFinish = (values: IValues, del: boolean, close: () => void, fieldsType: ModelItemType) => {


  let getLength = (fieldsType: ModelItemType) => fieldsType === "others" ? tmp_model.other_custom_fields.length : (tmp_model.prepare?.length ||  0)


    let tmp_model = { ...model };
    let arr = [];
    let val: ICustomField = {
      key: values.key,
      name: values.name,
      uom: values.uom,
      type: values.type,
      formula: values.formula,
      friendly_id: indexToUpdateTestModel === -1 ? getFormulaLetter(fieldsType) + (getLength(fieldsType)+1) : values.friendly_id
    };

    arr = fieldsType === "others" ? [...tmp_model.other_custom_fields] : (tmp_model.prepare ? [...tmp_model.prepare] : [])
    let index = indexToUpdateTestModel;
    if (index === -1 && !del) {
      arr.push(val);
    } else if (!del) {
      arr[index] = val;
    } else {
      set_delLoading(true);
      arr.splice(index, 1);
    }
    if(fieldsType === "others"){
      tmp_model.other_custom_fields = arr;
    }else{
      tmp_model.prepare = arr;
    }
    updateTestModelFormXHR(
      {
        errorCallback: () => message.error("Dogodila se greška."),
        id: model.id.toString(),
        body: { ...tmp_model, test: id },
        successCallback: () => {set_delLoading(false);message.success("Uspješno...");close()},
      },
      dispatch
    );
  };

  return (
    <>
      <Table
        delLoading={delLoading}
        onFinish={onFinish}
        title="Priprema"
        modelFieldsType="prepare"
        data={model.prepare}
        hideButtons={hideButtons}
      />
       <Table
        delLoading={delLoading}
        onFinish={onFinish}
        modelFieldsType="others"
        title="Rezultati"
        data={model.other_custom_fields}
        hideButtons={hideButtons}
      />
    </>
  );
};

export default ModelItem;
