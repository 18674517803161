import { Button, Divider, Form, Input, message, Select, Spin, Typography } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { ICategory, ITestCluster } from '../../../models/all';
import { copyTestsXHR, getProductsXHR, getStandardsXHR } from '../../../store/reducers/all/allActionCreator';

type IProps = {
    product: number,
    standard: number,
    set_selectedStandard: React.Dispatch<React.SetStateAction<ICategory>>,
    tests: number[],
    closeModal: () => void
}

export interface ICopyTests {
    from_product: number,
    product: number,
    standard: number,
    tests: number[],
    copy_ref_values: boolean
}

const CopyTests = ({ product, standard, set_selectedStandard, tests, closeModal }: IProps) => {

    // Hooks
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Variables
    const model: Partial<ICopyTests> = { product, standard, copy_ref_values: false }
    const { products, getProductsStatus, standards, getStandardsStatus, copyTestsStatus } = useAppSelector(state => state.allReducer)

    // Methods
    useEffect(() => {
        getProductsXHR({
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka..."),
        }, dispatch);
        getStandardsXHR({
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka..."),
        }, dispatch);
    }, [])

    const onFinish = (values: ICopyTests) => {
        const body: ICopyTests = {
            ...values,
            from_product: product,
            tests
        }
        copyTestsXHR({
            body,
            errorCallback: () => message.error("Dogodila se greška pri kopiranju testova..."),
            successCallback: () => successCallback(body)
        }, dispatch)
    }

    function successCallback(form: ICopyTests){
        if(product === form.product){
            // Product hasn't been changed, no redirects, setting new standard
            set_selectedStandard(standards.find(item => item.id === form.standard)!)
        } else{
            // Product has been changed, redirect to new product
            navigate(`/products/${form.product}/specifications`)
        }
        closeModal()
        message.success("Testovi su uspješno kopirani!")
    }

    return (
        <div style={{ height: '100%', paddingBottom: 48 }}>
            <Form initialValues={model} layout="vertical" form={form} onFinish={onFinish}>

                <div style={{ paddingBottom: 6, opacity: .75 }}>
                    Kopiraj test ili testove za neki drugi standard istog proizvoda ili za drugi proizvod
                </div>

                {/* Options */}
                <div className="stepsContent" style={{ paddingBottom: 0 }}>
                    <Spin spinning={getProductsStatus === 'loading' || getStandardsStatus === 'loading' || copyTestsStatus === 'loading'}>
                        <Form.Item 
                            name="product" 
                            label="Proizvod"
                            rules={[{ required: true, message: "Ovo polje je obavezno" }]} 
                        >
                            <Select 
                                showSearch 
                                style={{ textAlign: 'left' }}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                { products.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                )) }
                            </Select>
                        </Form.Item>

                        <Form.Item 
                            name="standard" 
                            label="Standard"
                            rules={[{ required: true, message: "Ovo polje je obavezno" }]} 
                        >
                            <Select 
                                showSearch 
                                style={{ textAlign: 'left' }}
                                filterOption={(input, option) =>
                                    (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                { standards.map((item, index) => (
                                    <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                )) }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="copy_ref_values"
                            valuePropName="checked"
                        >
                            <Checkbox style={{ float: 'left' }}>
                                Kopiraj referentne vrijednosti
                            </Checkbox>
                        </Form.Item>
                    </Spin>
                </div>

                {/* CTA */}
                <div style={{ float: 'right', paddingTop: 20 }}>
                    <Form.Item>
                        <Button 
                            htmlType="submit"
                            type='primary' 
                            // disabled={checkedList.length === 0}
                        >
                            Kopiraj
                        </Button>
                    </Form.Item>
                    
                </div>
            </Form>
        </div>
    )
}

export default CopyTests;