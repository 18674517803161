import { userSlice } from ".";
import { AppDispatch } from "../..";
import { IApiResponse, IDataToApi } from "../../../models";
import { ICurrentUser, IUser } from "../../../models/all";
import api from "../../../services";

export const loginXHR = async (data: any, dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.login());

    const response = await api().post<IApiResponse<ICurrentUser>>(
      `/auth/login/`,
      data.body
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }

    localStorage.setItem("refreshToken", response.data.results.token.refresh);
    localStorage.setItem("token", response.data.results.token.access);
    localStorage.setItem("user", JSON.stringify(response.data));

    dispatch(userSlice.actions.loginSucceess(response.data));
  } catch (error: any) {
    //
    if (data.errorCallback) {
      data.errorCallback(error?.response?.data, error);
    }

    dispatch(userSlice.actions.loginFail(error.message));
  }
};

export const registrationXHR = async (data: any, dispatch: AppDispatch) => {
  try {
    dispatch(userSlice.actions.registration);
    const response = await api().post<IApiResponse<IUser>>(
      `register/`,
      data.body
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.registartionSuccess);
  } catch (error: any) {
    //
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.registrationFail(error.message));
  }
};

export const changePasswordXHR = async (data: any, dispatch: AppDispatch) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.changePassword());
    const response = await api().put<IApiResponse<string>>(
      `accounts/accounts/2/change_password/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(userSlice.actions.changePasswordSuccess(response.data));
  } catch (error: any) {
    //
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.changePasswordFail(error.message));
  }
};

export const logoutXHR = async (data: null, dispatch: AppDispatch) => {
  const token = await localStorage.getItem("token");
  dispatch(userSlice.actions.logout());

  try {
    const response = await api().post<IApiResponse<string>>(`auth/logout/`, {
      headers: { Authorization: "Bearer " + token },
    }) 
  } catch (error: any) {
 
    console.log(error);
  }

  localStorage.removeItem("refreshToken");
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export const meXHR = async (
  data: Partial<IDataToApi<null, IApiResponse<ICurrentUser>>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(userSlice.actions.me());
    const response = await api().get<IApiResponse<ICurrentUser>>(`/accounts/me/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token },
    });
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    const str = localStorage.getItem("user");

    if(str){
      let tmp: IApiResponse<ICurrentUser> = JSON.parse(str);
      dispatch(userSlice.actions.meSuccess(tmp));

    }
  } catch (error: any) {
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(userSlice.actions.meFail(error.message));
  }
};


 