import {
  Col,
  Divider,
  message,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getTestClusterDetailsXHR,
} from "../../store/reducers/all/allActionCreator";
import ModelForms from "./ModelForms";
import StandardRequirements from "./StandardRequirements";

export type TypesOfAction = "adding" | "copying" | "updateing" | "deleteing";

const TestCluster: React.FC = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const { testClusterDetails, getTestClusterDetailsStatus } = useAppSelector(
    (state) => state.allReducer
  );

  useEffect(() => {
    getTestClusterDetailsXHR(
      { id, errorCallback: () => message.error("Greska na dohvat...") },
      dispatch
    );
  }, []);


  if (getTestClusterDetailsStatus === "loading") {
    return <Spin />;
  }

  // Content
  const equipmentCategoriesTags = (
    <>
      {testClusterDetails?.equipment_categories?.map((item, index) => (
        <Tag className="tag" key={index}>
          {item.name || ""}
        </Tag>
      ))}
    </>
  );



  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: 12,
          }}
        >
          <Typography.Title level={3} style={{ marginRight: 20 }}>
            {testClusterDetails?.name}
          </Typography.Title>

          <Space size="large">
            <Info
              label="Metoda ispitivanja: "
              value={
                <Typography.Text style={{ fontWeight: 500 }}>
                  {testClusterDetails?.exam_method.name || ""}
                </Typography.Text>
              }
            />
            <Info label="Kategorije opreme: " value={equipmentCategoriesTags} />
          </Space>
        </div>

        <Row >
              <Col span={24}>
                <StandardRequirements />
              </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={24}>
            {testClusterDetails &&   <ModelForms testClusterDetails={testClusterDetails} />}
          </Col>
        </Row>
      </div>
      
    </>
  );
};

export default TestCluster;

interface IInfoProps {
  label: string;
  value: string | JSX.Element;
}

const Info: React.FC<IInfoProps> = ({ label, value }) => {
  return (
    // <div className="spaceBetweenRow">
    //   <div style={{ fontWeight: "bold" }}>{label}</div>
    //   <div style={{ width: 30 }} />
    //   <div>{value}</div>
    // </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
        paddingBottom: 12,
      }}
    >
      <div style={{ opacity: 0.8 }}>{label}</div>
      <div>{value}</div>
    </div>
  );
};
