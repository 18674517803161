import { DeleteOutlined, PlusCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import {
  Checkbox,
  Col,
  message,
  Row,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { ICustomValue, IRefValues, ITry } from "../../../../../../models/all";
import InputResult from "../InputResult";
import CustomCheckbox from "./CustomCheckbox";

export type BelongToType = "prepare" | "other";

interface IProps{
  tries: ITry[],
  result: ICustomValue[],
  belongsTo: BelongToType,
  updateData:(tries: ITry[], belongsTo: BelongToType) => void,
  refValues: IRefValues[],
  title: string
}

// Constants
const COLUMN_WIDTH = 380
const NAME_WIDTH = 200



const Roles: React.FC<IProps> = ({tries, result, updateData, belongsTo, refValues, title}) => {

  // Hooks

  // Variables
 const [matrix, set_matrix] = useState<ITry[][]>([]);

  // Methods
 useEffect(() => {
    const _matrix: ITry[][] = [];

    tries.forEach((obj) => {
      let found = false;
      for (let i = 0; i < _matrix.length; i++) {
        if (_matrix[i][0].try === obj.try) {
          _matrix[i].push(obj);
          found = true;
          break;
        }
      }
      if (!found) _matrix.push([obj]);
    });

    unstable_batchedUpdates(() => {
      set_matrix(_matrix);
    }, [])
  }, [tries]);

 



  const removeTry = (_try: number) => {
    let _tries = [...tries].filter(x => x.try !== _try);
   updateData(_tries, belongsTo)

    }

  const _updateData = (_try: number, key: string, value: string | number, check: boolean) => {
    let tmp_res = [...tries];
    let index = tmp_res.findIndex((x) => x.key === key && x.try === _try);
    if (index === -1) return;
    tmp_res[index] = {
      ...tmp_res[index],
      value,
      check: check == null ? tmp_res[index].check : check,
      input_type: "manual"
    };
    updateData(tmp_res, belongsTo);
  };


  return (
    <div >
        <Typography.Title style={{width: "100%", textAlign: "center"}} level={4}>{title}</Typography.Title>
      <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: "auto",
          minHeight: 56
        }}
      >
        {matrix.map((x, xIndex) => {
          return (
            <div key={xIndex} style={{ maxWidth: COLUMN_WIDTH, padding: 6 }}>

              {/* Titles */}
              <div style={{ width: '100%', maxWidth: COLUMN_WIDTH, marginBottom: 6 }}>
                <div style={{ marginLeft: xIndex <= 0 ? (NAME_WIDTH + 2) : 2 }}>
                  <Typography.Text strong>Pokušaj { xIndex + 1 }</Typography.Text>
                  <DeleteOutlined onClick={() => {
                    removeTry(x[0].try);
                  }}  style={{marginLeft: 12, fontSize: 18}}/>
                </div>
              </div>

              {x.sort((a, b) => (a.friendly_id > b.friendly_id) ? 1 : -1).map((y: ITry, yIndex: number) => {
                return (
                  <div
                    key={yIndex}
                    style={{
                    display: "flex",
                    justifyContent: "flex-start", 
                    alignItems: "center", 
                    marginRight: xIndex <= 0 ? 0 : 16, 
                    padding: "2px 0" 
                  }}
                >
                    {/* Title */}
                    {
                      xIndex <= 0 &&
                      <div className="triesLabels"  style={{ width: NAME_WIDTH, paddingRight: 8, display: "flex", justifyContent: "flex-start", alignItems: "center" }}  >
                        <Typography.Text  strong>
                          {y.friendly_id}
                        </Typography.Text>
                        <Tooltip title={`${y.name} ${refValues.find(ref => ref.key === y.key)?.value || ""} ${y.uom ? `(${y.uom})` : ""} `} >
                        <Typography.Text style={{marginLeft: 3}} className="ellipsis">
                          {`${y.name} ${refValues.find(ref => ref.key === y.key)?.value || ""} ${y.uom ? `(${y.uom})` : ""} `} 
                        </Typography.Text>
                        </Tooltip>
                        <Tooltip title={y?.formula || ""}>
                          <Typography.Text style={{marginLeft: 3}} className="ellipsis">
                            {y?.formula || ""}
                          </Typography.Text>
                        </Tooltip>
                        </div>
                    }

                    {/* Input */}
                    <InputResult
                      save={(v) => {
                        _updateData(y.try, y.key, v.value === null ? "" : v.value, y.check);
                      }}
                      key={y.key + xIndex}
                      value={y}
                      field={y}
                      inputStyle={{ width: 120 }}
                    />
                    {/* Checkbox */}
                    <CustomCheckbox
                      defaultValue={y.check}
                      value={y.check}
                      onChange={(value: boolean) => {
                        _updateData(y.try, y.key, y.value === null ?  "" : y.value, value);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
        {/* New try CTA */}
      </div>
    </div>
    </div>
  );
};

export default Roles;

const createMatrix = (tries: ITry[]) => {
  let _matrix: ITry[][] = [];
  tries.forEach((obj) => {
    let found = false;
    for (let index = 0; index < _matrix.length; index++) {
      const element = _matrix[index];
      if (element[0].try === obj.try) {
        element.push(obj);
        found = true;
        break;
      }
    }
    if (!found) _matrix.push([obj]);
  });
  return _matrix;
}