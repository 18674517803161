import { Form, Input } from "antd";
import { ICustomField, ICustomValue } from "../../models/all";

const { Item } = Form;

interface IProps {
  selectedCategory?: number;
  existingsColumns?: ICustomValue[]; // ako ne postoje kolone radi se o dodavanju ukoliko postoje potrebno ih ej merge sa ostalim custom kolonama
  customFields: ICustomField[];
}

const GenerateForCustomFields: React.FC<IProps> = ({
  customFields,
  selectedCategory,
  existingsColumns,
}) => {
  if (!existingsColumns) {
    // not update in form
    return (
      <FieldsOnAdding
        customFields={customFields}
        selectedCategory={selectedCategory}
      />
    );
  }

  let values: ICustomField[] = customFields.filter(
    (x) => !existingsColumns.filter((y) => y.key === x.key).length
  );

  return <>{null}</>;
};

interface IPropsFieldsOnAdding {
  selectedCategory?: number;
  customFields: ICustomField[];
}

const FieldsOnAdding: Function = ({
  selectedCategory,
  customFields,
}: IPropsFieldsOnAdding): JSX.Element[] | null => {
  let items: JSX.Element[] | null = [];
  if (selectedCategory) {
    items = customFields
      .filter((x) => x.selectableList?.includes(selectedCategory))
      .map((x, index) => (
        <Item key={index} name={x.key} label={x.name}>
          <Input />
        </Item>
      ));

      return items;
  }

  items = customFields.map((x, index) => (
    <Item key={index} name={x.key} label={x.name}>
      <Input />
    </Item>
  ));

  if (items && !items.length) {
    items = null;
  }

  return items;
};

export default GenerateForCustomFields;
