import { Button, Form, Input, message, TreeSelect } from "antd";
import React from "react";
import { createTreeSelect } from "../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { SelectTreeItem } from "../../../../models";
import { createMaterialTypeXHR, updateMaterialTypeXHR } from "../../../../store/reducers/all/allActionCreator";

export interface IMaterialTypeForm {
  parent_id: null | number;
  id: number;
  name: string;
}

interface IProps {
  materialType: IMaterialTypeForm;
  close: () => void;
}

const MaterialTypeForm: React.FC<IProps> = ({ close, materialType }) => {
  const { materialTypes, createMaterialTypeStatus, updateMaterialTypeStatus } = useAppSelector(
    (state) => state.allReducer
  );
  const dispatch = useAppDispatch();

  const onFinish = (values: { name: string; parent: null | number | undefined }) => {
    if (materialType.id === 0) {
      createMaterialTypeXHR(
        {
          body: {...values, parent: values.parent || null},
          errorCallback: () => message.error("Dogodila se greška..."),
          successCallback: () => {
            close();
          },
        },
        dispatch
      );
      return;
    }

    updateMaterialTypeXHR({
        id: materialType.id,
        body: {...values, parent: values.parent || null},
        errorCallback: () => message.error("Dogodila se greška..."),
        successCallback: () => {
          close();
        },
    }, dispatch)
  };

  return (
    <Form layout="vertical" onFinish={onFinish} initialValues={{parent: materialType.parent_id?.toString(), name: materialType.name}}>
      <Form.Item label="Nadtip" name="parent">
        <TreeSelect
          allowClear
          treeData={createTreeSelect(
            materialTypes.map((x) => ({
              title: x.name,
              value: x.id.toString(),
              parent_id: x.parent_id?.toString() || null,
            })),
            null
          )}
        />
      </Form.Item>

      <Form.Item label="Tip" name="name">
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          loading={createMaterialTypeStatus === "loading" || updateMaterialTypeStatus === "loading"}
        >
          Spremi
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MaterialTypeForm;
