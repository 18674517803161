import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { Button, Col, Drawer, List, message, Popconfirm, Row, Typography } from "antd"
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse } from "../../models";
import { IStandard, ITestMethod } from "../../models/all";
import api from "../../services";
import { allSlice } from "../../store/reducers/all";
import { getStandardsXHR, getTestMethodsXHR } from "../../store/reducers/all/allActionCreator";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import CategoryForm from "./CategoryForm";


const Standards:React.FC = () => {

    const dispatch = useAppDispatch();

    const { 
        standards,
        getStandardsStatus,
        updateStandardsStatus, 
        addStandardsStatus,
        addTestMethodStatus,
        testMethods,
        getTestMethodsStatus,
        updateTestMethodStatus
      } = useAppSelector(
        (state) => state.allReducer
      );
  const [loadingDeleteStandard, set_loadingDeleteStandard] = useState<boolean>(false);
  const [loadingDeleteTestMethod, set_loadingDeleteTestMethod] = useState<number>(0);

    const [standard, set_standard] = useState<IStandard>();
    const [testMethod, set_testMethod] = useState<ITestMethod>();

    useEffect(() => {
        getTestMethodsXHR({
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka...")
        }, dispatch);
        getStandardsXHR({
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka...")
        }, dispatch);
    }, [])

    const deleteTestMethod = async (id: number) => {
        set_loadingDeleteTestMethod(id);
        let token = await localStorage.getItem("token");
        try {
            let response = await api().delete<IApiResponse<null>>(`laboapp/exam_method/${id}/`,{ headers: { Authorization: "Bearer " + token } });
            let index = testMethods.findIndex(x => x.id === id);
            let tmp = [...testMethods];
            tmp.splice(index, 1);
            dispatch(allSlice.actions.getTestMethodsSuccess({results: tmp, message: ""}))
        } catch (error: any) {
            
        }
        set_loadingDeleteTestMethod(0);
    }

    const deleteStandard = async (id: number) => {

        set_loadingDeleteStandard(true);
    
        try {
          let token = await localStorage.getItem("token");
          let response = await api().delete<IApiResponse<string>>(
            `laboapp/standards/${id}/`,
            { headers: { Authorization: "Bearer " + token } }
          );
    
          let tmp = [...standards];
          let index = tmp.findIndex((x) => x.id === id);
          tmp.splice(index, 1);
          dispatch(allSlice.actions.getStandardsSuccess({ results: tmp, message: "" }));
        } catch (error: any) {
          if (error?.response?.status === 401) {
            logoutXHR(null, dispatch);
            return;
          }
    
          message.error("Dogodila se greška kod brisanja...");
        }
    
        set_loadingDeleteStandard(false);
      }


    return <>
    <Row gutter={24}>
    <Col style={{padding: 8}} span={12}>
    <List
      dataSource={standards}
      loading={getStandardsStatus === "loading" || addStandardsStatus==="loading" ||  updateStandardsStatus === "loading"  || loadingDeleteStandard}
      header={
        <div className="spaceBetweenRow">
          <Typography.Title level={4}>
            Standardi Proizvoda
          </Typography.Title>
          <Button
            onClick={() => set_standard({ id: 0, name: "" })}
            type="primary"
          >
            Dodaj
          </Button>
        </div>
      }
      renderItem={(item: IStandard, index: number) => (
        <List.Item
          actions={[
            <Popconfirm
              onConfirm={() => deleteStandard(item.id)}
              title="Nastaviti sa akcijom..."
            >
              <Button danger type="dashed">
                <DeleteOutlined />
              </Button>
            </Popconfirm>,
            <Button
              onClick={() =>
                set_standard({ id: item.id, name: item.name })
              }
            >
              <EditFilled />
            </Button>,
          ]}
        >
          <List.Item.Meta title={item.name} />
        </List.Item>
      )}
    />
  </Col>
  <Col style={{padding: 8}} span={12}>
    <List
      dataSource={testMethods}
      loading={addTestMethodStatus === "loading" || getTestMethodsStatus==="loading" ||  updateTestMethodStatus === "loading"  || loadingDeleteStandard}
      header={
        <div className="spaceBetweenRow">
          <Typography.Title level={4}>
            Standardi Testa (Metode)
          </Typography.Title>
          <Button
            onClick={() => set_testMethod({ id: 0, name: "" })}
            type="primary"
          >
            Dodaj
          </Button>
        </div>
      }
      renderItem={(item: IStandard, index: number) => (
        <List.Item
          actions={[
            <Popconfirm
              onConfirm={() => deleteTestMethod(item.id)}
              title="Nastaviti sa akcijom..."
            >
              <Button danger type="dashed">
                <DeleteOutlined />
              </Button>
            </Popconfirm>,
            <Button
              onClick={() =>
                set_testMethod({ id: item.id, name: item.name })
              }
            >
              <EditFilled />
            </Button>,
          ]}
        >
          <List.Item.Meta title={item.name} />
        </List.Item>
      )}
    />
  </Col>

  </Row>

  <Drawer
        title={"Standardi"}
        width={540}
        destroyOnClose={true}
        
        children={
          <CategoryForm
          type="standard"
            category={standard}
            close={() => set_standard(undefined)}
          />
        }
        visible={!!standard}
        onClose={() => set_standard(undefined)}
      />
        <Drawer
        title={"Test metode"}
        width={540}
        destroyOnClose={true}
        
        children={
          <CategoryForm
          type="testMethod"
            category={testMethod}
            close={() => set_testMethod(undefined)}
          />
        }
        visible={!!testMethod}
        onClose={() => set_testMethod(undefined)}
      />
  </>  
}


export default Standards;