import React, { useEffect } from "react";
import "./App.css";
import RoutesGenerator from "./navigation/RoutesGenerator";
import { BrowserRouter as Router } from "react-router-dom";
import { meXHR } from "./store/reducers/user/actionCreators";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./hooks";
import { Spin } from "antd";

import moment from 'moment';
import 'moment/locale/hr'

function App() {

  // Hooks
  const dispatch = useDispatch();
  const {meStatus} = useAppSelector(state => state.userReducer)

  // Methods
  useEffect(() => {
    meXHR({}, dispatch)
    // Changing moment locale
    moment.locale('hr');
    // Changing moment format
    moment.defaultFormat = "DD.MM.YYYY - HH:mm"
  }, [])

  // Loading
  if(meStatus === "loading"){
    return <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: 69}}  ><Spin size="large" /></div>
  }

  return (
    <Router>
      <RoutesGenerator />
    </Router>
  );
}

export default App;
