import { message, Spin, Typography } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ICustomField } from "../../models/all";
import { allSlice } from "../../store/reducers/all";
import { updateStandardRequirementsFieldsXHR } from "../../store/reducers/all/allActionCreator";
import { IValues } from "./ModelItem";
import Table from "./Table";
 


const StandardRequirements: React.FC = () => {
  const [delLoading, set_delLoading] = useState<boolean>();
  const dispatch = useAppDispatch();
  const {id} = useParams();
  const { testClusterDetails, indexToUpdateTestModel, updateStandardRequirementsFieldsStatus } = useAppSelector((state) => state.allReducer);

  const onFinish = (values: IValues, del: boolean, close: () => void) => {

    let arr: ICustomField[] = testClusterDetails?.standard_requirements_custom_fields ? [...testClusterDetails?.standard_requirements_custom_fields]  : [];
    let val: ICustomField = {
      key: values.key,
      name: values.name,
      uom: values.uom,
      type: values.type,
      formula: values.formula,
      friendly_id: indexToUpdateTestModel === -1 ? "A" + ((testClusterDetails?.standard_requirements_custom_fields?.length || 0) + 1) : values.friendly_id
    };

    let index = indexToUpdateTestModel;


    if (index === -1 && !del) {
      arr.push(val);
    } else if (!del) {
      arr[index] = val;
    } else {
      set_delLoading(true);
      arr.splice(index, 1);
    }
    updateStandardRequirementsFieldsXHR({
        errorCallback: () => message.error("Dogodila se greška..."),
        successCallback: () => {
            if(!testClusterDetails) return;
            dispatch(allSlice.actions.getTestClusterDetailsSuccess(
                {
                    message: "",
                    results: {...testClusterDetails, standard_requirements_custom_fields: arr}
                }
            ))
            message.success("Uspješno...");
            close();
        },
        body: {standard_requirements_custom_fields: arr},
        id: id
    }, dispatch)
    //Posovi server
  };

  return (
    <>
      <Spin spinning={updateStandardRequirementsFieldsStatus === "loading"} >
        <Table
            title="Zahtjevi standarda"
            onFinish={onFinish}
            modelFieldsType={"standard"}
            data={testClusterDetails?.standard_requirements_custom_fields || []}
        />
      </Spin>
    </>
  );
};

export default StandardRequirements;
