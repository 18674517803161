import { Button, Modal, Space, Spin, Table, message, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getProductTestingsXHR } from "../../../store/reducers/all/allActionCreator";
import { useAppSelector } from "../../../hooks";
import CreateProductTesting from "../components/CreateProductTesting";
import { ITableColumn } from "../../../models";
import { IProductTesting } from "../../../models/all";
import moment from "moment";
import { getResult, getTestDuration } from "../../../helpers/functions";

const Tested:React.FC = () => {

    // Hooks
    const dispatch = useDispatch()
    const { id } = useParams()

    // Variables
    const [createProductTestingModalVisible, setCreateProductTestingModalVisible] = useState<boolean>(false)
    const { productTestings, getProductTestingsStatus, productDetails } = useAppSelector(state => state.allReducer)
    const [search, set_search] = useState<string>("");
    // Table
    const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IProductTesting>[]>([
        { 
            title: "Testiranje", 
            dataIndex: "name",
            render(text: string, value: IProductTesting) {
                return (
                    <Link to={`/testings/${value.id}`}>
                        { `Testiranje ${value.id}` }
                    </Link>
                )
            }, 
        },
        {
            title: "Nalog",
            dataIndex: "work_order",
            render(text: string, value: IProductTesting) {
                return (
                    <span>
                        { value.work_order || "-" }
                    </span>
                )
            }
        },
        { 
            title: "Oznaka uzorka", 
            dataIndex: "sample_mark",
            render(text: string, value: IProductTesting) {
                return (
                    <span>
                        { value.sample_mark || "-" }
                    </span>
                )
            }
        },
        { 
            title: "Datum", 
            dataIndex: "created_at",
            render(text: string, value: IProductTesting) {
                return (
                    <span>
                        { moment(value.testing_date).format("DD.MM.YYYY") }
                    </span>
                )
            }
        },
        { 
            title: "Trajanje", 
            dataIndex: "name",
            render(text: string, value: IProductTesting) {
                return (
                    <span>
                        { getTestDuration(value.created_at, value.end_at) }
                    </span>
                )
            }
        },
        { 
            title: "Rezultat", 
            dataIndex: "name",
            render(text: string, value: IProductTesting) {
                return (
                    <span>
                        { getResult(value.is_done, value.is_success) }
                    </span>
                )
            }
        },
    ]);
    
    // Methods
    useEffect(() => {
        getProductTestingsXHR({
            id,
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka...")
        }, dispatch);
    }, []);


    let filteredData = search ?  [...productTestings].filter((item) => (item.work_order + item.product.name + item.sample_mark + item.id).toLowerCase().includes(search.toLowerCase())) : [...productTestings];

    return(
        <Spin spinning={getProductTestingsStatus === "loading"}>

            <Space direction="horizontal" style={{ alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingBottom: 24, paddingTop: 12 }}>
                <h3 style={{ margin: 0 }}>Popis testiranja</h3>

                <Space>
                    <Input.Search
                        allowClear
                    onChange={({target: {value}}) =>{
                        set_search(value || "");
                    }} placeholder="Pretraži" style={{ width: 200
                    }}  />
                <Button 
                    type="primary"
                    onClick={() => setCreateProductTestingModalVisible(true)}
                >
                    Novo testiranje
                </Button>
                </Space>
            </Space>

            {/* Table */}
            <Table
                rowKey={(item) => item.id}
                dataSource={filteredData}
                columns={COLUMNS}
            />

            {/* Create product testing modal */}
            <Modal
                destroyOnClose
                title="Novo testiranje"
                visible={createProductTestingModalVisible}
                onCancel={() => setCreateProductTestingModalVisible(false)}
                footer={null}
            >
                <CreateProductTesting 
                    onClose={() => setCreateProductTestingModalVisible(false)} 
                    productId={productDetails?.id as number}
                />
            </Modal>
        </Spin>    
    )
}


export default Tested;