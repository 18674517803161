import React, { useEffect, useState } from "react";
import { Menu, Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "./routes";
import { useAppSelector } from "../hooks";
import Logo from '../assets/exertuslab.png'

const { Sider } = Layout;

const NO_SIDER_ROUTE: string[] = [
  "*",
  "logout",
  "profile",
  "",
  "equipment/:id",
  "testclusters/:id",
  "products/:id",
  "testings/:id",
  "reportbro"
];

interface IProps {
}

const _Sider: React.FC<IProps> = ({ }) => {

  // Hooks
  const location = useLocation();

  // Variables
  const auth = useAppSelector(state => !!state.userReducer.user.token.access);
  const [tabKey, setTabKey] = useState<string>(location.pathname.split("/")[1] || "dashboard")

  // Methods
  useEffect(() => {
    let key = location.pathname.split("/")[1]
    if(key === 'testings') setTabKey("products")
    else setTabKey(key)
  }, [location])

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div style={{ minHeight: "86px", display: 'flex', alignItems: 'center' }} className="logo">
        {/* <img src={Logo} style={{ width: '100%' }} /> */}
      </div>
      <Menu
        theme="dark"
        mode="inline"
        activeKey={tabKey}
        selectedKeys={[tabKey]}
        defaultSelectedKeys={[tabKey]}
      >
        {Routes(auth).children?.filter((x): boolean => !NO_SIDER_ROUTE.includes(x.path))
          .map((item) => (
            <Menu.Item key={item.path} icon={item.icon}>
              <Link to={item.path}>{item.name}</Link>
            </Menu.Item>
          ))}
      </Menu>
    </Sider>
  );
};

export default _Sider;
