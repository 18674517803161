import {
    Button,
    Col,
    Drawer,
    Input,
    message,
    Popconfirm,
    Row,
    Spin,
    Table,
    Tooltip,
    Typography,
  } from "antd";
  import React, { useEffect, useState } from "react";
  import { useAppDispatch, useAppSelector } from "../../hooks";
  import { IApiResponse, ITableColumn } from "../../models";
  import { IClient, ITestCluster } from "../../models/all";
  import { Link, useLocation } from "react-router-dom";
  import { clientsXHR, getTestClustersXHR } from "../../store/reducers/all/allActionCreator";
  import {
    CopyOutlined,
    DeleteOutlined,
    EditFilled,
    EditOutlined,
    InfoCircleOutlined,
  } from "@ant-design/icons";
  import TestClusterForm, { ITestClusterForm } from "./TestClusterForm";
  import api from "../../services";
  import { logoutXHR } from "../../store/reducers/user/actionCreators";
  import { allSlice } from "../../store/reducers/all";
  
  const TestClusters: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
  
    const {testClusters, getTestClustersStatus} = useAppSelector(state => state.allReducer);
 
    const [search, set_search] = useState<string>("");
 
    const [deleteLoading, set_deleteLoading] = useState<number>(0)
    const [test, set_test] = useState<ITestClusterForm>()
  
    const COLUMNS:ITableColumn<ITestCluster>[]  =[{
      dataIndex: "name",
      title: "Naziv",
      render: (text, value) => <Link to={`${value.id}`}>{value.name}</Link>
    },
    {
      title: "Kategorija proizvoda",
      render: (text, value) => <span>{value.product_category.name}</span>
    },
    {
      title: "Metoda",
      render: (text, value) => <span>{value.exam_method.name}</span>
    },
    {
      title: "Kategorija opreme",
      render: (text, value) => {
        let equipment_categories = value.equipment_categories?.map(item => item.name).join(', ') || "-"
        
        return (
          <div className="text-ellipsis">
            { equipment_categories }
          </div>
        )
      }
      
    },
    {
      title: "",
      dataIndex: "",
      render: (text, value) => <div style={{ minWidth: 200 }}>
        <Popconfirm
          onConfirm={() => deleteTestCluster(value.id)}
          title="Nastaviti sa akcijom..."
        >
          <Button loading={deleteLoading === value.id} danger type="dashed">
            <DeleteOutlined />
          </Button>
        </Popconfirm>
        <Button
          style={{marginLeft: 12}}
          onClick={() =>
            set_test({ id: value.id, name: value.name, product_category: value.product_category.id,
              exam_method: value.exam_method.id,
              equipment_categories: value.equipment_categories?.map(item => item.id),
            })
          }
        >
          <EditFilled />
        </Button>
        <Button
          style={{marginLeft: 12}}
          onClick={() =>
            set_test({ id: 0, name: value.name, product_category: value.product_category.id,
              exam_method: value.exam_method.id,
              equipment_categories: value.equipment_categories?.map(item => item.id),
              copy: value.id
            })
          }
        >
          <CopyOutlined />
        </Button>
      </div>
    }
  ];

    useEffect(() => {}, [])
  
    const deleteTestCluster = async (id: number) => {
      set_deleteLoading(id);
  
      try {
        let token = await localStorage.getItem("token");
        let response = await api().delete<IApiResponse<string>>(
          `laboapp/test/${id}/`,
          { headers: { Authorization: "Bearer " + token } }
        );
  
        let tmp = [...testClusters];
        let index = tmp.findIndex((x) => x.id === id);
        tmp.splice(index, 1);
        dispatch(allSlice.actions.getTestClustersSuccess({ results: tmp, message: "" }));
      } catch (error: any) {
        console.log(error);
        if (error?.response?.status === 401) {
          logoutXHR(null, dispatch);
          return;
        }
  
        message.error("Dogodila se greška kod brisanja...");
      }
  
      set_deleteLoading(0);
    };
  
    const getTestsClusters = () => {
      getTestClustersXHR(
        {
          errorCallback: (data: any) => message.error("Greska na dohvatu."),
        },
        dispatch
      );
    };
  
    useEffect(() => {
      getTestsClusters();
    }, []);
  
    
  
    let filtredData: ITestCluster[] = testClusters;
    console.log(search, "KK");
    if (search) {
      filtredData = filtredData?.filter((x) => x.name.toLowerCase().includes(search));
    }  
  
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "18px 0",
          }}
        >
          <Typography.Title level={4}>Pregled testova</Typography.Title>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Tooltip title={"Pretraži po nazivu."}>
                <InfoCircleOutlined />
              </Tooltip>
              <div style={{ width: 12 }} />
  
              <Input.Search
                onChange={({target: {value}}) => {
                  if(!value){
                    set_search("");
                    return;
                  }
                  set_search(value.toLowerCase())
                }}
                allowClear
                style={{ marginRight: 18 }}
              />
            </div>
            <Button
              onClick={() =>
                set_test({
                  id: 0,
                  name: "",
                  product_category: null,
                  exam_method: null,
                  equipment_categories: []
                })
              }
              type="primary"
            >
              Dodaj
            </Button>
          </div>
        </div>
  
        <Row>
          <Col span={24}>
            <Spin spinning={getTestClustersStatus === "loading"}>
              <Table
                rowKey={(item) => item.id}
                dataSource={filtredData}
                columns={COLUMNS}
              />
            </Spin>
          </Col>
        </Row>
  
        <Drawer
          title={test?.copy ? 'Kopiraj test' : test?.id !== 0 ? 'Uredi test' : 'Dodaj test'}
          width={540}
          destroyOnClose={true}
          children={
            <TestClusterForm test={test} close={() => set_test(undefined)} />
          }
          visible={!!test}
          onClose={() => set_test(undefined)}
        />
      </div>
    );
  };
  
  export default TestClusters;
  