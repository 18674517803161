import { message } from "antd";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../hooks";

interface IProps {
  childern: JSX.Element;
}

const PrivateRoute: React.FC<IProps> = ({ childern }) => {
  const auth: boolean = useAppSelector(state => !!state.userReducer.user.token.access);

  if (!auth) {
    message.warning("toContiniuePleaseLogin");
  }

  return auth ? childern : <Navigate replace to="" />;
};

export default PrivateRoute;
