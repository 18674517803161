import React, { useEffect } from "react";

import { Button, Form, Input, InputNumber, message, Select, Spin } from "antd";

import { IApiResponse } from "../../models";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  addProductXHR,
  getCustomFieldsXHR,
  getProductCategoriesXHR,
  updateProductXHR,
} from "../../store/reducers/all/allActionCreator";
import GenerateForCustomFields from "../components/GenerateForCustomFields";
import { ICustomValue } from "../../models/all";
import { CUSTOM_FIELD_HALF_KEY } from "../Settings/CustomFieldForm";
import { generateCustomFieldValues } from ".";

const { Option } = Select;

interface IProps {
  product: undefined | IProductForm;
  close: () => void;
}

export interface IProductForm {
  id: number;
  name: string;
  category: null | number;
  custom_fields: ICustomValue[]
}

const ProductForm: React.FC<IProps> = ({ product, close }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    addProductStatus,
    productCategories,
    updateProductStatus,
    getCustomFieldsStatus,
    customFields
  } = useAppSelector((state) => state.allReducer);

  useEffect(() => {
    getProductCategoriesXHR({}, dispatch);
    // getCustomFieldsXHR({}, dispatch);
    if(!!product){
      form.setFieldsValue(generateCustomFieldValues(customFields.product_fields, product.custom_fields));
    }
  }, []);

   const onFinish = (values: IProductForm & {[key: string]: ICustomValue[]}) => {

    let custom_fields: ICustomValue[] = [];

    Object.keys(values).map((key) => {
      if (key.includes(CUSTOM_FIELD_HALF_KEY)) {
        let tmp = customFields.product_fields.find(
          (x) => x.key === key
        );
        custom_fields.push({
          key: key,
          type: tmp?.type || "",
          name: tmp?.name || "",
          value: values[key]?.toString() || "",
          friendly_id: tmp?.friendly_id || "no_id"
        });
      }
    });
    
    if (!!product?.id) {
      // update user
      updateProductXHR(
        {
          id: product.id.toString(),
          body: {...values, custom_fields},
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        },
        dispatch
      );

      return;
    }

    addProductXHR(
      {
        body: {...values, custom_fields},
        errorCallback: () =>
          message.error("Dogodila se greška na spremanje podataka"),
        successCallback: () => close(),
      },
      dispatch
    );
  };

  if(getCustomFieldsStatus === "loading") {
    return <Spin />
  }

  return (
    <Form
      form={form}
      initialValues={product}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name={"name"}
        label={"Naziv"}
        rules={[{ required: true, message: "Ovo polje je obavezno" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item  rules={[{ required: true, message: "Ovo polje je obavezno" }]} name={"category"} label="Kategorija">
        <Select>
          {productCategories.map((x) => (
            <Select.Option value={x.id}>{x.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <GenerateForCustomFields
            selectedCategory={undefined}
            customFields={customFields.product_fields}
          />
      <Form.Item>
        <Button
          loading={
            addProductStatus === "loading" || updateProductStatus === "loading"
          }
          htmlType="submit"
          type="primary"
        >
          {"Spremi"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProductForm;
