import {
  Button,
  Col,
  Drawer,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse, ITableColumn } from "../../models";
import { ICategory, ICustomField, ICustomValue, ICustoValuesObject, IProduct } from "../../models/all";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clientsXHR, getProductsXHR } from "../../store/reducers/all/allActionCreator";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import ProductForm, { IProductForm } from "./ProductForm";
import api from "../../services";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import { allSlice } from "../../store/reducers/all";

const Products: React.FC = () => {

  // Hooks
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [search, set_search] = useState<string>("");
  const { products, getProductsStatus } = useAppSelector(
    (state) => state.allReducer
  );

  const [product, set_product] = useState<IProductForm>();
  const [loading, set_loading] = useState<boolean>(false);
  
  
  useEffect(() => {
    getProducts();
  },[])
 

  const getProducts = () => {
    getProductsXHR(
      {
        errorCallback: (data: any) => message.error("Greška na dohvati podataka..."),
      },
      dispatch
    );
  };

  const deleteItem = async (id: number) => {
    set_loading(true);

    try {
      let token = await localStorage.getItem("token");
      let response = await api().delete<IApiResponse<string>>(
        `laboapp/product/${id}/`,
        { headers: { Authorization: "Bearer " + token } }
      );

      let tmp = [...products];
      let index = tmp.findIndex((x) => x.id === id);
      tmp.splice(index, 1);
      dispatch(
        allSlice.actions.getProductsSuccess({ results: tmp, message: "" })
      );
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }

      message.error("Dogodila se greška kod brisanja...");
    }

    set_loading(false);
  };

  let filtredData = [];

  if (search) {
    filtredData = products?.filter((x) => x.name.toLowerCase().includes(search));
  } else {
    filtredData = products;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "18px 0",
        }}
      >
        <Typography.Title level={5}>Pregled proizvoda</Typography.Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={"Pretraži po nazivu."}>
              <InfoCircleOutlined />
            </Tooltip>
            <div style={{ width: 12 }} />

            <Input.Search
              onChange={({target: {value}}) => {
                if(!value){
                  set_search("");
                  return;
                }
                set_search(value.toLowerCase())
              }}
              style={{ marginRight: 18 }}
              allowClear
            />
          </div>
          <Button
            onClick={() =>
              set_product({
                id: 0,
                name: "",
                category: null,
                custom_fields: []
              })
            }
            type="primary"
          >
            Dodaj
          </Button>
        </div>
      </div>

      <Row>
        <Col span={12}>
          <Spin spinning={getProductsStatus === "loading"}>
            <List
              dataSource={filtredData}
              loading={getProductsStatus === "loading" || loading}
              renderItem={(item: IProduct, index: number) => (
                <List.Item
                  style={{ border: '1px solid #eee', padding: '12px', marginBottom: 12, borderRadius: 4, transition: '250ms ease' }}
                  actions={[
                    <Popconfirm
                      onConfirm={() => deleteItem(item.id)}
                      title="Nastaviti sa akcijom..."
                      cancelText="Odustani"
                    >
                      <Button danger type="dashed">
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>,
                    <Button
                      onClick={() =>
                        set_product({
                          id: item.id,
                          name: item.name,
                          category: item.category.id,
                          custom_fields: item.custom_fields
                        })
                      }
                    >
                      <EditOutlined />
                    </Button>,
                  ]}
                >
                  <List.Item.Meta title={<Link to={`${item.id}/specifications/`}>{item.name}</Link>} description={item.category.name} />
                </List.Item>
              )}
            />
          </Spin>
        </Col>
        <Col span={12}></Col>
      </Row>
      <Drawer
        title={"Proizvod"}
        width={540}
        destroyOnClose={true}
        children={
          <ProductForm product={product} close={() => set_product(undefined)} />
        }
        visible={!!product}
        onClose={() => set_product(undefined)}
      />
    </div>
  );
};

export default Products;

export function generateCustomFieldValues(customFields: ICustomField[], customValues: ICustomValue[]) : ICustoValuesObject {


  let tmp: ICustoValuesObject = {};
  customFields.forEach(x => {
    tmp[x.key] = customValues.find(y => y.key === x.key)?.value || "" 
  })

  return tmp
}