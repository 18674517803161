import { CompassOutlined, DashboardOutlined, FileDoneOutlined, SettingOutlined, ShoppingOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Navigate } from "react-router-dom";
import Wraper from "../layouts/Wraper";
import Accounts from "../pages/Accounts";
import Auth from "../pages/Auth/Auth";
import Logout from "../pages/Auth/Logout";
import Clients from "../pages/Clients";
import Dashboard from "../pages/Dashboard";
import Equipment from "../pages/Equipment";
import EquipmentDetails from "../pages/EquipmentDetails";
import Product from "../pages/Product";
import Spec from "../pages/Product/pages/Spec";
import Tested from "../pages/Product/pages/Tested";
import Tests from "../pages/Product/pages/Tests";
import Products from "../pages/Products";
import Settings from "../pages/Settings";
import SettingsCategories from "../pages/Settings/Components/categories";
import SettingsDynamicColumns from "../pages/Settings/Components/dynamic_columns";
import Standards from "../pages/Settings/Standards";
import TestCluster from "../pages/TestCluster";
import TestClusters from "../pages/TestClusters";
import PrivateRoute from "./PrivateRoute.";
import Testing from "../pages/Testing";
import Results from "../pages/Testing/Pages/Results";
import TestList from "../pages/Testing/Pages/TestList";
import Reportbro from "../pages/Reportbro";

export interface IRoute {
  path: string;
  element: JSX.Element;
  children?: Array<IRoute>;
  icon?: any;
  name: string | null
}

export const Routes = (auth: boolean): IRoute => {


  return {
    path: "",
    name: "wraper",
    element: <Wraper />,
    icon: null,
    children: !auth
      ? [
          {
            path: "",
            element: <Auth />,
            icon: null,
            name: ""
          },
          {
            path: "*",
            element: <Navigate to="" />,
            icon: null,
            name: ""
          },
      
        ]
      : [
          {
            path: "",
            element: <Navigate to="dashboard" />,
            icon: null,
            name: ""
          },
          {
            path: "dashboard",
            element: <PrivateRoute childern={<Dashboard />} />,
            icon: <DashboardOutlined />,
            name: "Nadzorna ploča"
          },
          {
            path: "accounts",
            element: <PrivateRoute childern={<Accounts />} />,
            icon: <UsergroupAddOutlined />,
            name: "Korisnici"
          },
          {
            path: "reportbro",
            element: <PrivateRoute childern={<Reportbro />} />,
            icon: null,
            name: "Reportbro"
          },
          {
            path: "clients",
            element: <PrivateRoute childern={<Clients />} />,
            icon: <UsergroupAddOutlined />,
            name: "Klijenti"
          },
          {
            path: "testclusters",
            element: <PrivateRoute childern={<TestClusters/>} />,
            icon: <FileDoneOutlined />,
            name: "Testovi"
          },
          {
            path: "equipment",
            element: <PrivateRoute childern={<Equipment />} />,
            icon: <CompassOutlined />,
            name: "Oprema",
          },
          {
            path: "equipment/:id",
            element: <PrivateRoute childern={<EquipmentDetails />} />,
            name: "Profil opreme",
          },
          {
            path: "products",
            element: <PrivateRoute childern={<Products />} />,
            icon: <ShoppingOutlined />,
            name: "Proizvodi"
          },
          {
            path: "products/:id",
            element: <PrivateRoute childern={<Product />} />,
            icon: <ShoppingOutlined />,
            name: "Proizvod",
            children: [
              {
                path: "",
                element: <Navigate replace to="specifications" />,
                children: undefined,
                name: null
              },
              {
                path: "specifications",
                element: <Spec />,
                children: undefined,
                name: "Osnovno"
              },
              {
                path: "tests",
                element: <Tests />,
                children: undefined,
                name: "Testovi"
              },
              {
                path: "testing",
                element: <Tested />,
                children: undefined,
                name: "Testiranja"
              },
            ]
          },
          {
            path: "testclusters/:id",
            element: <PrivateRoute childern={<TestCluster />} />,
            icon: <ShoppingOutlined />,
            name: "Proizvodi"
          },
          {
            path: "settings",
            element: <Settings />,
            icon: <SettingOutlined />,
            name: "Postavke",
            children: [
              {
                path: "",
                element: <Navigate replace to="categories" />,
                children: undefined,
                name: null
              },
              {
                path: "categories",
                element: <SettingsCategories />,
                children: undefined,
                name: "Kategorije"
              },
              // {
              //   path: "dynamic_columns",
              //   element: <SettingsDynamicColumns />,
              //   children: undefined,
              //   name: "Dinamična polja"
              // },
              {
                path: "standards",
                element: <Standards />,
                children: undefined,
                name: "Standardi"
              }
            ],
          },
          {
            path: "testings/:id",
            element: <Testing />,
            icon: null,
            children: [
              {
                path: "",
                element: <TestList />,
                name: "Lista testova" 
              },
              {
                path: "results/:test_id",
                element: <Results />,
                children: undefined,
                icon: null,
                name: "Rezultati"
              }
            ],
            name: ""
          },
          {
            path: "logout",
            element: <Logout />,
            icon: null,
            name: ""
          },
          {
            path: "*",
            element: <Navigate to="dashboard" />,
            icon: null,
            name: ""
          }
        ],
  };
};
