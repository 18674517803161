import React from "react";
import { Layout, Typography } from "antd";
import { Link, useLocation } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Routes } from '../navigation/routes'
import Logo from '../assets/exertuslab.png'

const { Header } = Layout;

interface IProps {
  authed: boolean;
}

const _Header: React.FC<IProps> = ({ authed }) => {

  // Hooks
  const location = useLocation()

  // Variables
  const routeName = Routes(true).children?.find(item => item.path === location.pathname.split('/')[1])?.name

  return (
    <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ padding: "0 40px" }}>
          {/* <Typography.Title 
            level={4}
            style={{ lineHeight: '3.2 !important' }}
          >
              { routeName }
          </Typography.Title> */}
          <img src={Logo} style={{ width: 200, marginRight: 32, paddingBottom: 8 }} />
        </div>
        <div style={{ padding: "0 40px" }}>
          <div className="header-flex">
            {authed && <Link className="header-flex-logout" to={"logout"}>Odjava</Link>}
            <div style={{ width: 24 }} />
            {authed && (
              <Link to="profile">
                <UserOutlined style={{ fontSize: 22, color: "#434343" }} />
              </Link>
            )}
          </div>
        </div>
      </div>
    </Header>
  );
};

export default _Header;
