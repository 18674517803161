import { Button, Input, InputNumber, message } from 'antd'
import { valueType } from 'antd/lib/statistic/utils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ICustomField, IRefValues, ITestProduct } from '../../../models/all'
import { updateProductTestXHR } from '../../../store/reducers/all/allActionCreator'

type IProps = {
    data: ICustomField[],
    defaultValues: ITestProduct | undefined,
    id: number | string | undefined,
    closeModal: () => void
}

type RenderRowType = { 
    item: IRefValues, 
    set_refValues: React.Dispatch<React.SetStateAction<IRefValues[]>>, 
    refValues: IRefValues[] 
}

type FlexType = { 
    label: string, 
    value: any, 
    width: string | number 
}

export interface IRefValuesForm {
    ref_values: IRefValues[]
}

const AddRefValues = ({ data, defaultValues, closeModal, id }: IProps) => {


    // Hooks
    const dispatch = useDispatch()

    // Variables
    const [refValues, set_refValues] = useState<IRefValues[]>(data.map(item  => {
        let value = 
            defaultValues && 
            defaultValues?.ref_values.length > 0 && 
            defaultValues?.ref_values.find(value => value.key === item.key)?.value || 
            undefined

        return {
            ...item,
            type: item.type || "",
            value
        }
    }))

    useEffect(() => {
        set_refValues(data.map(item  => {
            let value =

                defaultValues &&
                defaultValues?.ref_values.length > 0 &&
                defaultValues?.ref_values.find(value => value.key === item.key)?.value ||
                undefined

            return {
                ...item,
                type: item.type || "",
                value
            }
        }))
    }, [data])

    // Methods
    function handleOnClick(){
        const body: IRefValuesForm & {rank: number}= {
            ref_values: refValues.filter(item => item.value !== undefined),
            rank: Number(defaultValues?.rank.split("_")[0]) || 0
        }
        updateProductTestXHR({
            id,
            body,
            errorCallback: () => message.error("Dogodila se pogreška"),
            successCallback: () => {
                message.success("Uspješno su dodane referentne vrijednosti!");
                closeModal()
            }
        }, dispatch)
    }

    return (
        <div>
            <div style={{ height: '100%', paddingBottom: 48 }}>
                {/* Options */}
                <div className="stepsContent">
                    { refValues.map((item, index) => (
                        <RenderRow 
                            key={index.toString()} 
                            item={item} 
                            refValues={refValues}
                            set_refValues={set_refValues}
                        />
                    )) }
                </div>

                {/* CTA */}
                <div style={{ float: 'right', paddingTop: 20 }}>
                    <Button 
                        onClick={handleOnClick}
                        type='primary' 
                        // disabled={refValues.some(item => !item.value)}
                    >
                        Dodaj
                    </Button>
                </div>
            </div>
        </div>
    )
}


const RenderRow = ({ item, refValues, set_refValues }: RenderRowType) => {

    // Methods
    function handleOnChange(e: React.ChangeEvent<HTMLInputElement> | valueType, type: 'number' | 'text'){
        let value;
        if(type === 'number') value = e as valueType
        else if(type === 'text') {
            let _val = e as React.ChangeEvent<HTMLInputElement>
            value = _val.target.value
        }
        let arr = [...refValues]
        let index = arr.findIndex(el => el.key === item.key)
        arr[index].value = value
        set_refValues(arr)
    }

    return(
        <div 
            style={{ 
                width: '100%', 
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center', 
                justifyContent: 'stretch',
                gap: 40, marginBottom: 20,
                paddingTop: 12
            }}
        >
            <Flex width="55%" label='Naziv' value={item.name} />
            <Flex width="15%" label='UOM' value={item.uom} />
            <Flex
                width="30%" 
                label='Vrijednost' 
                value={
                    item.type === 'text' ?
                        <Input 
                            value={item.value} 
                            onChange={e => handleOnChange(e, 'text')}
                        /> : 
                        <InputNumber 
                            value={item.value} 
                            onChange={e => handleOnChange(e || "", 'number')}
                            style={{ width: '100%' }}
                            type="number"
                        />
                } 
            />
        </div>
    )
}


const Flex = ({ label, value, width }: FlexType) => {
    return(
        <div style={{ width, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 4 }}>
            <div style={{ fontSize: 11, opacity: .65 }}>{ label }</div>
            <div style={{ fontSize: 18 }}>{ value }</div>
        </div>
    )
}

export default AddRefValues