import { Button, Form, Select, Spin, message } from 'antd';
import { useEffect, useState } from 'react'
import { getColorsXHR, updateTestingTestXHR } from '../../../store/reducers/all/allActionCreator';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { IProductTestingDetails, ITest } from '../../../models/all';
import { allSlice } from '../../../store/reducers/all';

type IProps = {
    test: ITest | undefined,
    onClose: () => void
}

const ColorForm = ({ test, onClose }: IProps) => {

    // Hooks
    const dispatch = useAppDispatch();
    const {colors, getColorsStatus, productTestingDetails} = useAppSelector((state) => state.allReducer);
    const [loading, setLoading] = useState<boolean>(false)

    // Methods
    useEffect(() => {
        getColorsXHR({
            errorCallback: () => message.error("Dogodila se greška pri dohvaćanju boja..."),
        }, dispatch)
    }, []);

    const onFinish = (values: { color: string }) => {
        setLoading(true)
        updateTestingTestXHR({
            body: { color: values.color },
            id: test?.id || 0,
            errorCallback: (err) => { message.error("Dogodila se greška..."); console.log(err)},
            successCallback: () => {
                // Update product testing details tests array
                let arr = [...productTestingDetails?.tests || []]
                let index = arr.findIndex((item) => item.id === test?.id)
                let color = colors.find((item) => item.id === +values.color)
                let _test = arr[index]
                _test = { ..._test, color }
                arr[index] = _test
                dispatch(allSlice.actions.getProductTestingDetailsSuccess({
                    message: "",
                    results: {
                        ...productTestingDetails as IProductTestingDetails,
                        tests: arr
                    }
                }))
                onClose()
            }
        }, dispatch)
        setLoading(false)
    };

    return (
        <div>
            {/* Loader */}
            <Spin spinning={getColorsStatus === "loading" || loading}>

                {/* Form */}
                <Form
                    initialValues={{ color: test?.color?.id }}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Boja"
                        name="color"
                    >
                        <Select style={{ width: '100%' }}>
                            {colors.map((color) => (
                                <Select.Option key={color.id} value={color.id}>{color.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            style={{ float: 'right' }}
                        >
                            Spremi
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
}

export default ColorForm