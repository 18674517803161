import { NewTreeItem, SelectTreeItem } from "../models";
import { IMaterialType } from "../models/all";

// ---------------------------------------------------------------------------------------------------------- 

export function createTreeSelect(
  data:  {parent_id: string | null, title: string, value: string}[],
  parentValue: string | null,
) {
  const treeData: SelectTreeItem[] = [];

  data.forEach((item) => {
    if (parentValue === item.parent_id) {
      treeData.push({
        ...item,
        children: createTreeSelect(data.filter(x => x.parent_id !== parentValue), item.value),
      });
    } 
  });

  return treeData;
}

// ----------------------------------------------------------------------------------------------------------

export function createTree(
  data: IMaterialType[],
  parentValue: number | null,
) {
  const treeData: NewTreeItem<IMaterialType>[] = [];
  data.forEach((item) => {
    if (parentValue === item.parent_id) {
      treeData.push({
        ...item,
        children: createTree(data.filter(x => x.parent_id !== parentValue), item.id),
      });
    } 
  });

  return treeData;
}
  
// ----------------------------------------------------------------------------------------------------------

/**
 * @param is_done Is test done
 * @param is_success Is test success
 * @returns Result of test
 * @example getResult(true, true) // Uspješno
*/

export function getResult(is_done: boolean, is_success: boolean | null): string {
  if (is_done) {
    if (is_success) {
      return "Uspješno";
    }
    return "Neuspješno";
  }
  return "U tijeku";
}

// ----------------------------------------------------------------------------------------------------------

/**
 * @param start Start date
 * @param end End date
 * @returns Test duration in format: 1d 2h
 * @example 
 * getTestDuration("2021-01-01T00:00:00.000Z", "2021-01-02T00:00:00.000Z") // 1d 0h
 * getTestDuration("2021-01-01T00:00:00.000Z", "2021-01-01T02:00:00.000Z") // 2h
*/

export function getTestDuration(start: string, end: string): string {
  // Early exit
  if(!start || !end) return "-";
  
  // Return test duration in format: 1d 2h
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = endDate.getTime() - startDate.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  return `${days === 0 ? '' : `${days}d `}${hours}h`;
}