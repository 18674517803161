import { Col, message, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks";
import {  ITestModelFormValues, ITry } from "../../../../../../models/all";
import { saveModelFormsXHR } from "../../../../../../store/reducers/all/allActionCreator";
import AddTriesButton from "./AddTriesButton";
import FinalResults from "./FinalResults";
import Roles, { BelongToType } from "./Roles";


interface IProps{
  modelFormID: number,
}

const Tries:React.FC<IProps> = ({modelFormID}) =>{

  const { testingTest } = useAppSelector((state) => state.allReducer);

  const [modelForm, setModelForm] = React.useState<ITestModelFormValues | undefined>(testingTest?.model_forms.find(x => x.id === modelFormID));


  const dispatch = useAppDispatch();

  useEffect(() => {
    setModelForm(testingTest?.model_forms.find(x => x.id === modelFormID));
  }, [modelFormID, testingTest])


  const updateData = (tries: ITry[], belongsTo: BelongToType) => {

    if(modelForm === undefined) return;
    let body: ITestModelFormValues= {
      ...modelForm,
      prepare_tries: belongsTo === "prepare" ? tries : modelForm.prepare_tries,
      other_custom_fields_tries: belongsTo === "other" ? tries : modelForm.other_custom_fields_tries
    }


    saveModelFormsXHR(
      {
        body,
        id: modelForm.id,
        errorCallback: () => {
          message.error("Dogodila se greška...");
        },
        successCallback: (data) => {
          let model_form = data.results?.model_forms.find(x => x.id === modelFormID);
          if(!model_form) return;
          setModelForm(model_form);
        }
      },
      dispatch
    );
  }

  if(modelForm === undefined){
    return <Typography.Title level={4}>Model form not found</Typography.Title>

  }


  return(
    <div>
      <Row>
        <Col span={16}>
          <div  style={{width: "100%", overflow: "auto"}} >
            <div>
              <Roles title="Priprema" updateData={updateData}  tries={modelForm.prepare_tries} result={modelForm.prepare_result} belongsTo="prepare" refValues={[]}  />
              <Roles title="Rezultati" updateData={updateData} tries={modelForm.other_custom_fields_tries} result={modelForm.other_custom_fields_result} belongsTo="other" refValues={[]}  />
            </div>
          </div>
        </Col>
      <Col span={2}>
        <AddTriesButton setActiveModelForm={setModelForm} triesPrepare={modelForm.prepare_tries} triesOther={modelForm.other_custom_fields_tries} modelFormID={modelFormID} />
      </Col>
      <Col span={6  }>
        <FinalResults resultsPrepare={modelForm.prepare_result}  resultsOther={modelForm.other_custom_fields_result}/>
      </Col>
      </Row>

    </div>
  )
}

export default Tries;