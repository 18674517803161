import React from "react";
import { Button, Divider, Form, Input, message, Typography } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loginXHR } from "../../store/reducers/user/actionCreators";
import Logo from '../../assets/exertuslab.png'

const Login: React.FC = () => {

  const { loginStatus } = useAppSelector((state) => state.userReducer);

  const dispatch = useAppDispatch();

  const onFinish = (values: { email: string; password: string }) => {
    loginXHR(
      {
        body: values,
        errorCallback: (data: any, error: any) => {
          console.log(data, error);
          if (error && error?.response?.status !== 400) {
            message.error("Greška pri logiranju");
            return;
          }

          message.warning("Netočno korisničko ime ili lozinka!");
        },
      },
      dispatch
    );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 100,
      }}
    >

      

      <Typography.Title 
        level={1}
        style={{ height: 20, color: "#434343", fontWeight: 500 }}
      >
        Prijavite se
      </Typography.Title>

      <Typography.Title 
        level={5}
        style={{ paddingBottom: 32, color: "#434343", fontWeight: 300 }}
      >
        Unesite podatke da biste pristupili aplikaciji
      </Typography.Title>

      
      <Form layout="vertical" onFinish={onFinish} autoComplete="off">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Polje obavezno" },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label={"Lozinka"}
          name="password"
          rules={[
            { required: true, message: "Polje obavezno"},
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Divider />

        <Form.Item>
          <Button
            loading={loginStatus === "loading"}
            type="primary"
            htmlType="submit"
            block
            size="large"
          >
            Prijava
          </Button>
        </Form.Item>
      </Form>
      <img style={{ width: 300, marginTop: 48 }} src={Logo} />
    </div>
  );
};

export default Login;
