
import { allSlice } from ".";
import { AppDispatch } from "../..";
import { IApiResponse, IDataToApi } from "../../../models";
import { IAccount, ICategory, IClient, ICustomField, ICustomFields, IEquipment, IFinishCalibration, IFinishCalibrationForm, IFormAccount, IMaterialType, IProduct, IProductDetails, IProductTesting, IProductTestingDetails, IRefValues, ITestingTest, IStandard, ITest, ITestCluster, ITestClusterDetails, ITestMethod, ITestModelForm, ITestProduct, ICustomValue, IColor, ITry, ITestModelFormValues } from "../../../models/all";
import { IAccountForm } from "../../../pages/Accounts/AccountForm";
import { ICLientForm } from "../../../pages/Clients/ClientForm";
import { IEquipmentForm } from "../../../pages/Equipment/EquipmentForm";
import { IRefValuesForm } from "../../../pages/Product/components/AddRefValues";
import { ICopyTests } from "../../../pages/Product/components/CopyTests";
import { ICreateProductTesting } from "../../../pages/Product/components/CreateProductTesting";
import { IAddTest } from "../../../pages/Product/pages/Tests/TestTable";
import { IProductForm } from "../../../pages/Products/ProductForm";
import { ITestClusterForm } from "../../../pages/TestClusters/TestClusterForm";
import { UpdateData } from "../../../pages/Testing/Pages/Results/Components/Header";
import api from "../../../services";
import { logoutXHR } from "../user/actionCreators";

export const clientsXHR = async (
  data: Partial<IDataToApi<undefined, IApiResponse<IClient[]>>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(allSlice.actions.clients());
    const response = await api().get<IApiResponse<IClient[]>>(
      `laboapp/clients/`,
      {
        params: data.queryParams,
        headers: { Authorization: "Bearer " + token },
      }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.clientsSucceess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.clientsFail(error.message));
  }
};

export const addClientXHR = async (
  data: Partial<IDataToApi<ICLientForm, IApiResponse<IClient>>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(allSlice.actions.addClient());
    const response = await api().post<IApiResponse<IClient>>(
      `laboapp/clients/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    
    dispatch(allSlice.actions.addClientSucceess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.addClientFail(error.message));
  }
};

export const updateClientXHR = async (data: Partial<IDataToApi<ICLientForm, IApiResponse<IClient>>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


      try {
        dispatch(allSlice.actions.updateClient());
        const response = await api().put<IApiResponse<IClient>>(`laboapp/clients/${data.id}/`,
          data.body,

          {headers: { Authorization: "Bearer " + token }}
          );
        if (data.successCallback) {
          data.successCallback(response.data);
        }
        dispatch(allSlice.actions.updateClientSuccess(response.data));
      } catch (error: any) { //lkl
        if(error?.response?.status === 401){
          logoutXHR(null, dispatch)
        }
        if (data.errorCallback) {
          data.errorCallback(error);
        }
    
        dispatch(allSlice.actions.updateClientFail(error.message));
      }
    };



 
    export const getProductsXHR = async (data: Partial<IDataToApi<null, IApiResponse<IProduct[]>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.getProducts());
            const response = await api().get<IApiResponse<IProduct[]>>(`laboapp/product/`,
                {
                    params: data.queryParams,
                   headers: { Authorization: "Bearer " + token }
                });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.getProductsSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.getProductsFail(error.message));
          }
    };



        export const addProductXHR = async (data: Partial<IDataToApi<IProductForm, IApiResponse<IProduct>>>, dispatch: AppDispatch) => {

          const token = await localStorage.getItem("token");
      
      
              try {
                dispatch(allSlice.actions.addProduct());
                const response = await api().post<IApiResponse<IProduct>>(`laboapp/product/`,
                  data.body,
      
                  {headers: { Authorization: "Bearer " + token }}
                  );
                if (data.successCallback) {
                  data.successCallback(response.data);
                }
                dispatch(allSlice.actions.addProductSuccess(response.data));
              } catch (error: any) { //lkl
                if(error?.response?.status === 401){
                  logoutXHR(null, dispatch)
                }
                if (data.errorCallback) {
                  data.errorCallback(error);
                }
            
                dispatch(allSlice.actions.addProductFail(error.message));
              }
            };


    export const updateProductXHR = async (data: Partial<IDataToApi<IProductForm, IApiResponse<IProduct>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.updateProduct());
            const response = await api().put<IApiResponse<IProduct>>(`laboapp/product/${data.id}/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.updateProductSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.updateProductFail(error.message));
          }
        };
  
      

        
      
  
  
  
        export const addProductCategoryXHR = async (data: Partial<IDataToApi<ICategory, IApiResponse<ICategory>>>, dispatch: AppDispatch) => {

          const token = await localStorage.getItem("token");
      
      
              try {
                dispatch(allSlice.actions.addProductCategory());
                const response = await api().post<IApiResponse<ICategory>>(`laboapp/productcategory/`,
                  data.body,
      
                  {headers: { Authorization: "Bearer " + token }}
                  );
                if (data.successCallback) {
                  data.successCallback(response.data);
                }
                dispatch(allSlice.actions.addProductCategorySuccess(response.data));
              } catch (error: any) { //lkl
                if(error?.response?.status === 401){
                  logoutXHR(null, dispatch)
                }
                if (data.errorCallback) {
                  data.errorCallback(error);
                }
            
                dispatch(allSlice.actions.addProductCategoryFail(error.message));
              }
            };
      
 


            export const getProductCategoriesXHR = async (data: Partial<IDataToApi<null, IApiResponse<ICategory[]>>>, dispatch: AppDispatch) => {

              const token = await localStorage.getItem("token");
          
          
                  try {
                    dispatch(allSlice.actions.getProductCategories());
                    const response = await api().get<IApiResponse<ICategory[]>>(`laboapp/productcategory/`,
                        {
                            params: data.queryParams,
                           headers: { Authorization: "Bearer " + token }
                        });
                    if (data.successCallback) {
                      data.successCallback(response.data);
                    }
                    dispatch(allSlice.actions.getProductCategoriesSuccess(response.data));
                  } catch (error: any) { //lkl
                    if(error?.response?.status === 401){
                      logoutXHR(null, dispatch)
                    }
                    if (data.errorCallback) {
                      data.errorCallback(error);
                    }
                
                    dispatch(allSlice.actions.getProductCategoriesFail(error.message));
                  }
                };
          
          


    export const updateProductCategoriesXHR = async (data: Partial<IDataToApi<ICategory, IApiResponse<ICategory>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.updateProductCategories());
            const response = await api().put<IApiResponse<ICategory>>(`laboapp/productcategory/${data.id}/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.updateProductCategoriesSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.updateProductCategoriesFail(error.message));
          }
        };
  
   
      
      
  
  
        export const updateCustomFieldsXHR = async (data: Partial<IDataToApi<ICustomFields, IApiResponse<ICustomFields>>>, dispatch: AppDispatch) => {

          const token = await localStorage.getItem("token");
      
      
              try {
                dispatch(allSlice.actions.updateCustomFields());
                const response = await api().put<IApiResponse<ICustomFields>>(`laboapp/customfields/${1}/`,
                  data.body,
      
                  {headers: { Authorization: "Bearer " + token }}
                  );
                if (data.successCallback) {
                  data.successCallback(response.data);
                }
                dispatch(allSlice.actions.updateCustomFieldsSuccess(response.data));
              } catch (error: any) { //lkl
                if(error?.response?.status === 401){
                  logoutXHR(null, dispatch)
                }
                if (data.errorCallback) {
                  data.errorCallback(error);
                }
            
                dispatch(allSlice.actions.updateCustomFieldsFail(error.message));
              }
            };
      
      
     
          


    export const getCustomFieldsXHR = async (data: Partial<IDataToApi<null, IApiResponse<ICustomFields>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.getCustomFields());
            const response = await api().get<IApiResponse<ICustomFields>>(`laboapp/customfields/`,
                {
                    params: data.queryParams,
                   headers: { Authorization: "Bearer " + token }
                });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.getCustomFieldsSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.getCustomFieldsFail(error.message));
          }
        };



    export const getStandardsXHR = async (data: Partial<IDataToApi<null, IApiResponse<IStandard[]>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.getStandards());
            const response = await api().get<IApiResponse<IStandard[]>>(`laboapp/standards/`,
                {
                    params: data.queryParams,
                   headers: { Authorization: "Bearer " + token }
                });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.getStandardsSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.getStandardsFail(error.message));
          }
        };
  
  
      
    export const addStandardsXHR = async (data: Partial<IDataToApi<IStandard, IApiResponse<IStandard>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.addStandards());
            const response = await api().post<IApiResponse<IStandard>>(`laboapp/standards/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.addStandardsSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.addStandardsFail(error.message));
          }
        };
  
  
  
     
    export const updateStandardsXHR = async (data: Partial<IDataToApi<IStandard, IApiResponse<IStandard>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.updateStandards());
            const response = await api().put<IApiResponse<IStandard>>(`laboapp/standards/${data.id}/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.updateStandardsSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.updateStandardsFail(error.message));
          }
        };
  


    export const getEqipmentXHR = async (data: Partial<IDataToApi<null, IApiResponse<IEquipment[]>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.getEqipment());
            const response = await api().get<IApiResponse<IEquipment[]>>(`equipment/equipment/`,
                {
                    params: data.queryParams,
                   headers: { Authorization: "Bearer " + token }
                });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.getEqipmentSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.getEqipmentFail(error.message));
          }
        };
  
  
    
      

        export const addEquipmentXHR = async (data: Partial<IDataToApi<IEquipmentForm, IApiResponse<IEquipment>>>, dispatch: AppDispatch) => {

          const token = await localStorage.getItem("token");
      
      
              try {
                dispatch(allSlice.actions.addEquipment());
                const response = await api().post<IApiResponse<IEquipment>>(`equipment/equipment/`,
                  data.body,
      
                  {headers: { Authorization: "Bearer " + token }}
                  );
                if (data.successCallback) {
                  data.successCallback(response.data);
                }
                dispatch(allSlice.actions.addEquipmentSuccess(response.data));
              } catch (error: any) { //lkl
                if(error?.response?.status === 401){
                  logoutXHR(null, dispatch)
                }
                if (data.errorCallback) {
                  data.errorCallback(error);
                }
            
                dispatch(allSlice.actions.addEquipmentFail(error.message));
              }
            };
      

            export const updateEquipmentXHR = async (data: Partial<IDataToApi<IEquipmentForm, IApiResponse<IEquipment>>>, dispatch: AppDispatch) => {

              const token = await localStorage.getItem("token");
          
          
                  try {
                    dispatch(allSlice.actions.updateEquipment());
                    const response = await api().put<IApiResponse<IEquipment>>(`equipment/equipment/${data.id}/`,
                      data.body,
          
                      {headers: { Authorization: "Bearer " + token }}
                      );
                    if (data.successCallback) {
                      data.successCallback(response.data);
                    }
                    dispatch(allSlice.actions.updateEquipmentSuccess(response.data));
                  } catch (error: any) { //lkl
                    if(error?.response?.status === 401){
                      logoutXHR(null, dispatch)
                    }
                    if (data.errorCallback) {
                      data.errorCallback(error);
                    }
                
                    dispatch(allSlice.actions.updateEquipmentFail(error.message));
                  }
                };
  

                
   
                export const addEquipmentCategoryXHR = async (data: Partial<IDataToApi<ICategory, IApiResponse<ICategory>>>, dispatch: AppDispatch) => {

                  const token = await localStorage.getItem("token");
              
              
                      try {
                        dispatch(allSlice.actions.addEquipmentCategory());
                        const response = await api().post<IApiResponse<ICategory>>(`equipment/categories/`,
                          data.body,
              
                          {headers: { Authorization: "Bearer " + token }}
                          );
                        if (data.successCallback) {
                          data.successCallback(response.data);
                        }
                        dispatch(allSlice.actions.addEquipmentCategorySuccess(response.data));
                      } catch (error: any) { //lkl
                        if(error?.response?.status === 401){
                          logoutXHR(null, dispatch)
                        }
                        if (data.errorCallback) {
                          data.errorCallback(error);
                        }
                    
                        dispatch(allSlice.actions.addEquipmentCategoryFail(error.message));
                      }
                    };
      



    export const updateEquipmentCategoryXHR = async (data: Partial<IDataToApi<ICategory, IApiResponse<ICategory>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.updateEquipmentCategory());
            const response = await api().put<IApiResponse<ICategory>>(`equipment/categories/${data.id}/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.updateEquipmentCategorySuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.updateEquipmentCategoryFail(error.message));
          }
        };



    export const getEquipmentCategoryXHR = async (data: Partial<IDataToApi<null, IApiResponse<ICategory[]>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.getEquipmentCategory());
            const response = await api().get<IApiResponse<ICategory[]>>(`equipment/categories/`,
                {
                    params: data.queryParams,
                   headers: { Authorization: "Bearer " + token }
                });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.getEquipmentCategorySuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.getEquipmentCategoryFail(error.message));
          }
        };
  
  
        export const getEquipmentXHR = async (data: Partial<IDataToApi<null, IApiResponse<IEquipment>>>, dispatch: AppDispatch) => {
          const token = await localStorage.getItem("token");
          try {
            dispatch(allSlice.actions.getEquipment());
            const response = await api().get<IApiResponse<IEquipment>>(`equipment/equipment/${data.id}/`,
              {
                params: data.queryParams,
                headers: { Authorization: "Bearer " + token }
              });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.getEquipmentSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
            dispatch(allSlice.actions.getEquipmentFail(error.message));
          }
        };


        export const finishCalibrationXHR = async (data: Partial<IDataToApi<IFinishCalibrationForm, IApiResponse<IFinishCalibration>>>, dispatch: AppDispatch) => {
          const token = await localStorage.getItem("token");
          try {
            dispatch(allSlice.actions.finishCalibration());
            const response = await api().post<IApiResponse<IFinishCalibration>>(`equipment/finish_calibration/`,
              data.body,
              {
                params: data.queryParams,
                headers: { Authorization: "Bearer " + token }
              });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.finishCalibrationSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
            dispatch(allSlice.actions.finishCalibrationFail(error.message));
          }
        };
      
  
      
  
        export const getAccountsXHR = async (data: Partial<IDataToApi<null, IApiResponse<IAccount[]>>>, dispatch: AppDispatch) => {

          const token = await localStorage.getItem("token");
      
      
              try {
                dispatch(allSlice.actions.getAccounts());
                const response = await api().get<IApiResponse<IAccount[]>>(`accounts/accounts/`,
                    {
                        params: data.queryParams,
                       headers: { Authorization: "Bearer " + token }
                    });
                if (data.successCallback) {
                  data.successCallback(response.data);
                }
                dispatch(allSlice.actions.getAccountsSuccess(response.data));
              } catch (error: any) { //lkl
                if(error?.response?.status === 401){
                  logoutXHR(null, dispatch)
                }
                if (data.errorCallback) {
                  data.errorCallback(error);
                }
            
                dispatch(allSlice.actions.getAccountsFail(error.message));
              }
            };
      
      
          export const addAccountXHR = async (
            data: Partial<IDataToApi<IAccountForm, IApiResponse<IAccount>>>,
            dispatch: AppDispatch
          ) => {
            const token = await localStorage.getItem("token");
          
            try {
              dispatch(allSlice.actions.addAccount());
              const response = await api().post<IApiResponse<IAccount>>(
                `accounts/accounts/`,
                data.body,
          
                { headers: { Authorization: "Bearer " + token } }
              );
              if (data.successCallback) {
                data.successCallback(response.data);
              }
              
              dispatch(allSlice.actions.addAccountSucceess(response.data));
            } catch (error: any) {
              //lkl
              if (error?.response?.status === 401) {
                logoutXHR(null, dispatch);
              }
              if (data.errorCallback) {
                data.errorCallback(error);
              }
          
              dispatch(allSlice.actions.addAccountFail(error.message));
            }
          };
            
  
  
          export const updateAccountXHR = async (data: Partial<IDataToApi<IAccountForm, IApiResponse<IAccount>>>, dispatch: AppDispatch) => {

            const token = await localStorage.getItem("token");
          
          
                try {
                  dispatch(allSlice.actions.updateAccount());
                  const response = await api().put<IApiResponse<IAccount>>(`accounts/accounts/${data.id}/`,
                    data.body,
          
                    {headers: { Authorization: "Bearer " + token }}
                    );
                  if (data.successCallback) {
                    data.successCallback(response.data);
                  }
                  dispatch(allSlice.actions.updateAccounttSuccess(response.data));
                } catch (error: any) { //lkl
                  if(error?.response?.status === 401){
                    logoutXHR(null, dispatch)
                  }
                  if (data.errorCallback) {
                    data.errorCallback(error);
                  }
              
                  dispatch(allSlice.actions.updateAccountFail(error.message));
                }
              };



    export const addTestMethodXHR = async (data: Partial<IDataToApi<ICategory, IApiResponse<ITestMethod>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.addTestMethod());
            const response = await api().post<IApiResponse<ITestMethod>>(`laboapp/exam_method/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.addTestMethodSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.addTestMethodFail(error.message));
          }
        };

        export const getTestMethodsXHR = async (data: Partial<IDataToApi<null, IApiResponse<ITestMethod[]>>>, dispatch: AppDispatch) => {

          const token = await localStorage.getItem("token");
      
      
              try {
                dispatch(allSlice.actions.getTestMethods());
                const response = await api().get<IApiResponse<ITestMethod[]>>(`laboapp/exam_method/`,
                    {
                        params: data.queryParams,
                       headers: { Authorization: "Bearer " + token }
                    });
                if (data.successCallback) {
                  data.successCallback(response.data);
                }
                dispatch(allSlice.actions.getTestMethodsSuccess(response.data));
              } catch (error: any) { //lkl
                if(error?.response?.status === 401){
                  logoutXHR(null, dispatch)
                }
                if (data.errorCallback) {
                  data.errorCallback(error);
                }
            
                dispatch(allSlice.actions.getTestMethodsFail(error.message));
              }
            };
      
      

            export const updateTestMethodXHR = async (data: Partial<IDataToApi<{name: string}, IApiResponse<ITestMethod>>>, dispatch: AppDispatch) => {

              const token = await localStorage.getItem("token");
          
          
                  try {
                    dispatch(allSlice.actions.updateTestMethod());
                    const response = await api().put<IApiResponse<ITestMethod>>(`laboapp/exam_method/${data.id}/`,
                      data.body,
          
                      {headers: { Authorization: "Bearer " + token }}
                      );
                    if (data.successCallback) {
                      data.successCallback(response.data);
                    }
                    dispatch(allSlice.actions.updateTestMethodSuccess(response.data));
                  } catch (error: any) { //lkl
                    if(error?.response?.status === 401){
                      logoutXHR(null, dispatch)
                    }
                    if (data.errorCallback) {
                      data.errorCallback(error);
                    }
                
                    dispatch(allSlice.actions.updateTestMethodFail(error.message));
                  }
                };
          
          

                export const getTestClustersXHR = async (data: Partial<IDataToApi<null, IApiResponse<ITestCluster[]>>>, dispatch: AppDispatch) => {

                  const token = await localStorage.getItem("token");
              
              
                      try {
                        dispatch(allSlice.actions.getTestClusters());
                        const response = await api().get<IApiResponse<ITestCluster[]>>(`laboapp/test/`,
                            {
                                params: data.queryParams,
                               headers: { Authorization: "Bearer " + token }
                            });
                        if (data.successCallback) {
                          data.successCallback(response.data);
                        }
                        dispatch(allSlice.actions.getTestClustersSuccess(response.data));
                      } catch (error: any) { //lkl
                        if(error?.response?.status === 401){
                          logoutXHR(null, dispatch)
                        }
                        if (data.errorCallback) {
                          data.errorCallback(error);
                        }
                    
                        dispatch(allSlice.actions.getTestClustersFail(error.message));
                      }
                    };


    export const addTestClustersXHR = async (data: Partial<IDataToApi<ITestClusterForm, IApiResponse<ITestCluster>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.addTestClusters());
            const response = await api().post<IApiResponse<ITestCluster>>(`laboapp/test/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.addTestClustersSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.addTestClustersFail(error.message));
          }
        };



        export const getMaterialTypesXHR = async (data: Partial<IDataToApi<null, IApiResponse<IMaterialType[]>>>, dispatch: AppDispatch) => {

          const token = await localStorage.getItem("token");
              try {
                dispatch(allSlice.actions.getMaterialTypes());
                const response = await api().get<IApiResponse<IMaterialType[]>>(`laboapp/material_type/`,
                    {
                        params: data.queryParams,
                       headers: { Authorization: "Bearer " + token }
                    });
                if (data.successCallback) {
                  data.successCallback(response.data);
                }
                dispatch(allSlice.actions.getMaterialTypesSuccess(response.data));
              } catch (error: any) { //lkl
                if(error?.response?.status === 401){
                  logoutXHR(null, dispatch)
                }
                if (data.errorCallback) {
                  data.errorCallback(error);
                }
            
                dispatch(allSlice.actions.getMaterialTypesFail(error.message));
              }
            };

      
    
            export const updateTestClusterXHR = async (data: Partial<IDataToApi<ITestClusterForm, IApiResponse<ITestCluster>>>, dispatch: AppDispatch) => {

              const token = await localStorage.getItem("token");
          
          
                  try {
                    dispatch(allSlice.actions.updateTestCluster());
                    const response = await api().patch<IApiResponse<ITestCluster>>(`laboapp/test/${data.id}/`,
                      data.body,
          
                      {headers: { Authorization: "Bearer " + token }}
                      );
                    if (data.successCallback) {
                      data.successCallback(response.data);
                    }
                    dispatch(allSlice.actions.updateTestClusterSuccess(response.data));
                  } catch (error: any) { //lkl
                    if(error?.response?.status === 401){
                      logoutXHR(null, dispatch)
                    }
                    if (data.errorCallback) {
                      data.errorCallback(error);
                    }
                
                    dispatch(allSlice.actions.updateTestClusterFail(error.message));
                  }
                };
          


    export const getTestClusterDetailsXHR = async (data: Partial<IDataToApi<null, IApiResponse<ITestClusterDetails>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.getTestClusterDetails());
            const response = await api().get<IApiResponse<ITestClusterDetails>>(`laboapp/test/${data.id}/`,
                {
                    params: data.queryParams,
                   headers: { Authorization: "Bearer " + token }
                });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.getTestClusterDetailsSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.getTestClusterDetailsFail(error.message));
          }
        };
  


    export const createTestModelFormXHR = async (data: Partial<IDataToApi<ITestModelForm, IApiResponse<ITestModelForm>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.createTestModelForm());
            const response = await api().post<IApiResponse<ITestModelForm>>(`laboapp/model_form/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.createTestModelFormSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.createTestModelFormFail(error.message));
          }
        };






    export const updateTestModelFormXHR = async (data: Partial<IDataToApi<ITestModelForm & {is_active?: boolean}, IApiResponse<ITestModelForm>>>, dispatch: AppDispatch) => {
      const token = await localStorage.getItem("token");
          try {
            dispatch(allSlice.actions.updateTestModelForm());
            const response = await api().put<IApiResponse<ITestModelForm>>(`laboapp/model_form/${data.id}/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.updateTestModelFormSuccess({...response.data, prev_id: data.id}));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.updateTestModelFormFail(error.message));
          }
        };
  

      export const getTestProductsXHR = async (data: Partial<IDataToApi<null, IApiResponse<ITestProduct[]>>>, dispatch: AppDispatch) => {

        const token = await localStorage.getItem("token");
    
    
            try {
              dispatch(allSlice.actions.getTestProducts());
              const response = await api().get<IApiResponse<ITestProduct[]>>(`laboapp/test_product/`,
                  {
                      params: data.queryParams,
                      headers: { Authorization: "Bearer " + token }
                  });
              if (data.successCallback) {
                data.successCallback(response.data);
              }
              dispatch(allSlice.actions.getTestProductsSuccess(response.data));
            } catch (error: any) { //lkl
              if(error?.response?.status === 401){
                logoutXHR(null, dispatch)
              }
              if (data.errorCallback) {
                data.errorCallback(error);
              }
          
              dispatch(allSlice.actions.getTestProductsFail(error.message));
            }
      };
      
      
      export const getTestProductXHR = async (data: Partial<IDataToApi<null, IApiResponse<ITestProduct>>>, dispatch: AppDispatch) => {

        const token = await localStorage.getItem("token");
    
    
            try {
              dispatch(allSlice.actions.getTestProduct());
              const response = await api().get<IApiResponse<ITestProduct>>(`laboapp/test_product/${data.id}/`,
                  {
                      params: data.queryParams,
                      headers: { Authorization: "Bearer " + token }
                  });
              if (data.successCallback) {
                data.successCallback(response.data);
              }
              dispatch(allSlice.actions.getTestProductSuccess(response.data));
            } catch (error: any) { //lkl
              if(error?.response?.status === 401){
                logoutXHR(null, dispatch)
              }
              if (data.errorCallback) {
                data.errorCallback(error);
              }
          
              dispatch(allSlice.actions.getTestProductFail(error.message));
            }
      };
  
   
      
      export const addTestXHR = async (data: Partial<IDataToApi<IAddTest, IApiResponse<ITestProduct[]>>>, dispatch: AppDispatch) => {

        const token = await localStorage.getItem("token");
    
    
            try {
              dispatch(allSlice.actions.addTest());
              const response = await api().post<IApiResponse<ITestProduct[]>>(`laboapp/test_product/`,
                data.body,
    
                {headers: { Authorization: "Bearer " + token }}
                );
              if (data.successCallback) {
                data.successCallback(response.data);
              }
              dispatch(allSlice.actions.addTestSuccess(response.data));
            } catch (error: any) { //lkl
              if(error?.response?.status === 401){
                logoutXHR(null, dispatch)
              }
              if (data.errorCallback) {
                data.errorCallback(error);
              }
          
              dispatch(allSlice.actions.addTestFail(error.message));
            }
          };


export const createMaterialTypeXHR = async (
  data: Partial<
    IDataToApi<
      { name: string; parent: null | number },
      IApiResponse<IMaterialType>
    >
  >,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(allSlice.actions.createMaterialType());
    const response = await api().post<IApiResponse<IMaterialType>>(
      `laboapp/material_type/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.createMaterialTypeSuccess(response.data.results));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.createMaterialTypeFail(error.message));
  }
};

export const updateMaterialTypeXHR = async (
  data: Partial<
    IDataToApi<
      { parent: number | string | null; name: string },
      IApiResponse<IMaterialType>
    >
  >,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(allSlice.actions.updateMaterialType());
    const response = await api().put<IApiResponse<IMaterialType>>(
      `laboapp/material_type/${data.id}/`,
      data.body,

      { headers: { Authorization: "Bearer " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.updateMaterialTypeSuccess(response.data));
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.updateMaterialTypeFail(error.message));
  }
};

export const updateStandardRequirementsFieldsXHR = async (
  data: Partial<IDataToApi<{standard_requirements_custom_fields: ICustomField[]}, IApiResponse<ITestClusterDetails>>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(allSlice.actions.updateStandardRequirementsFields());
    const response = await api().patch<IApiResponse<ITestClusterDetails>>(
      `laboapp/test/${data.id}/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      allSlice.actions.updateStandardRequirementsFieldsSuccess(response.data)
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(
      allSlice.actions.updateStandardRequirementsFieldsFail(error.message)
    );
  }
};


export const updateProductTestXHR = async (
  data: Partial<IDataToApi<IRefValuesForm & {rank: number}, IApiResponse<ITestProduct[]>>>,
  dispatch: AppDispatch
) => {
  const token = await localStorage.getItem("token");

  try {
    dispatch(allSlice.actions.updateProductTest());
    const response = await api().patch<IApiResponse<ITestProduct[]>>(
      `laboapp/test_product/${data.id}/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } }
    );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(
      allSlice.actions.updateProductTestSuccess(response.data)
    );
  } catch (error: any) {
    //lkl
    if (error?.response?.status === 401) {
      logoutXHR(null, dispatch);
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }
    dispatch(
      allSlice.actions.updateProductTestFail(error.message)
    );
  }
};


export const copyTestsXHR = async (data: Partial<IDataToApi<ICopyTests, IApiResponse<ITestProduct[]>>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");
  try {
    dispatch(allSlice.actions.copyTests());
    const response = await api().post<IApiResponse<ITestProduct[]>>(`laboapp/test_product/copy_test/`,
      data.body,
      {headers: { Authorization: "Bearer " + token }}
      );
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.copyTestsSuccess(response.data));
  } catch (error: any) { //lkl
    if(error?.response?.status === 401){
      logoutXHR(null, dispatch)
    }
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.copyTestsFail(error.message));
  }
};



export const getProductXHR = async (data: Partial<IDataToApi<null, IApiResponse<IProductDetails>>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


      try {
        dispatch(allSlice.actions.getProduct());
        const response = await api().get<IApiResponse<IProductDetails>>(`laboapp/product/${data.id}/`,
            {
                params: data.queryParams,
               headers: { Authorization: "Bearer " + token }
            });
        if (data.successCallback) {
          data.successCallback(response.data);
        }
        dispatch(allSlice.actions.getProductSuccess(response.data));
      } catch (error: any) { //lkl
        if(error?.response?.status === 401){
          logoutXHR(null, dispatch)
        }
        if (data.errorCallback) {
          data.errorCallback(error);
        }
    
        dispatch(allSlice.actions.getProductFail(error.message));
      }
    };


 
  
    export const addUnitOfMesureXHR = async (data: Partial<IDataToApi<ICategory, IApiResponse<ICategory[]>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.addUnitOfMesure());
            const response = await api().post<IApiResponse<ICategory[]>>(`laboapp/measure_unit/`,
              data.body,
  
              {headers: { Authorization: "Bearer " + token }}
              );
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.addUnitOfMesureSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.addUnitOfMesureFail(error.message));
          }
        };
  
      // -----
      
   
      
      
    export const getUnitOfMesureXHR = async (data: Partial<IDataToApi<null, IApiResponse<ICategory[]>>>, dispatch: AppDispatch) => {

      const token = await localStorage.getItem("token");
  
  
          try {
            dispatch(allSlice.actions.getUnitOfMesure());
            const response = await api().get<IApiResponse<ICategory[]>>(`laboapp/measure_unit/`,
                {
                    params: data.queryParams,
                   headers: { Authorization: "Bearer " + token }
                });
            if (data.successCallback) {
              data.successCallback(response.data);
            }
            dispatch(allSlice.actions.getUnitOfMesureSuccess(response.data));
          } catch (error: any) { //lkl
            if(error?.response?.status === 401){
              logoutXHR(null, dispatch)
            }
            if (data.errorCallback) {
              data.errorCallback(error);
            }
        
            dispatch(allSlice.actions.getUnitOfMesureFail(error.message));
          }
        };
        export const updateUnitOfMesureXHR = async (data: Partial<IDataToApi<ICategory, IApiResponse<ICategory>>>, dispatch: AppDispatch) => {
          const token = await localStorage.getItem("token");
              try {
                dispatch(allSlice.actions.updateUnitOfMesure());
                const response = await api().put<IApiResponse<ICategory>>(`laboapp/measure_unit/${data.id}/`,
                  data.body,      
                  {headers: { Authorization: "Bearer " + token }}
                  );
                if (data.successCallback) {
                  data.successCallback(response.data);
                }
                dispatch(allSlice.actions.updateUnitOfMesureSuccess(response.data));
              } catch (error: any) { //lkl
                if(error?.response?.status === 401){
                  logoutXHR(null, dispatch)
                }
                if (data.errorCallback) {
                  data.errorCallback(error);
                }
            
                dispatch(allSlice.actions.updateUnitOfMesureFail(error.message));
              }
            };


export const getProductTestingsXHR = async (data: Partial<IDataToApi<null, IApiResponse<IProductTesting[]>>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");
  try {
    dispatch(allSlice.actions.getProductTestings());
    const response = await api().get<IApiResponse<IProductTesting[]>>(`laboapp/product_testing/?product=${data.id}`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token }
    });

    // Success callback
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.getProductTestingsSuccess(response.data));
  } catch (error: any) {
    // Error
    if(error?.response?.status === 401){
      logoutXHR(null, dispatch)
    }
    // Error callback
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.getProductTestingsFail(error.message));
  }
};

export const createProductTestingXHR = async (data: Partial<IDataToApi<ICreateProductTesting, IApiResponse<ITest>>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");
  try {
    dispatch(allSlice.actions.createProductTesting());
    const response = await api().post<IApiResponse<ITest>>(`laboapp/product_testing/`,
      data.body,
      { headers: { Authorization: "Bearer " + token } }
    );

    // Success callback
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.createProductTestingSuccess(response.data));
  } catch (error: any) {
    // Error
    if(error?.response?.status === 401){
      logoutXHR(null, dispatch)
    }
    // Error callback
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.createProductTestingFail(error.message));
  }
};


export const getProductTestingDetailsXHR = async (data: Partial<IDataToApi<null, IApiResponse<IProductTestingDetails>>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");
  try {
    dispatch(allSlice.actions.getProductTestingDetails());
    const response = await api().get<IApiResponse<IProductTestingDetails>>(`laboapp/product_testing/${data.id}/`, {
      params: data.queryParams,
      headers: { Authorization: "Bearer " + token }
    });

    // Success callback
    if (data.successCallback) {
      data.successCallback(response.data);
    }
    dispatch(allSlice.actions.getProductTestingDetailsSuccess(response.data));
  } catch (error: any) {
    // Error
    if(error?.response?.status === 401){
      logoutXHR(null, dispatch)
    }
    // Error callback
    if (data.errorCallback) {
      data.errorCallback(error);
    }

    dispatch(allSlice.actions.getProductTestingDetailsFail(error.message));
  }
};


export const saveModelFormsXHR = async (data: Partial<IDataToApi<ITestModelFormValues, IApiResponse<ITestingTest>>>, dispatch: AppDispatch) => {

  const token = await localStorage.getItem("token");


      try {
        dispatch(allSlice.actions.saveResTestModelForm());
        let response = await api().patch<IApiResponse<ITestingTest>>(`laboapp/product_testing_test_model_form/${data.id}/`,
          data.body,
          {headers: { Authorization: "Bearer " + token }}
          );
        if (data.successCallback) {
          data.successCallback(response.data);
        }
        dispatch(allSlice.actions.saveResTestModelFormSuccess(response.data));
      } catch (error: any) { //lkl
        if(error?.response?.status === 401){
          logoutXHR(null, dispatch);
        }
        if (data.errorCallback) {
          data.errorCallback(error);
        }
        dispatch(allSlice.actions.saveResTestModelFormFail(error.message));
      }
    };

  // -----
  


  export const getTestingTestXHR = async (data: Partial<IDataToApi<null, IApiResponse<ITestingTest>>>, dispatch: AppDispatch) => {

    const token = await localStorage.getItem("token");


        try {
          dispatch(allSlice.actions.getTestingTest());
          let response = await api().get<IApiResponse<ITestingTest>>(`laboapp/product_testing_test/${data.id}/`,
              {
                  params: data.queryParams,
                 headers: { Authorization: "Bearer " + token }
              });
          if (data.successCallback) {
            data.successCallback(response.data);
          }
          dispatch(allSlice.actions.getTestingTestSuccess(response.data));
        } catch (error: any) { //lkl
          if(error?.response?.status === 401){
            logoutXHR(null, dispatch)
          }
          if (data.errorCallback) {
            data.errorCallback(error);
          }
      
          dispatch(allSlice.actions.getTestingTestFail(error.message));
        }
      };


      export const updateTestingTestXHR = async (data: Partial<IDataToApi<Partial<UpdateData>, IApiResponse<ITestingTest>>>, dispatch: AppDispatch) => {

        const token = await localStorage.getItem("token");

        try {
              dispatch(allSlice.actions.updateTestingTest());
              let response = await api().patch<IApiResponse<ITestingTest>>(`laboapp/product_testing_test/${data.id}/`,
                data.body,
    
                {headers: { Authorization: "Bearer " + token }}
                );
              if (data.successCallback) {
                data.successCallback(response.data);
              }
              dispatch(allSlice.actions.updateTestingTestSuccess(response.data));
        } catch (error: any) { //lkl
              if(error?.response?.status === 401){
                logoutXHR(null, dispatch)
              }
              if (data.errorCallback) {
                data.errorCallback(error);
              }
          
              dispatch(allSlice.actions.updateTestingTestFail(error.message));
        }
      };
    

      export const updateProductTestingXHR = async (data: Partial<IDataToApi<any, IApiResponse<IProductTestingDetails>>>, dispatch: AppDispatch) => {

        const token = await localStorage.getItem("token");
    
    
            try {
              dispatch(allSlice.actions.updateProductTesting());
              let response = await api().patch<IApiResponse<IProductTestingDetails>>(`laboapp/product_testing/${data.id}/`,
                data.body,
    
                {headers: { Authorization: "Bearer " + token }}
                );
              if (data.successCallback) {
                data.successCallback(response.data);
              }
              dispatch(allSlice.actions.updateProductTestingSuccess(response.data));
            } catch (error: any) { //lkl
              if(error?.response?.status === 401){
                logoutXHR(null, dispatch)
              }
              if (data.errorCallback) {
                data.errorCallback(error);
              }
          
              dispatch(allSlice.actions.updateProductTestingFail(error.message));
            }
          };
    

        

          export const productTestCopyXHR = async (data: Partial<IDataToApi<{
            test: number,
            colors: number[],
            copy_results: boolean,
          }, IApiResponse<ITest[]>>>, dispatch: AppDispatch) => {

            const token = await localStorage.getItem("token");

                try {
                  dispatch(allSlice.actions.productTestCopy());
                  let response = await api().post<IApiResponse<ITest[]>>(`laboapp/product_test_copy/`,
                    data.body,
        
                    {headers: { Authorization: "Bearer " + token }}
                    );
                  if (data.successCallback) {
                    data.successCallback(response.data);
                  }
                  dispatch(allSlice.actions.productTestCopySuccess(response.data));
                } catch (error: any) { //lkl
                  if(error?.response?.status === 401){
                    logoutXHR(null, dispatch)
                  }
                  if (data.errorCallback) {
                    data.errorCallback(error);
                  }
              
                  dispatch(allSlice.actions.productTestCopyFail(error.message));
                }
              };
        
        

              export const getColorsXHR = async (data: Partial<IDataToApi<null, IApiResponse<IColor[]>>>, dispatch: AppDispatch) => {

                const token = await localStorage.getItem("token");
            
            
                    try {
                      dispatch(allSlice.actions.getColors());
                      let response = await api().get<IApiResponse<IColor[]>>(`laboapp/color/`,
                          {
                              params: data.queryParams,
                             headers: { Authorization: "Bearer " + token }
                          });
                      if (data.successCallback) {
                        data.successCallback(response.data);
                      }
                      dispatch(allSlice.actions.getColorsSuccess(response.data));
                    } catch (error: any) { //lkl
                      if(error?.response?.status === 401){
                        logoutXHR(null, dispatch)
                      }
                      if (data.errorCallback) {
                        data.errorCallback(error);
                      }
                  
                      dispatch(allSlice.actions.getColorsFail(error.message));
                    }
                  };

                  export const saveColorXHR = async (data: Partial<IDataToApi<{name: string}, IApiResponse<IColor[]>>>, dispatch: AppDispatch) => {

                    const token = await localStorage.getItem("token");
                
                
                        try {
                          dispatch(allSlice.actions.saveColor());
                          let response = await api().post<IApiResponse<IColor[]>>(`laboapp/color/`, data.body,
                              {
                                 headers: { Authorization: "Bearer " + token }
                              });
                          if (data.successCallback) {
                            data.successCallback(response.data);
                          }
                          dispatch(allSlice.actions.saveColorSuccess(response.data));
                        } catch (error: any) { //lkl
                          if(error?.response?.status === 401){
                            logoutXHR(null, dispatch)
                          }
                          if (data.errorCallback) {
                            data.errorCallback(error);
                          }
                      
                          dispatch(allSlice.actions.saveColorFail(error.message));
                        }
                      };

                      export const updateColorXHR = async (data: Partial<IDataToApi<{name: string}, IApiResponse<IColor>>>, dispatch: AppDispatch) => {

                        const token = await localStorage.getItem("token");
                    
                    
                            try {
                              dispatch(allSlice.actions.updateColor());
                              let response = await api().put<IApiResponse<IColor>>(`laboapp/color/${data.id}/`,
                                data.body,
                    
                                {headers: { Authorization: "Bearer " + token }}
                                );
                              if (data.successCallback) {
                                data.successCallback(response.data);
                              }
                              dispatch(allSlice.actions.updateColorSuccess(response.data));
                            } catch (error: any) { //lkl
                              if(error?.response?.status === 401){
                                logoutXHR(null, dispatch)
                              }
                              if (data.errorCallback) {
                                data.errorCallback(error);
                              }
                          
                              dispatch(allSlice.actions.updateColorFail(error.message));
                            }
                          };
                    
                          export const getReportDataXHR = async (data: Partial<IDataToApi<null, any>>, dispatch: AppDispatch) => {


                            const token = await localStorage.getItem("token");
                          
                                try {
                                  dispatch(allSlice.actions.getReportData());
                                  let response = await api().get<IApiResponse<any>>(`reports/report_data/`,
                                      {
                                          params: data.queryParams,
                                         headers: { Authorization: "Bearer " + token }
                                      });
                                  if (data.successCallback) {
                                    data.successCallback(response.data);
                                  }
                                  dispatch(allSlice.actions.getReportDataSuccess(response.data));
                                } catch (error: any) { //lkl
                                  if(error?.response?.status === 401){
                                    logoutXHR(null, dispatch)
                                  }
                                  if (data.errorCallback) {
                                    data.errorCallback(error);
                                  }
                             
                                  dispatch(allSlice.actions.getReportDataFail(error.message));
                                }
                              };
                          