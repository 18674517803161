import { Button, Checkbox, Descriptions, message, Tabs, Typography } from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../hooks";
import { ITestModelFormValues } from "../../../../../models/all";
import { saveModelFormsXHR, updateTestingTestXHR } from "../../../../../store/reducers/all/allActionCreator";
import { CheckboxChangeEvent } from "antd/lib/checkbox";


interface IProps{
  setActiveModelForm: (modelForm: ITestModelFormValues | undefined) => void
}

const { TabPane } = Tabs;



const Obrasci: React.FC<IProps> = ({setActiveModelForm}) => {
  const { test_id } = useParams();
  const {  testingTest } = useAppSelector((state) => state.allReducer);
  const [activeKey, setActiveKey] = React.useState<string>();
  const dispatch = useAppDispatch();

  const onTabChange = (path: string) => {
    setActiveKey(path);
    let modelForm = testingTest?.model_forms.find(x => x.id === +path);
    if(modelForm) setActiveKey(path);
  };

  useEffect(() => {
    if(testingTest && testingTest?.model_forms.length > 0){
      let tmp = [...testingTest.model_forms].sort((a,b) => b.id - a.id);
      setActiveKey(tmp[0].id.toString());
    }
  }, [testingTest?.id])

 
  useEffect(() => {
    setActiveModelForm(testingTest?.model_forms.find(x => x.id.toString() === activeKey)
    || undefined);
  }
  , [activeKey])






  

  const handleCheckboxChange = (e: CheckboxChangeEvent, modelFormID: number, modelForm: ITestModelFormValues) => {


    let body: ITestModelFormValues = {
      ...modelForm,
      included_in_result: e.target.checked
    }
    saveModelFormsXHR(
      {
        body,
        id: modelForm.id,
        errorCallback: () => {
          message.error("Dogodila se greška...");
        },
        successCallback: ({results}) => {
          setActiveKey(modelFormID.toString());
          setActiveModelForm(results.model_forms.find(x => x.id === modelFormID) || undefined);
        }
      },
      dispatch
    );
  }




  return (
    <div>
       <Tabs activeKey={activeKey} onChange={onTabChange}>
        {testingTest? [...testingTest.model_forms].sort((a, b) => b.id - a.id).map((x, i) => (
        <TabPane tab={<span>{x.model_form.name} <Checkbox checked={x.included_in_result} onChange={(e) => handleCheckboxChange(e, x.id, x)} /></span>} key={x.id}>
          {/* Tab content here */}
        </TabPane>
      )) : null}
      </Tabs>
    </div> 
  );
};

export default Obrasci;


// Sorter function based on the "name" property of "ICategory"
const sortTestModelFormValuesByName = (a: ITestModelFormValues, b: ITestModelFormValues): number => {
  const nameA = a.model_form.name.toLowerCase();
  const nameB = b.model_form.name.toLowerCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};
