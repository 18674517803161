import React, { useEffect } from "react";

import { Button, Form, Input, message, Select } from "antd";

import { useAppDispatch } from "../../hooks";
import { ICategory } from "../../models/all";
import {
  addEquipmentCategoryXHR,
  addProductCategoryXHR,
  addStandardsXHR,
  addTestMethodXHR,
  addUnitOfMesureXHR,
  saveColorXHR,
  updateColorXHR,
  updateEquipmentCategoryXHR,
  updateProductCategoriesXHR,
  updateStandardsXHR,
  updateTestMethodXHR,
  updateUnitOfMesureXHR,
} from "../../store/reducers/all/allActionCreator";

const { Option } = Select;

interface IProps {
  type?: "testMethod" | "equipment" | "standard" | "uom" | "color";  
  category: undefined | ICategory;
  close: () => void;
}

const CategoryForm: React.FC<IProps> = ({ category, close, type }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onFinish = (values: ICategory) => {

    if(type === "color"){
      if(category?.id === 0){
        saveColorXHR({
          body: values,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        }, dispatch);
        return;
      }else{
        updateColorXHR({
          body: values,
          id: category?.id,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        }, dispatch);
        return;
      }
    }

    if(type === "uom"){
      if(category?.id === 0){
        addUnitOfMesureXHR({
          body: values,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        }, dispatch);
        return;
      }else{
        updateUnitOfMesureXHR({
          body: values,
          id: category?.id,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        }, dispatch);
        return;
      }
    }


    if (!!category?.id) {


      if(type === "testMethod"){
        updateTestMethodXHR( {
          id: category.id.toString(),
          body: values,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        },
        dispatch
      )
      return;
      }

      if(type === "equipment"){
        updateEquipmentCategoryXHR( {
          id: category.id.toString(),
          body: values,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        },
        dispatch
      )
      return;
      }

      if(type === "standard"){
        updateStandardsXHR(
          {
            id: category.id.toString(),
            body: values,
            errorCallback: (data: any) =>
              message.error("Dogodila se greška na spremanje podataka"),
            successCallback: () => close(),
          },
          dispatch
        )
        return;
      }

      updateProductCategoriesXHR(
        {
          id: category.id.toString(),
          body: values,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        },
        dispatch
      );

      return;
    }

    if(type === "testMethod"){
      addTestMethodXHR({
        body: values,
        errorCallback: () =>
        message.error("Dogodila se greška na spremanje podataka"),
        successCallback: () => close()
      }, dispatch)
      return;
    }



    if(type === "standard"){
      addStandardsXHR(
        {
          body: values,
          errorCallback: () =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        },
        dispatch
      )
      return;
    }   

    if(type === "equipment"){
      addEquipmentCategoryXHR(
        {
          body: values,
          errorCallback: () =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        },
        dispatch
      )
      return;
    }




    addProductCategoryXHR(
      {
        body: values,
        errorCallback: () =>
          message.error("Dogodila se greška na spremanje podataka"),
        successCallback: () => close(),
      },
      dispatch
    );
  };

  return (
    <Form
      form={form}
      initialValues={category}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name={"name"}
        label={"Naziv"}
        rules={[{ required: true, message: "Ovo polje je obavezno" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Spremi
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CategoryForm;
