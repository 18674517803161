import React, { useEffect } from "react";

import { Button, Form, Input, InputNumber, message, Select } from "antd";


import { IApiResponse } from "../../models";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAccountXHR, addClientXHR, updateAccountXHR, updateClientXHR } from "../../store/reducers/all/allActionCreator";
import { IClient } from "../../models/all";

const { Option } = Select;

interface IProps {
  account: undefined | IAccountForm;
  close: () => void;
}

export interface IAccountForm {
    id: number,
    name: string,
    email: string
  }

const ClientForm: React.FC<IProps> = ({ account, close }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { addClientStatus } = useAppSelector((state) => state.allReducer);


  const onFinish = (values: IAccountForm) => {
    if (!!account?.id) {
      updateAccountXHR(
        {
          id: account.id.toString(),
          body: values,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        },
        dispatch
      );

      return;
    }

    addAccountXHR(
      {
        body: values,
        errorCallback: () => message.error("Dogodila se greška na spremanje podataka"),
        successCallback: () => close(),
      },
      dispatch
    );
  };

 
 

  return (
    <Form 
        form={form} 
        initialValues={account} 
        layout="vertical" 
        onFinish={onFinish}
    >

      {/* Name */}
      <Form.Item
        name={"name"}
        label={"Naziv"}
        rules={[{ required: true, message: "Ovo polje je obavezno" }]}
      >
        <Input />
      </Form.Item>

      {/* Email */}
      <Form.Item
        name={"email"}
        label={"Email"}
        rules={[{ required: true, message: "Ovo polje je obavezno" }]}
      >
        <Input />
      </Form.Item>
      
      {/* Submit button */}
      <Form.Item>
        <Button
          loading={addClientStatus === "loading"}
          htmlType="submit"
          type="primary"
        >
          {"Spremi"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ClientForm;
