import React from "react";
import { useRoutes } from "react-router-dom";
import { useAppSelector } from "../hooks";
import {Routes} from "./routes";
const RoutesGenerator = (): React.ReactElement<
  any,
  string | React.JSXElementConstructor<any>
> | null => {
  const auth: boolean = useAppSelector(state => !!state.userReducer.user.token.access)

  return useRoutes([Routes(auth)], "");
};

export default RoutesGenerator;
