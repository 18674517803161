import React from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import { logoutXHR } from "../../store/reducers/user/actionCreators";

const Logout = () => {
  const dispatch = useAppDispatch();

  logoutXHR(null, dispatch);

  return <Navigate to="" />;
};

export default Logout;
  