
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import moment from "moment";
import { Button, Descriptions, Drawer, message, Popconfirm, Spin, Row, Col, Table, Tag } from "antd";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { finishCalibrationXHR, getEquipmentXHR } from "../../store/reducers/all/allActionCreator";
import { CheckCircleOutlined, ClockCircleOutlined, DeleteOutlined, EditOutlined, SyncOutlined } from "@ant-design/icons";
import { allSlice } from "../../store/reducers/all";
import { IApiResponse, ITableColumn } from "../../models";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import api from "../../services";
import ProductForm, { IEquipmentForm } from "../Equipment/EquipmentForm";
import { IEquipment, IFinishedCalibrations } from "../../models/all";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";

interface IStatusObject {
  formatedText: string,
  tagColor: string,
  tagIcon: JSX.Element
}

interface IStatuses {
  [key: string]: IStatusObject | undefined
}

interface ICalibrationUnit {
  [key: string]: string | undefined
}

const EquipmentDetails: React.FC = () => {

  // Hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams();

  // Variables
  const [loading, set_loading] = useState<boolean>(false);
  const [editEquipment, set_editEquipment] = useState<IEquipmentForm>();
  const [calibrationHistory, set_calibrationHistory] = useState<IFinishedCalibrations[]>();
  const [calibrationHistoryLoading, set_calibrationHistoryLoading] = useState<boolean>();
  const { equipment, equipments, getEquipmentStatus, finishCalibrationStatus } = useAppSelector(state => state.allReducer)
  

  // Constants
  const STATUSES: IStatuses = {
    waiting: {
      formatedText: "Na čekanju",
      tagColor: "processing",
      tagIcon: <SyncOutlined spin />
    },
    finished: {
      formatedText: "Završena",
      tagColor: "success",
      tagIcon: <CheckCircleOutlined />
    }
  }

  const CALIBRATION_UNITS: ICalibrationUnit = {
    day: 'Dan',
    week: "Tjedan",
    month: "Mjesec",
    year: "Godina"
  }

  // Table
  const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IFinishedCalibrations>[]>([
    {
      title: "Planirano vrijeme",
      dataIndex: "planned_at",
      render: (text: string, value: IFinishedCalibrations) => {
        return  moment(value.planned_at).isValid() ? moment(value.planned_at).format('LLLL') : "-"
      }
    },
    {
      title: "Vrijeme završetka",
      dataIndex: "finished_at",
      render: (text: string, value: IFinishedCalibrations) => {
        return moment(value.finished_at).isValid() ? moment(value.finished_at).format('LLLL') : "-"
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: string, value: IFinishedCalibrations) => {
        return (
          <Tag 
            color={STATUSES[value.status]?.tagColor}
            icon={STATUSES[value.status]?.tagIcon}
          >
            { STATUSES[value.status]?.formatedText }
          </Tag>
        )
      }
    },
    { title: "Potvrdio", dataIndex: "finished_by" },
    {
      title: "Potvrdi kalibraciju",
      dataIndex: "action",
      render: (text: string, value: IFinishedCalibrations) => {
        if(value.status === 'waiting'){
          return (
            <Popconfirm
              onConfirm={finishCalibration}
              title="Da li stvarno želite potvrditi izvršenje kalibracije!"
              cancelText="Odustani"
            >
              <a>Potvrdi</a>
            </Popconfirm>
          )
        }
      }
    },
  ]);

  // Methods
  useEffect(() => {
    getEquipmentXHR(
      {
        id,
        errorCallback: (error: any) => message.error("Error"),
      },
      dispatch
    );
    getCalibrationHistory()
  }, []);

  const finishCalibration = () => {
    finishCalibrationXHR(
      {
        body: { equipment_id: Number(id) },
        errorCallback: (error: any) => message.error("Error"),
        successCallback: () => finishCalibrationCallback()
      },
      dispatch
    );
  }

  const finishCalibrationCallback = () => {
    getEquipmentXHR({ id, errorCallback: (error: any) => message.error("Error")}, dispatch);
    getCalibrationHistory()
  }

  const getCalibrationHistory = async () => {
    set_calibrationHistoryLoading(true);

    try {
      let token = await localStorage.getItem("token");
      let response = await api().get<IApiResponse<IFinishedCalibrations[]>>(
        `equipment/equipment/${id}/calibration_history/`,
        { headers: { Authorization: "Bearer " + token } }
      );
      set_calibrationHistory(response.data.results)
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }
      message.error("Dogodila se greška kod dohvata podataka...");
    }
    set_calibrationHistoryLoading(false);
  };

  const deleteItem = async (id: number) => {
    set_loading(true);

    try {
      let token = await localStorage.getItem("token");
      let response = await api().delete<IApiResponse<string>>(
        `equipment/equipment/${id}/`,
        { headers: { Authorization: "Bearer " + token } }
      );

      let tmp = [...equipments];
      let index = tmp.findIndex((x) => x.id === id);
      tmp.splice(index, 1);
      dispatch(
        allSlice.actions.getEqipmentSuccess({ results: tmp, message: "" })
      );
      message.success("Uspješno ste izbrisali opremu.")
      navigate('/equipment')
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }

      message.error("Dogodila se greška kod brisanja...");
    }

    set_loading(false);
  };

  const validate = (value: any) => value != null ? value : "-"

  return(
    <Spin spinning={getEquipmentStatus === 'loading' || loading}>
      <div>
          <div className="spaceBetweenRow" style={{ padding: 15 }}>
              <h1>{equipment?.name || "Ime opreme"}</h1>
              <div>
                <Popconfirm
                  onConfirm={() => deleteItem(Number(id))}
                  title="Nastaviti sa akcijom..."
                  cancelText="Odustani"
                >
                  <Button danger type="dashed">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
                <Button
                  style={{ marginLeft: 12 }}
                  onClick={() =>
                    set_editEquipment({
                      id: equipment.id,
                      name: equipment.name,
                      oem: equipment.oem,
                      type: equipment.type,
                      category: equipment.category?.id || 0,
                      serial_number: equipment.serial_number,
                      calibration_every: equipment.calibration_every,
                      calibration_unit: equipment.calibration_unit,
                      next_calibration: moment(equipment.next_calibration).isValid() ? moment(equipment.next_calibration) : equipment.next_calibration,
                      calibration_required: equipment.calibration_required,
                      certificate_number: equipment.certificate_number,
                      measuring_range: equipment.measuring_range,
                      measurable_insecurity: equipment.measurable_insecurity,
                      calibration_body: equipment.calibration_body,
                    })
                  }
                >
                  <EditOutlined />
                </Button>
              </div>
          </div>
      </div>

      <Row gutter={24}>
        <Col span={10}>
          <Descriptions 
            layout="vertical" 
            bordered
            labelStyle={{ fontWeight: 600 }}
          >
            <Descriptions.Item span={2} label="Naziv">{ equipment?.name || "-" }</Descriptions.Item>
            <Descriptions.Item span={2} label="Kategorija">{ equipment?.category?.name || "-" }</Descriptions.Item>
            <Descriptions.Item span={2} label="Proizvođač">{ equipment?.oem || "-" }</Descriptions.Item>
            <Descriptions.Item span={2} label="Tip">{ equipment?.type || "-" }</Descriptions.Item>
            <Descriptions.Item span={2} label="Serijski broj">{ equipment?.serial_number || "-" }</Descriptions.Item>
            <Descriptions.Item span={2} label="Vrijeme sljedeće kalibracije">
              {
                moment(equipment?.next_calibration).isValid() ? 
                moment(equipment?.next_calibration).format('LLL') : 
                "-"
              }  
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Ponavljanje kalibracije">
              { 
                equipment?.calibration_every + 
                " - " + 
                validate(CALIBRATION_UNITS[equipment?.calibration_unit]) 
              }
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Broj certifikata">{ equipment?.certificate_number || "-" }</Descriptions.Item>
            <Descriptions.Item span={2} label="Mjerni opseg">{ equipment?.measuring_range || "-" }</Descriptions.Item>
            <Descriptions.Item span={2} label="Kalibracijsko tijelo">{ equipment?.calibration_body || "-" }</Descriptions.Item>
            <Descriptions.Item span={2} label="Mjerna nesigurnost">{ equipment?.measurable_insecurity || "-" }</Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={14}>
          <Spin spinning={calibrationHistoryLoading || finishCalibrationStatus === "loading"}>
              <Table
                rowKey={(item) => item.id}
                dataSource={calibrationHistory}
                columns={COLUMNS}
              />
            </Spin>
        </Col>
      </Row>

      <Drawer
        title={"Oprema"}
        width={540}
        destroyOnClose={true}
        children={
          <ProductForm 
            equipment={editEquipment} 
            close={() => set_editEquipment(undefined)}
            set_calibrationHistory={set_calibrationHistory}
          />
        }
        visible={!!editEquipment}
        onClose={() => set_editEquipment(undefined)}
      />

    </Spin>
  )
}

export default EquipmentDetails;