import { Button, DatePicker, Form, Input, message } from "antd";
import { useParams } from "react-router-dom";
import { idText } from "typescript";
import { TypesOfAction } from ".";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ITestModelForm } from "../../models/all";
import { createTestModelFormXHR, updateTestModelFormXHR } from "../../store/reducers/all/allActionCreator";
import { RULES } from "../TestClusters/TestClusterForm";
import moment, {Moment} from "moment";

 interface IProps {
     model?: ITestModelForm,
     close: () => void,
     typeOfAction?: TypesOfAction,
     set_indexToCopy: (activeTabIndex: number) => void
 }

const TestModelForm:React.FC<IProps> = ({model, close, set_indexToCopy, typeOfAction}) =>{

    const {testClusterDetails} = useAppSelector(state => state.allReducer);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const {id} = useParams();
    const {createTestModelFormStatus} = useAppSelector(state => state.allReducer);
    const onFinish = (values: {label: string, name: string, datetime: Moment, version: string} )=> {
        console.log(values);
        let tmpModel: ITestModelForm = { label: values.label, name: values.name,
            version: values.version,
            datetime: values.datetime.toISOString(),
            other_custom_fields: [], 
            prepare: [],
            test:  id ?  +id : 0,
            id: 0
        };
        if(typeOfAction === "updateing" && model){
            updateTestModelFormXHR({
                successCallback: () => {
                    close();
                },
                id: model.id.toString(),
                body: {...model,version: tmpModel.version, datetime: tmpModel.datetime,  name: values.name, label: values.label, test: tmpModel.test}, 
            errorCallback: () => message.error("Dogodila se greška tokom kopiranja.")
            }, dispatch)
            return;
        }

        
        if(tmpModel.id === 0){
            createTestModelFormXHR({
                successCallback: (data) => {
                    set_indexToCopy(testClusterDetails?.model_forms.length || 0);

                    if(model){
                        updateTestModelFormXHR({
                            successCallback: () => {
                            },
                            id: data.results.id.toString(),
                            body: {...data.results, other_custom_fields: model.other_custom_fields, prepare: model.prepare,test: tmpModel.test}, 
                        errorCallback: () => message.error("Dogodila se greška tokom kopiranja.")
                        }, dispatch)
                    }
                    close();
                },
                body: tmpModel, errorCallback: () => message.error("Greska na spremanje...")
            
        }, dispatch)
            return;
        }
    }
    console.log(model);
    return <>
        <Form initialValues={{
            ...model,
            datetime: typeOfAction === "adding" || typeOfAction === "copying" ? moment() : model?.datetime ? moment(model.datetime) : undefined
        }} layout="vertical" form={form} onFinish={onFinish}>
            <Form.Item label="Naziv" rules={RULES} name={"name"} >
                <Input />
            </Form.Item>
            <Form.Item label="Oznaka" rules={RULES} name={"label"} >
                <Input />
            </Form.Item>
            <Form.Item label="Verzija" rules={RULES} name="version" >
                <Input />
            </Form.Item>
            <Form.Item label="Datum" rules={RULES} name="datetime" >
                <DatePicker />
            </Form.Item>
            <Form.Item>
                <Button loading={createTestModelFormStatus === "loading"} htmlType="submit" type="primary"    >Spremi</Button>
            </Form.Item>
        </Form>
        </>
}

export default TestModelForm;