import { Input, InputNumber } from "antd";
import React from "react";
import { ICustomField, ICustomValue } from "../../../../../models/all";
import { useAppSelector } from "../../../../../hooks";

interface IProps {
  field: ICustomField;
  value: ICustomValue;
  disabled?: boolean;
  inputStyle?: React.CSSProperties;
  save?: (value: ICustomValue) => void;
}

const InputResult: React.FC<IProps> = ({ field, value, disabled, inputStyle, save }) => {
  const [v, set_v] = React.useState<string | number | null>(value.value);


  React.useEffect(() => {
    if(value.value == v){return}
    set_v(value.value)
  }, [value.value]);

  const _save = (v: string | number | null) => {
    if(v === undefined || save === undefined){return}
      set_v(v);
      save({...value, value: v})
  }


  


  return field.type === "text" || (field.type as any)=== "string" ? ( // prije su kolone bile string pa sam morao dodati ovaj any
    <Input
      id={field.key}
      // onBlur={onBlur}
      value={v || ""}
      onChange={({ target: { value } }) => _save(value)}
      disabled={disabled}
      style={{...inputStyle}}
    />
  ) : (
    <InputNumber  
      id={field.key}
      value={v}
      // onBlur={onBlur}
      onChange={(value) => _save(value)}
      disabled={disabled} 
      style={{...inputStyle}}
    />
  );
};

export default InputResult;



export const setNumOrSting = (value: string | number | null, type: "text" | "number" | "") => {
  if (type === "text" || type === "") {
    return value ? value.toString() : null;
  } else {
    return value ? Number(value) : null;
  }
};