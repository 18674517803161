import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { Button, Col, Divider, Drawer, List, message, Popconfirm, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse } from "../../../models";
import { ICategory, IStandard } from "../../../models/all";
import api from "../../../services";
import { allSlice } from "../../../store/reducers/all";
import { getEquipmentCategoryXHR, getProductCategoriesXHR, getStandardsXHR } from "../../../store/reducers/all/allActionCreator";
import { logoutXHR } from "../../../store/reducers/user/actionCreators";
import CategoryForm from "./../CategoryForm";
import Colors from "./colors";
import MaterialTypes from "./MaterialTypes";
import UnitOfMesure from "./UnitOfMesure";

const Categories: React.FC = () => {
  const { productCategories, 
    getProductCategoriesStatus,
    equipmentCategories,
    addProductCategoryStatus,
    
  } = useAppSelector(
    (state) => state.allReducer  
  );

  const [productCategory, set_productCategorty] = useState<ICategory>();
  const [equipmentCategory, set_equipmentCategory] = useState<ICategory>()
  const [standard, set_standard] = useState<IStandard>();
  const [loading, set_loading] = useState<boolean>(false);
  const [loadingDeleteEquipmentCategory, set_loadingDeleteEquipmentCategory] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getProductCategoriesXHR({}, dispatch);
    getEquipmentCategoryXHR({}, dispatch)
    getStandardsXHR({}, dispatch);
  }, []);

  const deleteItem = async (id: number) => {

    set_loading(true);

    try {
      let token = await localStorage.getItem("token");
      let response = await api().delete<IApiResponse<string>>(
        `laboapp/productcategory/${id}/`,
        { headers: { Authorization: "Bearer " + token } }
      );

      let tmp = [...productCategories];
      let index = tmp.findIndex((x) => x.id === id);
      tmp.splice(index, 1);
      dispatch(allSlice.actions.getProductCategoriesSuccess({ results: tmp, message: "" }));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }

      message.error("Dogodila se greška kod brisanja...");
    }

    set_loading(false);

  };

  

  const deleteEquipmentCategory = async (id: number) => {

    set_loadingDeleteEquipmentCategory(true);

    try {
      let token = await localStorage.getItem("token");
      let response = await api().delete<IApiResponse<string>>(
        `equipment/categories/${id}/`,
        { headers: { Authorization: "Bearer " + token } }
      );

      let tmp = [...equipmentCategories];
      let index = tmp.findIndex((x) => x.id === id);
      tmp.splice(index, 1);
      dispatch(allSlice.actions.getEquipmentCategorySuccess({ results: tmp, message: "" }));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }

      message.error("Dogodila se greška kod brisanja...");
    }

    set_loadingDeleteEquipmentCategory(false);
  }

  return (
    <>
      <Row>
      <Col style={{padding: 6}} span={8}>
          <List
            dataSource={productCategories}
            loading={getProductCategoriesStatus === "loading" || loading}
            header={
              <div className="spaceBetweenRow">
                <Typography.Title level={4}>
                  Kategorije proizvoda
                </Typography.Title>
                <Button
                  onClick={() => set_productCategorty({ id: 0, name: "" })}
                  type="primary"
                >
                  Dodaj
                </Button>
              </div>
            }
            renderItem={(item: ICategory, index: number) => (
              <List.Item
                actions={[
                  <Popconfirm
                    onConfirm={() => deleteItem(item.id)}
                    title="Nastaviti sa akcijom..."
                    cancelText="Odustani"
                  >
                    <Button danger type="dashed">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>,
                  <Button
                    onClick={() =>
                      set_productCategorty({ id: item.id, name: item.name })
                    }
                  >
                    <EditFilled />
                  </Button>,
                ]}
              >
                <List.Item.Meta title={item.name} />
              </List.Item>
            )}
          />
          <Divider />
          <UnitOfMesure />
        </Col>
        <Col style={{padding: 6}} span={8}>
                <MaterialTypes />
                <Colors />
        </Col>
        <Col style={{padding: 6}} span={8}>
          <List
            dataSource={equipmentCategories}
            loading={getProductCategoriesStatus === "loading" || addProductCategoryStatus==="loading" ||  addProductCategoryStatus=== "loading" || loadingDeleteEquipmentCategory}
            header={
              <div className="spaceBetweenRow">
                <Typography.Title level={4}>
                  Kategorije opreme
                </Typography.Title>
                <Button
                  onClick={() => set_equipmentCategory({ id: 0, name: "" })}
                  type="primary"
                >
                  Dodaj
                </Button>
              </div>
            }
            renderItem={(item: ICategory, index: number) => (
              <List.Item
                actions={[
                  <Popconfirm
                    onConfirm={() => deleteEquipmentCategory(item.id)}
                    title="Nastaviti sa akcijom..."
                    cancelText="Odustani"
                  >
                    <Button danger type="dashed">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>,
                  <Button
                    onClick={() =>
                      set_equipmentCategory({ id: item.id, name: item.name })
                    }
                  >
                    <EditFilled />
                  </Button>,
                ]}
              >
                <List.Item.Meta title={item.name} />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <Drawer
        title={"Kategorije opreme"}
        width={540}
        destroyOnClose={true}
        
        children={
          <CategoryForm
          type="equipment"
            category={equipmentCategory}
            close={() => set_equipmentCategory(undefined)}
          />
        }
        visible={!!equipmentCategory}
        onClose={() => set_equipmentCategory(undefined)}
      />



      <Drawer
        title={"Kategorije proizvoda"}
        width={540}
        destroyOnClose={true}
        children={
          <CategoryForm
            category={productCategory}
            close={() => set_productCategorty(undefined)}
          />
        }
        visible={!!productCategory}
        onClose={() => set_productCategorty(undefined)}
      />
    </>
  );
};

export default Categories;