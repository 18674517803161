import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { Button, Drawer, List, message, Popconfirm, Typography } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { IApiResponse } from "../../../models";
import { ICategory, IStandard } from "../../../models/all";
import api from "../../../services";
import { allSlice } from "../../../store/reducers/all";
import { getColorsXHR, getEquipmentCategoryXHR, getProductCategoriesXHR, getStandardsXHR } from "../../../store/reducers/all/allActionCreator";
import { logoutXHR } from "../../../store/reducers/user/actionCreators";
import CategoryForm from "./../CategoryForm";

const Colors: React.FC = () => {
  const { getColorsStatus, colors } = useAppSelector(state => state.allReducer);

  const [color, set_color] = useState<ICategory>();
  const [loading, set_loading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getColorsXHR({
        errorCallback: (error: any) => {
            message.error("Dogodila se greška kod dobavljanja boja...");},
    }, dispatch);
  }, []);

  const deleteItem = async (id: number) => {

    set_loading(true);

    try {
      let token = await localStorage.getItem("token");
      let response = await api().delete<IApiResponse<string>>(
        `laboapp/color/${id}/`,
        { headers: { Authorization: "Bearer " + token } }
      );

      let tmp = [...colors];
      let index = tmp.findIndex((x) => x.id === id);
      tmp.splice(index, 1);
      dispatch(allSlice.actions.getColorsSuccess({ results: tmp, message: "" }));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }

      message.error("Dogodila se greška kod brisanja...");
    }

    set_loading(false);

  };

  

 
  return (
    <>
          <List
            dataSource={colors}
            loading={getColorsStatus === "loading" || loading}
            header={
              <div className="spaceBetweenRow">
                <Typography.Title level={4}>
                  Komponente
                </Typography.Title>
                <Button
                  onClick={() => set_color({ id: 0, name: "" })}
                  type="primary"
                >
                  Dodaj
                </Button>
              </div>
            }
            renderItem={(item: ICategory, index: number) => (
              <List.Item
                actions={[
                  <Popconfirm
                    onConfirm={() => deleteItem(item.id)}
                    title="Nastaviti sa akcijom..."
                    cancelText="Odustani"
                  >
                    <Button danger type="dashed">
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>,
                  <Button
                    onClick={() =>
                      set_color({ id: item.id, name: item.name })
                    }
                  >
                    <EditFilled />
                  </Button>,
                ]}
              >
                <List.Item.Meta title={item.name} />
              </List.Item>
            )}
          />
      <Drawer
        title={"Kategorije opreme"}
        width={540}
        destroyOnClose={true}
        
        children={
          <CategoryForm
          type="color"
            category={color}
            close={() => set_color(undefined)}
          />
        }
        visible={!!color}
        onClose={() => set_color(undefined)}
      />
    </>
  );
};

export default Colors;