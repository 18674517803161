import { Divider, message, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { ITestModelFormValues } from "../../../../models/all";
import { getTestingTestXHR } from "../../../../store/reducers/all/allActionCreator";
import Header from "./Components/Header";
import Obrasci from "./Components/Obrasci";
import StandardRequiremnts from "./Components/StandardRequiremnts";
import Tries from "./Components/Tries";

const Results: React.FC = () => {
  const { test_id } = useParams();
  const [activeModelForm, set_activeModelForm] = useState<ITestModelFormValues>();
  const { getTestingTestStatus, saveTriesStatus, testingTest } = useAppSelector(
    (state) => state.allReducer
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getTestingTestXHR(
      {
        id: test_id || 0,
        errorCallback: () => {
          message.error("Dogodila se greška...");
        },
      },
      dispatch
    );
  }, [test_id]);


 
  console.log(activeModelForm?.id)

  return (
    <div>
      <Spin
        spinning={
          getTestingTestStatus === "loading"
        }
      >
        <Header />
        <Divider />
        <StandardRequiremnts fields={testingTest?.standard_requirements_custom_fields || []} refValues={testingTest?.test_product.ref_values || []} />
        <Divider />
        <Obrasci setActiveModelForm={modelForm => {
          set_activeModelForm(modelForm);
        }}/>
       {activeModelForm && (testingTest && testingTest.model_forms.length > 0) ?  <Tries modelFormID={activeModelForm.id} /> :  <Typography.Title level={4}>Nema obrazaca za ovaj test</Typography.Title>}
      </Spin>
    </div>
  );
};

export default Results;
