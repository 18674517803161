import { Button, message, PageHeader, Space, Spin, Tabs, Typography } from "antd";
import { useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Routes } from '../../navigation/routes'
import { getProductXHR } from "../../store/reducers/all/allActionCreator";

const { TabPane } = Tabs;

const Product: React.FC = () => {

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const {getProductStatus, productDetails} = useAppSelector(state => state.allReducer)

  // Variables
  const { token } = useAppSelector(state => state.userReducer.user)
 
  // Methods
  const onTabChange = (path: string) => {
    navigate(`${path}`, { replace: false });
  };
  
  useEffect(() => {
    getProductXHR({id, errorCallback: () => message.error("Desila se greška na dohvata proizvoda.")}, dispatch)
  }, [])


  if(getProductStatus === "loading"){
    return <Spin size="large"  />
  }

  const pageHeaderExtra: any[] = []

  // const pageHeaderExtra = location.pathname.split("/")[3] === "testing" ? [
  //   <Button type="primary" onClick={() => {}}>
  //     Novo testiranje
  //   </Button>,
  // ] : [];

  return (
    <>
      <PageHeader
        style={{ padding: 0 }}
        title={<Typography.Title level={4} style={{ marginBottom: 0 }}>{productDetails?.name}</Typography.Title>}
        extra={pageHeaderExtra}
        footer={
          <Tabs
            id="bordered-tabs"
            style={{ marginBottom: 12 }}
            activeKey={location.pathname.split("/")[3]}
            onChange={onTabChange}
          >
            {Routes(!!token).children
              ?.find((x) => x.path === "products/:id")
              ?.children?.map((x) => (
                x.name !== null &&
                <TabPane
                  tab={
                    <Link to={x.path} style={{ fontSize: 14 }}>
                      { x.name }
                    </Link>
                  }
                  key={x.path}
                />
              ))}
          </Tabs>
        }
      />

      {/* Rendering routes */}
      <Outlet />
    </>
  );
};

export default Product;
