import { CopyOutlined, DeleteOutlined, DiffOutlined, EditOutlined, FileDoneOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Popconfirm, Space, Table, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ITableColumn } from "../../../models";
import {  ITest } from "../../../models/all";
import CopyTestForm from "../Components/CopyTestForm";
import ColorForm from "../Components/ColorForm";
import { logoutXHR } from "../../../store/reducers/user/actionCreators";
import api from "../../../services";
import { allSlice } from "../../../store/reducers/all";

interface IProps{
}

const TestList:React.FC<IProps> = ({}) => {

    // Variables
    const [search, set_search] = useState<string>("");
    const {productTestingDetails} = useAppSelector(state => state.allReducer)
    const [modalVisible, set_modalVisible] = useState<ITest>()
    const [colorModalVisible, setColorModalVisible] = useState<ITest | undefined>(undefined);
    const [delLoading, set_delLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const delTest = (id: number) => {
        if(!productTestingDetails){
            return;
        }
        set_delLoading(true);
        let token = localStorage.getItem("token");
        try {
            let response = api().delete(`/laboapp/product_testing_test/${id}/`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            let tmp = [...productTestingDetails?.tests];
            let index = tmp.findIndex((item) => item.id === id);
            tmp.splice(index, 1);
            dispatch(allSlice.actions.getProductTestingDetailsSuccess({
                results: {
                    ...productTestingDetails,
                    tests: tmp
                },
                message: ""
            }))
            set_delLoading(false);

        } catch (error: any) {
            if(error?.response?.status === 401){
                logoutXHR(null, dispatch);
                return;
            }
            set_delLoading(false);
            message.error("Greška prilikom brisanja testa");
        }
    }

    useEffect(()=> {
        set_COLUMNS([
            { title: "Test", dataIndex: "test", render(text, value) {
                return  <span>{ value.test.name }</span>
            }},
            { title: "Tip materijala", dataIndex: "material_type", render(text: string, value: ITest) {
                return  <span>{ value.material_type?.name || "-" }</span>
            }},
            { title: "Metoda ispitivanja", dataIndex: "r3", render(text: string, value: ITest) {
                return  <span>{ value.test.exam_method.name || "-" }</span>
            }},
            { title: "Komponenta", dataIndex: "color", render(text: string, value: ITest) {
                return <><span>{ value.color?.name || "-" }</span>
                <EditOutlined style={{ marginLeft: 8 }} onClick={() => {
                    setColorModalVisible(value)
                }} />
                </> 
            }},
            { title: "", dataIndex: "", render(text: string, value: ITest) {
                return  (
                    <Space>
                        <Button onClick={() => {
                            set_modalVisible(value)
                        }}   icon={<CopyOutlined style={{ fontSize: 20 }} />} type="link" />
                        <Link to={`results/${value.id}`}><FileDoneOutlined style={{ fontSize: 20 }} /></Link>
                        <Popconfirm  onConfirm={() => {
                            delTest(value.id)
                        }} title={"Nastaviti sa akcijom?"} >
                        <Button danger loading={delLoading}  icon={<DeleteOutlined style={{ fontSize: 20 }} />} type="link" />
                        </Popconfirm>
                    </Space>
                )
            } },
        ])
    }, [productTestingDetails])

    // Columns
    const [COLUMNS, set_COLUMNS] = useState<ITableColumn<ITest>[]>([
        { title: "Test", dataIndex: "test", render(text, value) {
            return  <span>{ value.test.name }</span>
        }},
        { title: "Tip materijala", dataIndex: "material_type", render(text: string, value: ITest) {
            return  <span>{ value.material_type?.name || "-" }</span>
        }},
        { title: "Metoda ispitivanja", dataIndex: "material_type", render(text: string, value: ITest) {
            return  <span>{ value.test.exam_method.name || "-" }</span>
        }},
        { title: "Komponenta", dataIndex: "color", render(text: string, value: ITest) {
            return <><span>{ value.color?.name || "-" }</span>
            <EditOutlined style={{ marginLeft: 8 }} onClick={() => {
                setColorModalVisible(value)
            }} />
            </> 
        }},
        { title: "", dataIndex: "", render(text: string, value: ITest) {
            return  (
                <Space>
                    <Button onClick={() => {
                        set_modalVisible(value)
                    }}   icon={<CopyOutlined style={{ fontSize: 20 }} />} type="link" />
                    <Link to={`results/${value.id}`}><FileDoneOutlined style={{ fontSize: 20 }} /></Link>
                    <Popconfirm  onConfirm={() => {
                        delTest(value.id)
                    }} title={"Nastaviti sa akcijom?"} >
                    <Button danger loading={delLoading}  icon={<DeleteOutlined style={{ fontSize: 20 }} />} type="link" />
                    </Popconfirm>
                </Space>
            )
        } },
    ]);




    // Data
    const filteredData: ITest[] = useMemo(() => {
        if(!productTestingDetails?.tests) return [];
        if(!search) return productTestingDetails?.tests;
        return productTestingDetails?.tests.filter(item => item.test.name.toLowerCase().includes(search))
    }, [productTestingDetails?.tests, search])

    return(
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%', marginBottom: 24, gap: 12 }}>
                <Tooltip title={"Pretraži po nazivu."}>
                    <InfoCircleOutlined />
                </Tooltip>
                <Input.Search
                    style={{ maxWidth: 320 }}
                    onChange={({target: {value}}) => {
                        if(!value){
                            set_search("");
                            return;
                        }
                        set_search(value.toLowerCase())
                    }}
                    allowClear
                />
            </div>
            <Table
                rowKey={(item) => item.id}
                dataSource={filteredData}
                columns={COLUMNS}
            />
            <Modal
                destroyOnClose
                footer={null}
                visible={!!modalVisible}
                onCancel={() => set_modalVisible(undefined)}
                title={"Kopiraj test"}
            >
                {modalVisible && <CopyTestForm test={modalVisible} close={() => {
                    set_modalVisible(undefined)
                }} />}
            </Modal>

            <Modal
                destroyOnClose
                footer={null}
                visible={!!colorModalVisible}
                onCancel={() => setColorModalVisible(undefined)}
                title="Odaberi komponentu za ovaj test"
            >
                <ColorForm 
                    test={colorModalVisible}
                    onClose={() => setColorModalVisible(undefined)} 
                />
            </Modal>
        </div>
    )
}

export default TestList;