import React from "react";

interface IProps {
    label: string,
    value: string | JSX.Element
}

const Info:React.FC<IProps> = ({label, value}) => {
    return <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center"}} >
        <div>{label}</div>
        <div style={{fontWeight: "bold"}} >{value}</div>
    </div>
}

export default Info;