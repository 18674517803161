import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import {
  getAccountsXHR,
  getEqipmentXHR,
  getEquipmentXHR,
  getProductTestingDetailsXHR,
  updateProductTestingXHR,
} from "../../store/reducers/all/allActionCreator";
import {
  Button,
  Descriptions,
  Divider,
  PageHeader,
  Space,
  Spin,
  Table,
  message,
  Typography,
  DatePicker,
  Select,
  Tooltip,
  Modal,
  Input,
  Checkbox,
  Popconfirm,
  Popover,
} from "antd";
import { useAppSelector } from "../../hooks";
import { IApiResponse, ITableColumn } from "../../models";
import { ITest } from "../../models/all";
import { FilePdfOutlined } from "@ant-design/icons";
import moment from "moment";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import api from "../../services";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import { allSlice } from "../../store/reducers/all";

type IProps = {};

type UpdateProductTestingDetails = {
  product: number;
  standard: number;
  sample_mark: string;
  work_order: string;
  testing_date: string;
  testing_type: string;
  is_success?: boolean;
  is_done: boolean;
  tested_by: number[];
  note: string;
}

const Testing = (props: IProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  // Variables
  const [deleteTestingLoading, setDeleteTestingLoading] = useState<boolean>(false)
  const [closeModalVisible, setcloseModalVisible] = useState<boolean>(false)
  const [isTestSuccessful, setIsTestSuccessful] = useState<boolean>(false)
  const [closeTestingNote, setCloseTestingNote] = useState<string>("")
  const { productTestings, productTestingDetails, getProductTestingDetailsStatus, updateProductTestingStatus, accounts } =
    useAppSelector((state) => state.allReducer);

  // Methods
  useEffect(() => {
    getEqipmentXHR(
      {
        errorCallback: () => message.error("Greška na dohvati podataka..."),
      },
      dispatch
    );
    getAccountsXHR(
      {
        errorCallback: () => message.error("Greška na dohvati podataka..."),
      },
      dispatch
    );
    getProductTestingDetailsXHR(
      {
        id,
        errorCallback: () =>
          message.error("Dogodila se greška na dohvatu podataka..."),
      },
      dispatch
    );
  }, []);

  async function deleteTesting(product_id: number){
    setDeleteTestingLoading(true);
    try {
      let token = await localStorage.getItem("token");
      let response = await api().delete<IApiResponse<string>>(
        `laboapp/product_testing/${id}/`,
        { headers: { Authorization: "Bearer " + token } }
      );
      let tmp = [...productTestings];
      let index = tmp.findIndex((x) => x.id === +id!);
      tmp.splice(index, 1);
      dispatch(allSlice.actions.getProductTestingsSuccess({ results: tmp, message: "" }));
      navigate(`/products/${product_id}/testing`)
    } 
    catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }
      message.error("Dogodila se greška kod brisanja...");
    }
    setDeleteTestingLoading(false);
  }

  const updateData = (data: Partial<UpdateProductTestingDetails>) => {
    updateProductTestingXHR({
      id: productTestingDetails?.id,
      body: data,
      errorCallback: () => {
        message.error("Dogodila se greška...");
      }
    }, dispatch)
  }

  return (
    <Spin 
      spinning={
        getProductTestingDetailsStatus === "loading" || 
        updateProductTestingStatus === "loading" || 
        deleteTestingLoading
      }
    >
      {/* Header */}
      <PageHeader
        className="page-header"
        style={{ padding: 0 }}
        title={`Testiranje #${productTestingDetails?.id} - ${productTestingDetails?.product.name}`}
        onBack={() =>
          navigate(`/products/${productTestingDetails?.product.id}/testing`)
        }
        extra={[
          <Link to={`../reportbro?product_testing=${productTestingDetails?.id}`} target="_blank" type="link"  >
            <FilePdfOutlined style={{fontSize: 27}} />
          </Link>,
            <Popconfirm
              key="2"
              onConfirm={() => deleteTesting(productTestingDetails?.product.id!)}
              title="Da li ste sigurni?"
              cancelText="Odustani"
            >
              <Button danger>
                Izbriši
              </Button>
            </Popconfirm>,
         <>
          {
            !productTestingDetails?.is_done && 
              <Button 
                onClick={() => { setcloseModalVisible(true) }}
                key="1" 
                type="primary"
              >
                Zaključi
              </Button>
            }
          </>,
        ]}
      >
        {/* Standard */}
        <Tooltip title={productTestingDetails?.standard.name}>
          <div className="ellipsis" style={{ marginTop: -6, marginBottom: 12, opacity: .5, maxWidth: 320 }}>
            { productTestingDetails?.standard.name }
          </div>
        </Tooltip>

        <Divider />

        {/* Descriptions */}
        <Descriptions 
        bordered
        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        size={"middle"} >
          {/* Radni nalog */}
          <Descriptions.Item label="Radni nalog">
            <Typography.Text
              editable={{
                onChange: (value) => {
                  updateData({ work_order: value })
                },
              }}
            >
            {productTestingDetails?.work_order || ""}

            </Typography.Text>
          </Descriptions.Item>
          {/* Oznaka uzorka */}
          <Descriptions.Item label="Oznaka uzorka">
           <Typography.Text
              editable={{
                onChange: (value) => {
                  updateData({ sample_mark: value })
                },
              }}
            >
            {productTestingDetails?.sample_mark || ""}
            </Typography.Text>
          </Descriptions.Item>
          {/* Datum ispitivanja */}
          <Descriptions.Item label="Datum ispitivanja">
            <DatePicker 
              format="DD.MM.YYYY"
              value={productTestingDetails?.testing_date ? moment(productTestingDetails.testing_date) as any: undefined} 
              onChange={(value) => {
                updateData({ testing_date: value?.format("YYYY-MM-DD") || "" })
              }}
            />
          </Descriptions.Item>
          {/* Tip ispitivanja */}
          <Descriptions.Item label="Tip ispitivanja">
            <Typography.Text
              editable={{
                onChange: (value) => {
                  updateData({ testing_type: value })
                },
              }}
            >
            {productTestingDetails?.testing_type || ""}
            </Typography.Text>
          </Descriptions.Item>
          {/* Testirano od */}
          <Descriptions.Item label="Testirano od">
            <Select
              mode="tags"
              maxTagCount={1}
              onChange={(v: any) => {
                updateData({ tested_by: v })
              }}
              value={productTestingDetails?.tested_by.map(x => x.id) || []}
                filterOption={(input, option) => { 
                  if (typeof option?.children === "string") {
                    return (option?.children as string)
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }
                  return false;
                }}
                showSearch
                style={{ minWidth: 180 }}
              >
                {accounts?.map((x) => {
                  return <Select.Option key={x.id} value={x.id}>{x.name}</Select.Option>;
                })}
            </Select>
          </Descriptions.Item>
          {/* Napomena */}
          { productTestingDetails?.note&& 
              <Descriptions.Item  label="Napomena">
                <Popover style={{
                  width: 222,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }} >
                  <Typography.Text>{productTestingDetails?.note}</Typography.Text>
                </Popover>
              </Descriptions.Item>
            
          }
        </Descriptions>
        <Divider />
      </PageHeader>

      {/* Modal */}
      <Modal
        title="Zaključi testiranje"
        visible={closeModalVisible}
        onOk={() => {
          updateData({ is_done: true, is_success: isTestSuccessful, note: closeTestingNote })
          setcloseModalVisible(false)
        }}
        onCancel={() => setcloseModalVisible(false)}
      >
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
          {/* Napomena */}
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text>Napomena</Typography.Text>
            <Input.TextArea 
              rows={4} 
              placeholder="Unesite napomenu" 
              value={closeTestingNote}
              onChange={(e) => setCloseTestingNote(e.target.value)}
            />
          </Space>

          {/* Proizvod je prošao testiranje */}
          <Space style={{ width: '100%' }}>
            <Typography.Text>Proizvod je prošao testiranje</Typography.Text>
            <Checkbox 
              checked={isTestSuccessful}
              onChange={(e: CheckboxChangeEvent) => setIsTestSuccessful(e.target.checked)}
            />
          </Space>
        </Space>
      </Modal>

      <Outlet />
    </Spin>
  );
};

export default Testing;
