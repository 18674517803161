import { Spin, Typography } from 'antd';
import React from 'react';
import { ICustomValue } from '../../../../../../models/all';
import InputResult from '../InputResult';
import { useAppSelector } from '../../../../../../hooks';

interface IProps{
    resultsOther: ICustomValue[],
    resultsPrepare: ICustomValue[]
}

const FinalResults: React.FC<IProps> = ({resultsOther, resultsPrepare}) => {

  const  {getTestingTestStatus} = useAppSelector(state => state.allReducer); 


  if(getTestingTestStatus === "loading") return <Spin size="small" />
    return(
        <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '100%', maxWidth: 200, marginBottom: 6, }}>
          <div>
            <Typography.Text strong>Konačni rezultat</Typography.Text>
          </div>
        </div>
        <div  style={{ maxWidth: 200, padding: 6, paddingLeft: 0, paddingTop: 42 }}>
          {getArrSorted([...resultsPrepare]).map((x, i) => {
            return (
              <InputResult 
                disabled={true} 
                key={x.key + "_rp" + i} 
                value={x} 
                field={x}
                inputStyle={{ width: 160, margin: "2px 0" }} 
              />
            );
          })}
        </div>
        <div style={{ maxWidth: 200, padding: 6, paddingLeft: 0, paddingTop: 81 }}>
          {getArrSorted([...resultsOther]).map((x, i) => {
            return (
              <InputResult 
                disabled={true} 
                key={x.key + "_ro" + i}  
                value={x} 
                field={x} 
                inputStyle={{ width: 160, margin: "2px 0" }} 
              />
            );
          })}
        </div>
      </div>
    )
}

export default FinalResults;

const getArrSorted = (data: ICustomValue[]) => {
    return [...data.sort((a, b) => (a?.friendly_id > b?.friendly_id) ? 1 : -1)];
}