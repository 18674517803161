import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, message, Select, Spin } from "antd";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { addEquipmentXHR, getEquipmentCategoryXHR, updateEquipmentXHR } from "../../store/reducers/all/allActionCreator";
import { ICustomValue, IFinishedCalibrations } from "../../models/all";
import moment, { Moment } from "moment";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import api from "../../services";
import { IApiResponse } from "../../models";

const { Option } = Select;

interface IProps {
  equipment: undefined | IEquipmentForm;
  set_calibrationHistory?: React.Dispatch<React.SetStateAction<IFinishedCalibrations[] | undefined>>,
  close: () => void;
}

export interface IEquipmentForm {
  id: number;
  name: string;
  category?: any;
  oem: string;
  type: string,
  serial_number: string;
  calibration_every?: any;
  calibration_unit?: any;
  next_calibration?: Moment | Date | null;
  calibration_required: boolean;
  certificate_number?: any;
  measuring_range?: any;
  measurable_insecurity?: any;
  calibration_body: string
}

const ProductForm: React.FC<IProps> = ({ equipment, set_calibrationHistory, close }) => {

  // Variables
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { addEquipmentStatus, updateEquipmentStatus, equipmentCategories } = useAppSelector((state) => state.allReducer);

  // Methods
  useEffect(() => {
    getEquipmentCategoryXHR({}, dispatch);
  }, []);


  const onFinish = (values: IEquipmentForm) => {

    let _values:IEquipmentForm = {
      id: values.id,
      name: values.name,
      oem: values.oem,
      type: values.type,
      category: values.category,
      serial_number: values.serial_number,
      calibration_every: values.calibration_every,
      calibration_unit: values.calibration_unit,
      next_calibration: values.next_calibration,
      calibration_required: values.calibration_required,
      certificate_number: values.certificate_number,
      measuring_range: values.measuring_range,
      measurable_insecurity: Number(values.measurable_insecurity),
      calibration_body: values.calibration_body
    }
    
    if (!!equipment?.id) {
      // update user
      updateEquipmentXHR(
        {
          id: equipment.id.toString(),
          body: _values,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => onUpdateCallback(equipment.id),
        },
        dispatch
      );

      return;
    }

    addEquipmentXHR(
      {
        body: _values,
        errorCallback: () =>
          message.error("Dogodila se greška na spremanje podataka"),
        successCallback: () => close(),
      },
      dispatch
    );
  };

  const onUpdateCallback = (id: number): void => {
    getCalibrationHistory(id);
    close();
  }

  const getCalibrationHistory = async (id: number) => {

    try {
      let token = await localStorage.getItem("token");
      let response = await api().get<IApiResponse<IFinishedCalibrations[]>>(
        `equipment/equipment/${id}/calibration_history/`,
        { headers: { Authorization: "Bearer " + token } }
      );
      if(set_calibrationHistory){
        set_calibrationHistory(response.data.results)
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }
      message.error("Dogodila se greška kod dohvata podataka...");
    }
  };

  return (
    <Form
      form={form}
      initialValues={equipment}
      layout="vertical"
      onFinish={onFinish}
    >
      {/* Name */}
      <Form.Item
        name={"name"}
        label={"Naziv"}
        rules={[{ required: true, message: "Ovo polje je obavezno" }]}
      >
        <Input />
      </Form.Item>

      {/* Category */}
      <Form.Item
          rules={[{ required: true, message: "Ovo polje je obavezno" }]} 
          name={"category"} 
          label="Kategorija"
        >
          <Select>
            {
              equipmentCategories.map((item, index) => (
                <Option key={index} value={item.id}>{ item.name }</Option>
              ))
            }
          </Select>
        </Form.Item>

      {/* Calibration time */}
      <Form.Item 
        name={"next_calibration"} 
        label="Vrijeme sljedeće kalibracije"
      >
       <DatePicker style={{ width: '100%' }} />
      </Form.Item>

      {/* Calibration every - Calibration unit */}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Form.Item
          name={"calibration_every"} 
          label="Ponavljanje kalibracije"
          style={{ width: 'calc(100% - 120px)' }}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name={"calibration_unit"} 
          label=" "
          style={{ width: 120 }}
        >
          <Select style={{ width: 120 }}>
            <Option value="day">Dan</Option>
            <Option value="week">Tjedan</Option>
            <Option value="month">Mjesec</Option>
            <Option value="year">Godina</Option>
          </Select>
        </Form.Item>
      </div>

      {/* OEM */}
      <Form.Item
        name={"oem"}
        label={"Proizvođač"}
      >
        <Input />
      </Form.Item>

      {/* Type */}
      <Form.Item
        name={"type"}
        label={"Tip"}
      >
        <Input />
      </Form.Item>

      {/* Serial number */}
      <Form.Item
        name={"serial_number"}
        label={"Serijski broj"}
      >
        <Input />
      </Form.Item>

      {/* Certificate number */}
      <Form.Item
        name={"certificate_number"}
        label="Broj certifikata"
      >
        <Input />
      </Form.Item>

      {/* Measuring Range */}
      <Form.Item
        name={"measuring_range"}
        label={"Mjerni opseg"}
      >
        <Input />
      </Form.Item>

      {/* Measuring Insecurity */}
      <Form.Item
        name={"measurable_insecurity"}
        label={"Mjerna nesigurnost"}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"calibration_body"}
        label={"Kalibracijsko tijelo"}
      >
        <Input />
      </Form.Item>
      
      

      {/* Submit Button */}
      <Form.Item>
        <Button
          loading={
            addEquipmentStatus === "loading" || updateEquipmentStatus === "loading"
          }
          htmlType="submit"
          type="primary"
        >
          {"Spremi"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProductForm;
