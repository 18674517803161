import React from "react";

import { Popconfirm, List, Alert, Spin, Button } from "antd";

import { useDispatch, useSelector } from "react-redux";
import css from "../../parts.module.scss";
import { IMaterialType } from "../../../../models/all";
import { IMaterialTypeForm } from "./MaterialTypeForm";
import { NewTreeItem } from "../../../../models";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";

interface IProps {
  item: NewTreeItem<IMaterialType>;
  deep: number;
  set_update: (item: IMaterialTypeForm) => void;
  onDelete: (id: number) => void;
  deleteLoading?: number
}

const TypeListItem: React.FC<IProps> = ({
  set_update,
  deep,
  item,
  onDelete,
  deleteLoading
}) => {
  const childs = item.children && item.children.length && (
    <List
      style={{ paddingTop: "0px", marginLeft: deep * 12 }}
      itemLayout="horizontal"
      dataSource={item.children}
      renderItem={(item) => (
        <TypeListItem
          deep={deep + 1}
          set_update={set_update}
          onDelete={onDelete}
          item={item}
        />
      )}
    />
  );

  return (
    <>
      <List.Item
        actions={[
          <Popconfirm
            onConfirm={() => onDelete(item.id)}
            title="Nastaviti sa akcijom..."
            cancelText="Odustani"
          >
            <Button loading={deleteLoading === item.id} danger type="dashed">
              <DeleteOutlined />
            </Button>
          </Popconfirm>,
          <Button
            onClick={() =>
              {
                  console.log(item, "KKKlOv")
                  set_update({ id: item.id, name: item.name, parent_id: item.parent_id || 0 })}
            }
          >
            <EditFilled />
          </Button>,
        ]}
      >
        <List.Item.Meta title={item.name} />
      </List.Item>
      {childs !== 0 ? childs : null}
    </>
  );
};

export default TypeListItem;
 