import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Input, message, Popconfirm, Row, Spin, Table, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks";
import { IApiResponse, ITableColumn } from "../../models";
import { IAccount } from "../../models/all";
import api from "../../services";
import { allSlice } from "../../store/reducers/all";
import { getAccountsXHR } from "../../store/reducers/all/allActionCreator";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import AccountForm, { IAccountForm } from './AccountForm'

const Accounts: React.FC = () => {

  // Hooks
  const dispatch = useDispatch()

  // Variables
  const { accounts, getAccountsStatus } = useAppSelector(state => state.allReducer)
  const [account, set_account] = useState<IAccountForm>();
  const [loading, set_loading] = useState<boolean>(false);
  const [search, set_search] = useState<string>("");
  
  // Table
  const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IAccount>[]>([
    { title: "Naziv", dataIndex: "name" },
    { title: "Email", dataIndex: "email" },
    {
      title: "Akcije",
      dataIndex: "",
      render: (text: string, value: IAccount) => (
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          {/* Delete Button */}
          <Popconfirm
            onConfirm={() => deleteAccount(value.id)}
            title={"Nastaviti sa akcijom"}
            cancelText="Odustani"
          >
            <Button danger type="dashed">
              <DeleteOutlined />
            </Button>
          </Popconfirm>

          {/* Edit Button */}
          <Button
            style={{ marginLeft: 15 }}
            onClick={() => {
              set_account({
                id: value.id,
                name: value.name,
                email: value.email,
              });
            }}
          >
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ]);
  
  // Methods
  useEffect(() => {
    getAccountsXHR({}, dispatch)
  }, [])

  const deleteAccount = async (id: number) => {
    set_loading(true);

    console.log(id, accounts);

    try {
      let token = await localStorage.getItem("token");
      let response = await api().delete<IApiResponse<string>>(
        `accounts/accounts/${id}/`,
        { headers: { Authorization: "Bearer " + token } }
      );

      // let tmp = [...accounts];
      // let index = tmp.findIndex((x) => x.id === id);
      // tmp.splice(index, 1);

      // console.log(tmp);
      // dispatch(allSlice.actions.getAccountsSuccess({ results: tmp, message: "" }));
      getAccountsXHR({}, dispatch)
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }

      message.error("Dogodila se greška kod brisanja...");
    }

    set_loading(false);
  }

  let filtredData = [];

  if (search && accounts) {
    filtredData = [...accounts.filter((x) => x.name.toLowerCase().includes(search))];
  } else {
    filtredData = [...accounts];
  }

  

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "18px 0" }}>
        {/* Page subtitle */}
        <Typography.Title level={5}>Pregled korisnika</Typography.Title>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {/* Tooltip */}
            <Tooltip title={"Pretraži po nazivu."}>
              <InfoCircleOutlined />
            </Tooltip>

            {/* Spacing */}
            <div style={{ width: 12 }} />

            {/* Input */}
            <Input.Search
              onChange={({target: {value}}) => {
                if(!value){
                  set_search("");
                  return;
                }
                set_search(value.toLowerCase())
              }}
              style={{ marginRight: 18 }}
              allowClear
            />
          </div>

          {/* Add button */}
          <Button
            onClick={() =>
              set_account({
                id: 0,
                name: "",
                email: "",
              })
            }
            type="primary"
          >
            Dodaj
          </Button>
        </div>
      </div>


      {/* Table */}
      <Row>
        <Col span={24}>
          <Spin spinning={getAccountsStatus === 'loading' || loading}>
            <Table
              rowKey={(item) => item.id}
              dataSource={filtredData}
              columns={COLUMNS}
            />
          </Spin>
        </Col>
      </Row>


      {/* Drawer */}
      <Drawer
        title={"Dodaj korisnika"}
        width={540}
        destroyOnClose={true}
        children={
          <AccountForm account={account} close={() => set_account(undefined)} />
        }
        visible={!!account}
        onClose={() => set_account(undefined)}
      />

    </div>
  );
};

export default Accounts;
