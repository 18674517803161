import { Col, Row } from "antd";
import React from "react";
import { useAppSelector } from "../../../hooks";
import Info from "../../components/Info";

const Spec:React.FC = () => {

    const {productDetails} = useAppSelector(state => state.allReducer);


    return <div>
        <Row>
            <Col span={8}> <Info label="Kategorija proizvoda" value={productDetails?.category.name || "-"} /> </Col>
        </Row>
        
    </div>
}

export default Spec;