import moment from "moment";
import { Link } from "react-router-dom";
import { Col, message, Row, Spin, Table, Popconfirm, Typography } from 'antd'
import { useEffect, useState } from "react";
import { ITableColumn } from "../../models";
import { IEquipment } from "../../models/all";
import { finishCalibrationXHR, getEqipmentXHR } from "../../store/reducers/all/allActionCreator";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../hooks";

const Dashboard: React.FC = () => {

  // Hooks
  const dispatch = useDispatch()

  // Variables
  const { getEqipmentStatus, equipments, finishCalibrationStatus } = useAppSelector(state => state.allReducer);

  // Table
  const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IEquipment>[]>([
    {
      title: "Naziv",
      dataIndex: "name",
      render: (text: string, value: IEquipment) => (
        <Link to={`/equipment/${value.id}`}>{value.name}</Link>
      ),
    },
    { title: "Proizvođač", dataIndex: "oem" },
    { title: "Serijski broj", dataIndex: "serial_number" },
    {
      title: "Kategorija",
      dataIndex: "category",
      render: (text: string, value: IEquipment) => {
        return value.category?.name
      }
    },
    { 
      title: "Vrijeme sljedeće kalibracije", 
      dataIndex: "next_calibration",
      render: (text: string, value: IEquipment) => {
        return moment(value.next_calibration).isValid() ? 
          moment(value.next_calibration).format('LLLL') : 
          "-"
      }
    },
    {
      title: "Potvrdi kalibraciju",
      dataIndex: "action",
      render: (text: string, value: IEquipment) => {
        if(value.calibration_required){
          return (
            <Popconfirm
              onConfirm={() => finishCalibration(value.id)}
              cancelText="Odustani"
              title="Da li stvarno želite potvrditi izvršenje kalibracije!"
            >
              <a>Potvrdi</a>
            </Popconfirm>
          )
        }
      }
    },
  ]);

  // Methods
  useEffect(() => {
    getEquipment();
  },[])


  const getEquipment = () => {
    getEqipmentXHR({ errorCallback: (data: any) => message.error("Greška na dohvati podataka...")},
      dispatch
    );
  };

  const finishCalibration = (id:number) => {
    finishCalibrationXHR(
      {
        body: { equipment_id: Number(id) },
        errorCallback: (error: any) => message.error("Error"),
        successCallback: () => getEquipment()
      },
      dispatch
    );
  }


  const data = equipments.filter(item => item.calibration_required)

  return (
    <div style={{ padding: 12 }}>
      <Row>
        <Col span={24}>
          <Typography.Title 
            level={5}
            style={{ paddingBottom: 12 }}
          >
            Aktualne kalibracije {`( ${data.length} )`}
          </Typography.Title>
          <Spin spinning={getEqipmentStatus === "loading" || finishCalibrationStatus === "loading"}>
            <Table
              rowKey={(item) => item.id}
              dataSource={data}
              columns={COLUMNS}
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
  
  export default Dashboard;
  