import { Button, Col, Modal, Popconfirm, Row, Space, Spin, Table, Tooltip, message } from "antd";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import AddRefValues from "../../components/AddRefValues";
import CopyTests from "../../components/CopyTests";
import { ICategory, IStandard, ITestProduct } from "../../../../models/all";
import { IApiResponse, ITableColumn } from "../../../../models";
import { logoutXHR } from "../../../../store/reducers/user/actionCreators";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { DeleteOutlined, FileDoneOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import api from "../../../../services";
import { allSlice } from "../../../../store/reducers/all";
import DraggableBodyRow from "./DraggableRow";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { updateProductTestXHR, updateProductTestingXHR } from "../../../../store/reducers/all/allActionCreator";

export interface IAddTest {
  product: number;
  standard: number;
  tests: { test: number; material_type: number }[];
  ref_values: any;
}

interface IProps {
  standard: ICategory;
  tests: ITestProduct[];
}

export interface ChildRef {
  openModal: () => void;
}

const TestTable: React.ForwardRefRenderFunction<ChildRef, IProps> = ({ standard, tests }, ref) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  // Variables
  const [loading, set_loading] = useState<boolean>(false);
  const [addRefValuesModalVisible, set_addRefValuesModalVisible] = useState<ITestProduct | undefined>(undefined);
  const [copyTestsModalVisible, set_copyTestsModalVisible] = useState<boolean>(false);
  const [selectedStandard, set_selectedStandard] = useState<IStandard>(standard);
  const [selectedRows, set_selectedRows] = useState<number[]>([]);
  const { getTestClustersStatus, testProducts } = useAppSelector((state) => state.allReducer);
  const [data, setData] = useState(tests);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ITestProduct[]) => {
      set_selectedRows(selectedRows.map((item) => item.id));
    },
    getCheckboxProps: (record: ITestProduct) => ({
      name: record.test.name,
    }),
  };

  const _columns: ITableColumn<ITestProduct>[] = [
    {
      title: "Testovi",
      dataIndex: "",
      render: (text: string, value: ITestProduct) => <div>{value.test.name}</div>,
    },
    {
      title: "Metoda ispitivanja",
      dataIndex: "",
      render: (text: string, value: ITestProduct) => <div>{value.test.exam_method.name}</div>,
    },
    {
      title: "Tip materijala",
      dataIndex: "",
      render: (text: string, value: ITestProduct) => <div>{value.material_type?.name}</div>,
    },
    {
      title: () => (
        <div>
          <Button
            type="link"
            disabled={selectedRows.length === 0}
            style={{ float: "right" }}
            onClick={() => set_copyTestsModalVisible(true)}
          >
            Kopiraj testove
          </Button>
        </div>
      ),
      dataIndex: "",
      render: (text: string, value: ITestProduct) => (
        <Space size="large">
          <Tooltip title="Dodavanje referentnih vrijednosti">
            <Button
              onClick={() => set_addRefValuesModalVisible(value)}
              disabled={value.test.standard_requirements_custom_fields?.length === 0}
              type="link"
            >
              <FileDoneOutlined style={{ fontSize: 20, paddingTop: 4 }} />
            </Button>
          </Tooltip>
          <Popconfirm
            title="Jeste li sigurni da želite izbrisati ovaj test?"
            onConfirm={() => handleDeleteTestProduct(value.id)}
            okText="Izbriši"
            cancelText="Odustani"
          >
            <Button type="link">
              <DeleteOutlined style={{ fontSize: 20, paddingTop: 4 }} />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]



  const [COLUMNS, set_Columns]  = useState(_columns);

  useEffect(() => {
    set_Columns(_columns);
    setData(tests);
  }, [tests]);


  async function handleDeleteTestProduct(id: number) {
    set_loading(true);
    const token = await localStorage.getItem("token");
    try {
      const response = await api().delete<IApiResponse<string>>(`/laboapp/test_product/${id}/`, {
        headers: { Authorization: "Bearer " + token },
      });

      let arr: ITestProduct[] = [...testProducts];
      let index = arr.findIndex((item) => item.id === id);
      arr.splice(index, 1);
      const fakeRes: IApiResponse<ITestProduct[]> = {
        results: [...arr],
        message: "",
      };

      dispatch(allSlice.actions.getTestProductsSuccess(fakeRes));
      message.success("Uspješno ste izbrisali test!");
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }
      message.error("Dogodila se greška na dohvatu podataka...");
    }
    set_loading(false);
  }

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newData = [...data];
      const [draggedRow] = newData.splice(dragIndex, 1);
      let rank = hoverIndex;
      newData.splice(hoverIndex, 0, draggedRow);
      let newUpdatedRankData = newData.map((item, index) => {
        return { ...item, rank: standard.id + "_" + index };
      });
      updateProductTestXHR({
          id: data[dragIndex].id,
          body: { 
            rank,
            ref_values: data[dragIndex].ref_values,
       },
        errorCallback: () => {
          message.error("Dogodila se greška na spremanju podataka...");
        },
        successCallback: (res) => {
          console.log(sortByRank(res.results).map(x => x.rank + "." + x.id), "res.results.map(x => x.rank)")
        }
      }, dispatch)
      setData(newUpdatedRankData);
    },
    [data]
  );


  return (
    <div id="table-drag-sorting">
      <Row>
        <Col span={24}>
          <Spin spinning={getTestClustersStatus === "loading"}>
            <DndProvider backend={HTML5Backend}>
              <Table
                dataSource={sortByRank(data)}
                columns={COLUMNS}
                rowKey={(item) => `ID-${item.id}`} // Multiple tests with same ID can exists - this creates unique key
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                components={components}
                onRow={(_, index) => {
                  const attr = {
                    index,
                    moveRow,
                  };
                  return attr as React.HTMLAttributes<any>;
                }}
              />
            </DndProvider>
          </Spin>
        </Col>
      </Row>

      {/* Add ref values modal */}
      <Modal
        footer={null}
        title={`Dodaj referentne vrijednosti: (${addRefValuesModalVisible?.test.name})`}
        open={!!addRefValuesModalVisible}
        onCancel={() => set_addRefValuesModalVisible(undefined)}
        width={640}
        destroyOnClose
      >
        {
          addRefValuesModalVisible && <AddRefValues
            id={addRefValuesModalVisible.id}
            data={addRefValuesModalVisible.test.standard_requirements_custom_fields || []}
            defaultValues={addRefValuesModalVisible}
            closeModal={() => set_addRefValuesModalVisible(undefined)}
          />
        }
      </Modal>

      {/* Copy tests modal */}
      <Modal
        footer={null}
        title="Kopiraj testove"
        open={copyTestsModalVisible}
        onCancel={() => set_copyTestsModalVisible(false)}
        width={640}
        destroyOnClose
      >
        {selectedStandard && (
          <CopyTests
            product={Number(id)}
            standard={selectedStandard?.id}
            set_selectedStandard={set_selectedStandard}
            tests={selectedRows}
            closeModal={() => {
              set_copyTestsModalVisible(false);
              set_selectedRows([]);
            }}
          />
        )}
      </Modal>
    </div>
  );
};

export default forwardRef(TestTable);


export const sortByRank = (data: ITestProduct[]) => {  
  return data.sort((a, b) => Number(a.rank.split("_")[1]) - Number(b.rank.split("_")[1]))
}
