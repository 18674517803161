import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks';
import { Button, Select, Spin, message } from 'antd';
import { createProductTestingXHR, getStandardsXHR } from '../../../store/reducers/all/allActionCreator';
import { useDispatch } from 'react-redux';
import { IStandard } from '../../../models/all';
import { useNavigate } from 'react-router-dom';

type IProps = {
    productId: number
    onClose: () => void;
}

export interface ICreateProductTesting{
    standard: number;
    product: number;
}

const CreateProductTesting = ({ productId, onClose }: IProps) => {

    // Hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Variables
    const [selectedStandard, setSelectedStandard] = useState<IStandard | undefined>(undefined);
    const { standards, getStandardsStatus, createProductTestingStatus } = useAppSelector(state => state.allReducer);

    // Methods
    useEffect(() => {
        getStandardsXHR({
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka..."),
        }, dispatch);
    }, []);

    function handleSubmit(){
        let body: ICreateProductTesting = {
            standard: selectedStandard!.id,
            product: productId,
        }
        createProductTestingXHR({
            body,
            errorCallback: () => message.error("Dogodila se greška..."),
            successCallback: (data) => {message.success("Uspješno kreirano testiranje!") 
                navigate(`/testings/${data.results.id}`)
            },
        }, dispatch)
    }

    return (
        <Spin spinning={getStandardsStatus === "loading" || createProductTestingStatus === "loading"}>
            <div style={{ paddingBottom: 48 }}>
                {/* Dropdown */}
                <Select 
                    value={selectedStandard?.name} 
                    placeholder="Odaberite standard" 
                    style={{ width: "100%" }} 
                    onChange={(value) => {
                        const standard = standards.find(item => item.id === +value);
                        setSelectedStandard(standard);
                    }}
                    showSearch
                    filterOption={(input, option) =>
                        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {standards.map((item, index) => 
                        <Select.Option key={index} value={item.id}>
                            { item.name }
                        </Select.Option>
                    )}
                </Select>

                {/* Button */}
                <Button 
                    onClick={handleSubmit}
                    disabled={!selectedStandard}
                    type="primary"
                    style={{ marginTop: 20, float: 'right' }}
                >
                    Započni
                </Button>
            </div>
        </Spin>
    )
}

export default CreateProductTesting