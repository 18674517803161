import { CopyOutlined, DeleteOutlined, EditOutlined, HistoryOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Drawer, message, Modal, Popconfirm, Tabs, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { TypesOfAction } from ".";
import { useAppDispatch } from "../../hooks";
import { IApiResponse } from "../../models";
import { ITestClusterDetails, ITestModelForm } from "../../models/all";
import api from "../../services";
import { allSlice } from "../../store/reducers/all";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import ModelItem from "./ModelItem";
import TestModelForm from "./TestModelForm";
import TestModelFormHistory from "./TestModelFormHistory";

interface IProps{
    testClusterDetails: ITestClusterDetails
}

const ModelForms:React.FC<IProps> = ({testClusterDetails}) => {


    const [indexToCopy, set_indexToCopy] = useState<number>(0);
    const [loading, set_loading] = useState<boolean>(false);
    const [testModelFormVisible, set_testModelFormVisible] = useState<ITestModelForm>();
    const [addTestModelFormVisible, set_addTestModelFormVisible] =
    useState<{ visible: boolean; typeOfAction?: TypesOfAction }>();
    const dispatch = useAppDispatch();

    const deleteTestModel = async (id: number) => {
        set_loading(true);
    
        try {
          let token = await localStorage.getItem("token");
          let response = await api().delete<IApiResponse<string>>(
            `laboapp/model_form/${id}/`,
            { headers: { Authorization: "Bearer " + token } }
          );
    
          let tmp: ITestClusterDetails = { ...testClusterDetails! };
          let arr = [...(tmp.model_forms || [])];
          let index = arr.findIndex((x) => x.id === id);
          arr.splice(index, 1);
          tmp.model_forms = arr;
          dispatch(
            allSlice.actions.getTestClusterDetailsSuccess({
              results: tmp,
              message: "",
            })
          );
        } catch (error: any) {
          if (error?.response?.status === 401) {
            logoutXHR(null, dispatch);
            return;
          }
          message.error("Dogodila se greška kod brisanja...");
        }
        set_loading(false);
      };


  const closeTestModelForm = () => {
    unstable_batchedUpdates(() => {
      set_addTestModelFormVisible({
        visible: false,
        typeOfAction: undefined,
      });
      dispatch(allSlice.actions.setIndexOfItemForTestModelForm(-1));
    }, []);
  };

  const modalTitles = {
    adding: "Dodavanje obrasca",
    copying: "Kopiranje obrasca",
    updateing: "Uređivanje obrasca",
    deleteing: "Brisanje obrasca",
  };

  let modalTitleKey =
    addTestModelFormVisible?.typeOfAction as keyof typeof modalTitles;

    


    let tmpFSortedForms = testClusterDetails ? [...testClusterDetails.model_forms].sort((a, b) => b.id - a.id) : [];
    return <> <Tabs
    activeKey={indexToCopy.toString()}
    onTabClick={(item) => {
      set_indexToCopy(+item)}}
    tabBarExtraContent={{
      right: (
        <Button
          onClick={() =>
            set_addTestModelFormVisible({
              visible: true,
              typeOfAction: "adding",
            })
          }
          style={{ marginBottom: 12 }}
        >
          Dodaj obrazac <PlusCircleOutlined />
        </Button>
      ),
    }}
  >
    {tmpFSortedForms.map((x, i) => (
      <Tabs.TabPane tab={x.name} key={i}>
        <div className="spaceBetweenRow">
          <div  className="" style={{ paddingTop: 12, paddingBottom: 0 }}>
            <Typography.Text style={{ opacity: 0.8 }}>
              Oznaka obrasca
            </Typography.Text>
            <Typography.Title level={4} style={{ marginTop: 2 }}>
              {x.label}
            </Typography.Title>
            <Typography.Text style={{ opacity: 0.8 }}>
              Verzija obrasca
            </Typography.Text >
            <Typography.Text style={{ marginTop: 2 }}>
              {x.version}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ marginTop: 2 }}>
              {moment(x.datetime).format("LLL")}
            </Typography.Text>
          </div>
          <div>
            <Button
              onClick={() => {
                set_addTestModelFormVisible({
                  visible: true,
                  typeOfAction: "copying",
                });
              }}
            >
              <CopyOutlined />
            </Button>
            <Button
              style={{ marginLeft: 12 }}
              onClick={() => {
                set_addTestModelFormVisible({
                  visible: true,
                  typeOfAction: "updateing",
                });
              }}
            >
              <EditOutlined />
            </Button>
            <Button
              onClick={() => {
                set_testModelFormVisible(x);
              }}
                style={{ marginLeft: 12 }}
            >
              <HistoryOutlined />
            </Button>
            {!!testClusterDetails?.model_forms.length && (
              <Popconfirm
                onConfirm={() => deleteTestModel(x.id)}
                title="Nastaviti sa akcijom"
              >
                <Button
                  type="dashed"
                  danger
                  style={{ marginLeft: 12 }}
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </div>
        </div>
        <Divider />
        <ModelItem model={x} />
      </Tabs.TabPane>
    ))}
  </Tabs>
  <Drawer
  width={540}
    footer={null}
    visible={!!testModelFormVisible}
    title="Povijest obrasca"
    destroyOnClose={true}
    onClose={() => set_testModelFormVisible(undefined)}
  >
      {!!testModelFormVisible && <TestModelFormHistory model_form={testModelFormVisible} />}
  </Drawer>
  <Modal
    destroyOnClose={true}
    footer={null}
    title={modalTitles[modalTitleKey]}
    visible={!!addTestModelFormVisible?.visible}
    onCancel={closeTestModelForm}
    children={
      <TestModelForm
        set_indexToCopy={set_indexToCopy}
        typeOfAction={addTestModelFormVisible?.typeOfAction}
        close={closeTestModelForm}
        model={
          addTestModelFormVisible?.typeOfAction &&
          addTestModelFormVisible?.typeOfAction !== "adding"
            ? testClusterDetails?.model_forms[indexToCopy]
            : undefined
        }
      />
    }
  />
  </>
}


export default ModelForms;