import {
  Button,
  Divider,
  Input,
  message,
  Select,
  Space,
  TreeSelect,
  Typography,
} from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useState } from "react";
import { createTreeSelect } from "../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ITestCluster } from "../../../models/all";
import { getMaterialTypesXHR } from "../../../store/reducers/all/allActionCreator";

type Props = {
  tests: ITestCluster[];
  onAddTest: (
    list: { test: number; material_type: number }[],
    standard: number
  ) => void;
};

const AddTests = ({ tests, onAddTest }: Props) => {
  const dispatch = useAppDispatch();
  // Variables
  const [checkedList, setCheckedList] = useState<{
    material_type: number | undefined;
    test: number;
  }[]>([]);
   
  // const allOptionsChecked = tests.map((item) => item.id);
  const [search, set_search] = useState<string>("");
  const [standard, set_standard] = useState<number>(-1);
  let filteredData: ITestCluster[] = tests;
  const { materialTypes, standards } = useAppSelector(
    (state) => state.allReducer
  );

  useEffect(() => {
    getMaterialTypesXHR(
      {
        errorCallback: () =>
          message.error("Greška prilikom dohvata tipova materijala"),
      },
      dispatch
    );
  }, []);

  // Methods
  const onChange = (test_id: number) => {
    let arr = [...checkedList];
    let index = arr.findIndex((item) => item.test === test_id);
    if (index === -1) arr.push({
      material_type: undefined,
      test: test_id,
    });
    else {
      arr.splice(index, 1);
    }
    setCheckedList(arr);
  };

  const checkAll = (e: CheckboxChangeEvent) => {
    if(!e.target.checked){
      setCheckedList([]);
      return;
    }
    let arr = tests.map(x => ({
      material_type: checkedList.find(y => y.test === x.id)?.material_type,
      test: x.id
    }))
    setCheckedList(arr);
  };

  // Filtering data with search
  if (search)
    filteredData = tests.filter((item) =>
      item.name.toLowerCase().includes(search)
    );

  return (
    <div style={{ paddingBottom: 48 }}>
      <div className="spaceBetweenRow">
        {/* Title */}
        <div>Odabrano testova ({checkedList.length})</div>
        {/* Check all */}
        <Checkbox
          checked={tests.length === checkedList.length}
          indeterminate={
            checkedList.length !== 0 && checkedList.length !== tests.length
          }
          onChange={checkAll}
        >
          Odaberi sve
        </Checkbox>
      </div>

      <Divider />

      <Input.Search
        placeholder="Pretražite testove"
        allowClear
        enterButton
        style={{ marginBottom: 12 }}
        onChange={(e) => set_search(e.target.value.toLowerCase())}
      />

      <div style={{paddingLeft: 24}} >
        <Typography.Text>Standard</Typography.Text><br/>
        <Select
          style={{ minWidth: 180 }}
          onSelect={(id: number) => set_standard(id)}
        >
          {standards.map((standard, index) => (
            <Select.Option key={index} value={standard.id}>
              {standard.name}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* Options */}
      <div style={{ marginTop: 24, maxHeight: "50vh", overflowY: "auto" }}>
        {filteredData.map((item, index) => (
          <Space style={{ marginBottom: 24, width: "100%" }} key={index}>
            <Checkbox
              style={{ width: "100%", padding: "10px 0", marginLeft: 0 }}
              checked={checkedList.filter(z => z.test === item.id).length > 0}
              value={item.id}
              onChange={() => onChange(item.id)}
              key={index}
            />

            <div style={{ textAlign: "start" }}>
              <Typography.Text>{item.name}</Typography.Text>
              <div />
              <div>
                <TreeSelect
                  onChange={(v: number | undefined) => {
                    if (!v) {
                      return;
                    }
                    let arr = [...checkedList];
                    let index = arr.findIndex((x) => x.test === item.id);
                    if (index === -1) {
                      return message.warn("Molimo odaberite test");
                    }
                    arr[index] = {
                      ...arr[index],
                      material_type: Number(v),
                    };
                    setCheckedList(arr);

                }}
                disabled={checkedList.filter(x => x.test === item.id).length === 0}
                  style={{ width: 180 }}
                  multiple={false}
                  treeDefaultExpandAll={true}
                  value={checkedList.find((z) => z.test === item.id)?.material_type}
                  allowClear
                  treeData={createTreeSelect(
                    materialTypes.map((x) => ({
                      title: x.name,
                      value: x.id.toString(),
                      parent_id: x.parent_id?.toString() || null,
                    })),
                    null
                  )}
                />
              </div>
              <div>
                <Typography.Text type="secondary">
                  {item.exam_method.name}
                </Typography.Text>
              </div>
            </div>
          </Space>
        ))}
      </div>

      {/* CTA */}
      <div style={{ float: "right", paddingTop: 20 }}>
        <Button
          onClick={() =>
           {
          

          if(checkedList.filter(x => !x.material_type).length > 0){
            return message.warn("Molimo odaberite tip materijala za sve testove");
          }
            onAddTest(checkedList.map(x => ({
              ...x,
              material_type: x.material_type ||-1
            })), standard)
          }
          }
          type="primary"
          disabled={
            checkedList.length === 0 || standard === -1
          }
        >
          Dodaj
        </Button>
      </div>
    </div>
  );
};

export default AddTests;
