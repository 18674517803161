import {
  MinusCircleFilled,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message, Select, Space, Spin } from "antd";
import React, { HtmlHTMLAttributes, Ref, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ITest } from "../../../models/all";
import {  getColorsXHR, productTestCopyXHR } from "../../../store/reducers/all/allActionCreator";


interface IProps {
  close: () => void;
  test: ITest;
}

const CopyTestForm: React.FC<IProps> = ({ close, test }) => {
  const addFieldRef = React.useRef<HTMLElement>(null);
  const dispatch = useAppDispatch();
  const {colors, getColorsStatus} = useAppSelector((state) => state.allReducer);

  useEffect(() => {
    getColorsXHR({
      errorCallback: () => message.error("Dogodila se greška pri dohvaćanju boja..."),
    }, dispatch)
    if (addFieldRef.current) {
      addFieldRef.current.click();
    }
  }, []);

  const onFinish = (values: { colors: Record<string, number>[], copyResult: boolean }) => {
    productTestCopyXHR({
      body: {
        test: test.id,
        copy_results: !!values.copyResult,
        colors: values.colors.map((color) => color.color),
      },
      successCallback: () => close(),
      errorCallback: () => message.error("Dogodila se greška pri kopiranju testova..."),
    }, dispatch)
    console.log(values.colors);
  };

  return (
    <div>
      <Spin spinning={getColorsStatus === "loading"} />
      <Form
        layout="vertical"
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.List name="colors">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    label="Boja"
                    {...restField}
                    name={[name, "color"]}
                    rules={[
                      { required: true, message: "Ovo polje je obavezno" },
                    ]}
                  >
                    <Select style={{ width: 210 }}>
                      {colors.map((boja) => (
                        <Select.Option key={boja.id} value={boja.id}>{boja.name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <MinusCircleOutlined
                    style={{ top: 33, position: "relative" }}
                    onClick={() => remove(name)}
                  />
                </Space>
              ))}

              <Form.Item>
                <Button
                  ref={addFieldRef}
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Dodaj
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item label="Kopiraj rezultate" name={"copyResult"}>
          <Select style={{ maxWidth: 90 }} defaultValue={false}>
            <Select.Option value={true}>Da</Select.Option>
            <Select.Option value={false}>Ne</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Spremi
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CopyTestForm;
