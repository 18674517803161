import { Button, Divider, message, Modal, Spin, Tabs } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks';
import { addTestXHR, getStandardsXHR, getTestClustersXHR, getTestProductsXHR } from '../../../../store/reducers/all/allActionCreator';
import TestTable, { IAddTest } from './TestTable';
import AddTests from '../../components/AddTests';

type Props = {}



const Tests = (props: Props) => {

    // Hooks
    const dispatch = useDispatch()
    const { id } = useParams()


    const { standards, getStandardsStatus, testProducts, testClusters } = useAppSelector(state => state.allReducer);
    const [addTestsModalVisible, set_addTestsModalVisible] = useState<boolean>(false)



    const [items, set_items] = useState<Array<{
        key: string,
        label: string,
        children: JSX.Element
    }>>();
   
   

    // rowSelection object indicates the need for row selection

    // Methods   
    useEffect(() => {
        getTestClustersXHR({
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka...")
        }, dispatch
        );
        getStandardsXHR({
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka..."),
            
        }, dispatch);
        getTestProductsXHR({
            queryParams: { product: id },
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka...")
        }, dispatch)
    }, []);

    useEffect(() => {
        // here we need to create items for tabs from standards
        if (!standards) return;
        let tmp_items: Array<{
            key: string,
            label: string,
            children: JSX.Element
        }> = [];
        standards.filter(x => {
            //ukoliko nema testova ne prikazuj starndarde
            if(testProducts.filter(y => y.standard.id === x.id)?.length === 0) return false;
            return true;
        }).forEach((standard, index) => {
            tmp_items.push({
                key: index.toString(),
                label: standard.name,
                children: <TestTable tests={testProducts.filter(x => x.standard.id === standard.id)} standard={standard} />
            })
        })
        set_items(tmp_items);
    }, [standards, testProducts])

    


    function onAddTest(list: {test: number, material_type: number}[], standard: number){
        const body: IAddTest = {
            product: Number(id),
            standard,
            tests: list,
            ref_values: [],
        }
        addTestXHR({
            body,
            errorCallback: () => message.error("Dogodila se greška na dohvatu podataka..."),
            successCallback: () => (message.success("Uspješno ste dodali test!"), set_addTestsModalVisible(false))
        }, dispatch)
    }

   
    
   

    return (
        <>
        <Spin spinning={getStandardsStatus === "loading"} >
        <Tabs items={items} tabBarExtraContent={
            <Button type='primary' onClick={() => set_addTestsModalVisible(true)}>
            Dodaj testove
            </Button>

        } defaultActiveKey="1"  />
               
              
            <Divider />

            {/* Table */}
            
        </Spin>
          {/* Add tests modal */}
    <Modal
        footer={null}
        title="Dodaj testove"
        visible={addTestsModalVisible}
        onCancel={() => set_addTestsModalVisible(false)}
        width={640}
        centered
        destroyOnClose
    >
        <AddTests
            tests={testClusters} 
            onAddTest={onAddTest} 
        />
    </Modal>
        </>

    )
}

export default Tests;