import React, { useEffect, useState } from "react";

import { Button, List, message, Modal, Popconfirm, Typography } from "antd";
import { ICategory } from "../../../models/all";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import CategoryForm from "../CategoryForm";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getUnitOfMesureXHR } from "../../../store/reducers/all/allActionCreator";
import { logoutXHR } from "../../../store/reducers/user/actionCreators";
import api from "../../../services";
import { allSlice } from "../../../store/reducers/all";

interface IProps {}

const UnitOfMesure: React.FC<IProps> = () => {
  const [unit, set_unit] = useState<ICategory>();
    const [delLoading, set_delLoading] = useState(-1);
  const { addUnitOfMesureStatus, units, getUnitOfMesureStatus } = useAppSelector(state => state.allReducer);
    const dispatch = useAppDispatch();
  useEffect(() => {
      getUnitOfMesureXHR({
          errorCallback: () => message.error("Dogodila se greška kod dohvata mjernih jedinica."),
      }, dispatch)
  }, [])

  const deleteItem = async (id: number) => {
        set_delLoading(id);
        let token = await localStorage.getItem("token");

        try {
            await api().delete(`/laboapp/measure_unit/${id}/`,
             {
                 headers: {Authorization: "Bearer " + token}
             }
            );

            let tmp = [...units];
            let index = tmp.findIndex(x => x.id === id);
            tmp.splice(index, 1);
            dispatch(allSlice.actions.getUnitOfMesureSuccess({
                message: "",
                results: tmp
            }))

        } catch (error: any) {
            if(error?.response?.status === 401){
                logoutXHR(null, dispatch);
                return;
            }

            message.error("Dogodila se greška");
        }

        set_delLoading(-1);

  };

  return (
      <>
    <List
    loading={getUnitOfMesureStatus === "loading"}
    dataSource={units}
      header={
        <div className="spaceBetweenRow">
          <Typography.Title level={4}>Mjerne jedinice</Typography.Title>
          <Button
            loading={addUnitOfMesureStatus === "loading"}
            type="primary"
            onClick={() => set_unit({ id: 0, name: "" })}
          >Dodaj</Button>
        </div>
      }
      renderItem={(item: ICategory, index: number) => (
        <List.Item
          actions={[
            <Popconfirm
              onConfirm={() => deleteItem(item.id)}
              title="Nastaviti sa akcijom..."
              cancelText="Odustani"
            >
              <Button loading={delLoading === item.id} danger type="dashed">
                <DeleteOutlined />
              </Button>
            </Popconfirm>,
            <Button onClick={() => set_unit(item)}>
              <EditFilled />
            </Button>,
          ]}
        >
          <List.Item.Meta title={item.name} />
        </List.Item>
      )}
    />
    <Modal destroyOnClose footer={false} visible={!!unit} onCancel={() => set_unit(undefined)}>

         {unit && <CategoryForm type="uom" category={unit} close={() => set_unit(undefined)} />}
    </Modal>
    </>
  );
};

export default UnitOfMesure;
