import {
  Button,
  Col,
  Drawer,
  Input,
  message,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { IApiResponse, ITableColumn } from "../../models";
import { IClient } from "../../models/all";
import { Link, useLocation } from "react-router-dom";
import { clientsXHR } from "../../store/reducers/all/allActionCreator";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import ClientForm, { ICLientForm } from "./ClientForm";
import api from "../../services";
import { logoutXHR } from "../../store/reducers/user/actionCreators";
import { allSlice } from "../../store/reducers/all";

const Clients: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [addAccountVisible, set_addAccountVisible] = useState<boolean>(false);
  const [search, set_search] = useState<string>("");
  const { clients, getClientsStatus } = useAppSelector(
    (state) => state.allReducer
  );
  const [data, set_data] = useState<IClient[]>([]);
  const [client, set_client] = useState<ICLientForm>();
  const [loading, set_loading] = useState<boolean>(false);

  const [COLUMNS, set_COLUMNS] = useState<ITableColumn<IClient>[]>([
    {
      title: "Naziv",
      dataIndex: "name",
    },
    { title: "Email", dataIndex: "email" },
    { title: "Broj telefona", dataIndex: "phone_number" },
    {
      title: "Akcije",
      dataIndex: "",
      render: (text: string, value: IClient) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Popconfirm
            onConfirm={() => deleteClient(value.id)}
            title={"Nastaviti sa akcijom"}
            cancelText="Odustani"
          >
            <Button danger type="dashed">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
          <Button
            style={{ marginLeft: 15 }}
            onClick={() => {
              set_client({
                id: value.id,
                name: value.name,
                email: value.email,
                phone_number: value.phone_number,
              });
            }}
          >
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ]);

  const deleteClient = async (id: number) => {
    set_loading(true);

    try {
      let token = await localStorage.getItem("token");
      let response = await api().delete<IApiResponse<string>>(
        `laboapp/clients/${id}/`,
        { headers: { Authorization: "Bearer " + token } }
      );

      let tmp = [...clients];
      let index = tmp.findIndex((x) => x.id === id);
      tmp.splice(index, 1);
      dispatch(allSlice.actions.clientsSucceess({ results: tmp, message: "" }));
    } catch (error: any) {
      if (error?.response?.status === 401) {
        logoutXHR(null, dispatch);
        return;
      }

      message.error("Dogodila se greška kod brisanja...");
    }

    set_loading(false);
  };

  const getClients = () => {
    clientsXHR(
      {
        errorCallback: (data: any) => message.error("errorOnFetchData"),
      },
      dispatch
    );
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    set_data(clients);
    set_COLUMNS([
      {
        title: "Naziv",
        dataIndex: "name",
        render: (text: string, value: IClient) => (
          <Link to={`${value.id}`}>{value.name}</Link>
        ),
      },
      { title: "Email", dataIndex: "email" },
      { title: "Broj telefona", dataIndex: "phone_number" },
      {
        title: "",
        dataIndex: "",
        render: (text: string, value: IClient) => (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Popconfirm
              onConfirm={() => deleteClient(value.id)}
              title={"Nastaviti sa akcijom"}
              cancelText="Odustani"
            >
              <Button danger type="dashed">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            <Button
              style={{ marginLeft: 15 }}
              onClick={() => {
                set_client({
                  id: value.id,
                  name: value.name,
                  email: value.email,
                  phone_number: value.phone_number,
                });
              }}
            >
              <EditOutlined />
            </Button>
          </div>
        ),
      },
    ]);
  }, [clients]);

  let filtredData = [];

  if (search) {
    filtredData = clients?.filter((x) => x.name.toLowerCase().includes(search));
  } else {
    filtredData = clients;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "18px 0",
        }}
      >
        <Typography.Title level={5}>Pregled klijenata</Typography.Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip title={"Pretraži po nazivu."}>
              <InfoCircleOutlined />
            </Tooltip>
            <div style={{ width: 12 }} />

            <Input.Search
              onChange={({target: {value}}) => {
                if(!value){
                  set_search("");
                  return;
                }
                set_search(value.toLowerCase())
              }}
              style={{ marginRight: 18 }}
              allowClear
            />
          </div>
          <Button
            onClick={() =>
              set_client({
                id: 0,
                name: "",
                email: "",
                phone_number: "",
              })
            }
            type="primary"
          >
            Dodaj
          </Button>
        </div>
      </div>

      <Row>
        <Col span={24}>
          <Spin spinning={getClientsStatus === "loading"}>
            <Table
              rowKey={(item) => item.id}
              dataSource={filtredData}
              columns={COLUMNS}
            />
          </Spin>
        </Col>
        {/* <Col span={12}></Col> */}
      </Row>

      <Drawer
        title={"Dodaj klijenta"}
        width={540}
        destroyOnClose={true}
        children={
          <ClientForm client={client} close={() => set_client(undefined)} />
        }
        visible={!!client}
        onClose={() => set_client(undefined)}
      />
    </div>
  );
};

export default Clients;
