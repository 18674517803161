import React, { useEffect } from "react";

import { Button, Form, Input, InputNumber, message, Select } from "antd";


import { IApiResponse } from "../../models";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addClientXHR, updateClientXHR } from "../../store/reducers/all/allActionCreator";
import { IClient } from "../../models/all";

const { Option } = Select;

interface IProps {
  client: undefined | ICLientForm;
  close: () => void;
}

export interface ICLientForm{
    id: number,
    name: string,
    email: string,
    phone_number: string
}

const ClientForm: React.FC<IProps> = ({ client, close }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { addClientStatus } = useAppSelector((state) => state.allReducer);


  const onFinish = (values: ICLientForm) => {
    if (!!client?.id) {
      // update user
      updateClientXHR(
        {
          id: client.id.toString(),
          body: values,
          errorCallback: (data: any) =>
            message.error("Dogodila se greška na spremanje podataka"),
          successCallback: () => close(),
        },
        dispatch
      );

      return;
    }

    addClientXHR(
      {
        body: values,
        errorCallback: () => message.error("Dogodila se greška na spremanje podataka"),
        successCallback: () => close(),
      },
      dispatch
    );
  };

 
 

  return (
    <Form form={form} initialValues={client} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name={"name"}
        label={"Naziv"}
        rules={[{ required: true, message: "Ovo polje je obavezno" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"email"}
        label={"Email"}
        rules={[{ required: true, message: "Ovo polje je obavezno" }]}
      >
        <Input />
      </Form.Item>
      
      <Form.Item name={"phone_number"} label={"Broj telefona"}>
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          loading={addClientStatus === "loading"}
          htmlType="submit"
          type="primary"
        >
          {"Spremi"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ClientForm;
