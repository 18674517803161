import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import {
  Button,
  Drawer,
  List,
  message,
  Modal,
  Popconfirm,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { createTree } from "../../../../helpers/functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { NewTreeItem } from "../../../../models";
import { IMaterialType } from "../../../../models/all";
import { getMaterialTypesXHR } from "../../../../store/reducers/all/allActionCreator";
import { logoutXHR } from "../../../../store/reducers/user/actionCreators";
import MaterialTypeForm from "./MaterialTypeForm";
import TypeListItem from "./TypeListItem";
import api from "../../../../services";
import {allSlice} from "../../../../store/reducers/all";

const MaterialTypes: React.FC = () => {
  const [materialType, set_materialType] = useState<IMaterialType>();
  const [deleteLoading, set_deleteLoading] = useState<number>();
  const dispatch = useAppDispatch();
  const { materialTypes, getMaterialTypesStatus } = useAppSelector(
    (state) => state.allReducer
  );

  useEffect(() => {
    getMaterialTypesXHR(
      {
        errorCallback: () =>
          message.error("Dogodila se greška kod dohvata kategorija"),
      },
      dispatch
    );
  }, []);

  const deleteMaterialType = async (id: number) => {
    set_deleteLoading(id);
    let token = await localStorage.getItem("token");
    

    try {
      let response = api().delete<string>(`laboapp/material_type/${id}/`, {
        headers: { Authorization: "Bearer " + token }
      })
      let tmp = [...materialTypes];
      let index = tmp.findIndex(x => x.id === id);
      tmp.splice(index, 1);
      dispatch(allSlice.actions.getMaterialTypesSuccess({message: "", results: tmp}))
    } catch (error: any) {
      if(error?.response?.status === 401){
        logoutXHR(null, dispatch);
        return;
      }

      message.error("Dogodila se greška...");
    }

    set_deleteLoading(id);
  };

  const tree = createTree(materialTypes.map(x => ({...x, parent: x.parent_id})), null);


  return (
    <>
      <List
        dataSource={tree}
        loading={getMaterialTypesStatus === "loading"}
        header={
          <div className="spaceBetweenRow">
            <Typography.Title level={4}>Tipovi materijala</Typography.Title>
            <Button
              onClick={() =>
                set_materialType({ id: 0, name: "", parent_id: null })
              }
              type="primary"
            >
              Dodaj
            </Button>
          </div>
        }
        renderItem={(item: NewTreeItem<IMaterialType>, index: number) => (
          <TypeListItem
            item={item}
            deep={1}
            deleteLoading={deleteLoading}
            onDelete={deleteMaterialType}
            set_update={set_materialType}
          />
        )}
      />
      <Drawer
        title="Dodavanje tipa materijala"
        destroyOnClose={true}
        footer={null}
        visible={!!materialType}
        onClose={() => set_materialType(undefined)}
      >
        <MaterialTypeForm
          close={() => set_materialType(undefined)}
          materialType={{
            name: materialType?.name || "",
            parent_id: materialType?.parent_id|| null,
            id: materialType?.id || 0,
          }}
        />
      </Drawer>
    </>
  );
};

export default MaterialTypes;
