
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IApiResponse } from "../../../models";
import {
IAccount,
ICategory,
IClient,
IColor,
ICustomFields,
IEquipment,
IFinishCalibration,
IMaterialType,
IProduct,
IProductDetails,
IProductTesting,
IProductTestingDetails,
IStandard,
ITest,
ITestCluster,
ITestClusterDetails,
ITestingTest,
ITestMethod,
ITestModelForm,
ITestProduct,
} from "../../../models/all";


// Define a type for the slice state
interface AccountState {
getReportDataStatus: string,
updateColorStatus: string,
saveColorStatus: string,
colors: IColor[];
getColorsStatus: string;
productTestCopyStatus: string;
updateProductTestingStatus: string,
updateTestingTestStatus: string,
testingTest: ITestingTest | null,
getTestingTestStatus: string,
saveTriesStatus: string,
updateUnitOfMesureStatus: string,
getUnitOfMesureStatus: string,
units: ICategory[];
clients: IClient[];
getClientsStatus: string;
addClientStatus: string;
updateClientStatus: string;
getProductsStatus: string;
products: IProduct[];
addProductStatus: string;
updateProductStatus: string;
addProductCategoryStatus: string;
productCategories: ICategory[];
getProductCategoriesStatus: string;
updateProductCategoriesStatus: string;
updateCustomFieldsStatus: string;
getCustomFieldsStatus: string;
customFields: ICustomFields;
getStandardsStatus: string;
standards: IStandard[];
addStandardsStatus: string;
updateStandardsStatus: string;
getEqipmentStatus: string;
equipments: IEquipment[];
addEquipmentStatus: string;
updateEquipmentStatus: string;
addEquipmentCategoryStatus: string;
equipmentCategories: ICategory[];
updateEquipmentCategoryStatus: string;
getEquipmentCategoryStatus: string;
getEquipmentStatus: string;
equipment: IEquipment,
finishCalibrationStatus: string,
accounts: IAccount[],
getAccountsStatus: string,
addAccountStatus: string,
updateAccountStatus: string,
addTestMethodStatus: string,
testMethods: ITestMethod[],
getTestMethodsStatus: string,
updateTestMethodStatus: string,
testClusters: ITestCluster[],
getTestClustersStatus: string,
addTestClustersStatus: string,
getMaterialTypesStatus: string,
materialTypes: IMaterialType[],
createTestClusterStatus: string,
updateTestClusterStatus: string,
getTestClusterDetailsStatus: string,
testClusterDetails?: ITestClusterDetails,
createTestModelFormStatus: string,
updateTestModelFormStatus: string,
indexToUpdateTestModel: number,
createMaterialTypeStatus: string,
getMaterialTypeStatus: string,
updateMaterialTypeStatus: string,
testProducts: ITestProduct[],
getTestProductsStatus: string,
testProduct: ITestProduct,
getTestProductStatus: string,
addTestStatus: string,
updateStandardRequirementsFieldsStatus: string,
updateProductTestStatus: string,
copyTestsStatus: string,
getProductStatus: string,
productDetails: IProductDetails | null,
addUnitOfMesureStatus: string,
productTestings: IProductTesting[],
getProductTestingsStatus: string,
createProductTestingStatus: string,
productTestingDetails: IProductTestingDetails | null,
getProductTestingDetailsStatus: string,
}


// Define the initial state using that type
const initialState = {
getReportDataStatus: "loading",
updateColorStatus: "",
saveColorStatus: "",
colors : [],
getColorsStatus: "",
productTestCopyStatus: "",
updateProductTestingStatus: "",
updateTestingTestStatus: "",
testingTest: null,
getTestingTestStatus: "",
saveTriesStatus: "",
updateUnitOfMesureStatus: "",
getUnitOfMesureStatus: "",
units: [],
addUnitOfMesureStatus: "",
clients: [],
getClientsStatus: "",
addClientStatus: "",
updateClientStatus: "",
getProductsStatus: "",
products: [],
addProductStatus: "",
updateProductStatus: "",
addProductCategoryStatus: "",
productCategories: [],
getProductCategoriesStatus: "",
updateProductCategoriesStatus: "",
customFields: { product_fields: [] },
updateCustomFieldsStatus: "",
getCustomFieldsStatus: "",
getStandardsStatus: "",
standards: [],
addStandardsStatus: "",
updateStandardsStatus: "",
getEqipmentStatus: "",
equipments: [],
addEquipmentStatus: "",
updateEquipmentStatus: "",
equipmentCategories: [],
addEquipmentCategoryStatus: "",
updateEquipmentCategoryStatus: "",
getEquipmentCategoryStatus: "",
getEquipmentStatus: "",
equipment: {
id: 0,
name: "",
category: undefined,
oem: "",
type: "",
serial_number: "",
calibration_every: null,
calibration_unit: null,
next_calibration: null,
calibration_required: false,
certificate_number: null,
measuring_range: null,
measurable_insecurity: null,
calibration_body: ""
},
finishCalibrationStatus: "",
accounts: [],
getAccountsStatus: "",
addAccountStatus: "",
updateAccountStatus: "",
addTestMethodStatus: "",
testMethods: [],
getTestMethodsStatus: "",
updateTestMethodStatus: "",
testClusters: [],
getTestClustersStatus: "",
addTestClustersStatus: "",
getMaterialTypesStatus: "",
materialTypes: [],
createTestClusterStatus: "",
updateTestClusterStatus: "",
getTestClusterDetailsStatus: "",
testClusterDetails: undefined,
createTestModelFormStatus: "",
updateTestModelFormStatus: "",
indexToUpdateTestModel: -1,
createMaterialTypeStatus: "",
getMaterialTypeStatus: "",
updateMaterialTypeStatus: "",
testProducts: [],
getTestProductsStatus: "",
testProduct: {} as ITestProduct,
getTestProductStatus: "",
addTestStatus: "",
updateStandardRequirementsFieldsStatus: "",
updateProductTestStatus: "",
copyTestsStatus: "",
getProductStatus: "",
productDetails: null,
getProductTestingsStatus: "",
productTestings: [],
createProductTestingStatus: "",
productTestingDetails: null,
getProductTestingDetailsStatus: "",
} as AccountState;


export const allSlice = createSlice({
name: "user",
initialState,
reducers: {
clients(state) {
  state.getClientsStatus = "loading";
},
clientsSucceess(state, action: PayloadAction<IApiResponse<IClient[]>>) {
  state.clients = action.payload.results;
  state.getClientsStatus = "";
},
clientsFail(state, action: PayloadAction<string>) {
  state.getClientsStatus = action.payload;
},
addClient(state) {
  state.getClientsStatus = "loading";
},
addClientSucceess(state, action: PayloadAction<IApiResponse<IClient>>) {
  const data = [...state.clients];
  data.push(action.payload.results);
  state.clients = data;
  state.getClientsStatus = "";
},
addClientFail(state, action: PayloadAction<string>) {
  state.getClientsStatus = action.payload;
},


// -----


updateClient(state) {
  state.updateClientStatus = "loading";
},
updateClientSuccess(state, action: PayloadAction<IApiResponse<IClient>>) {
  let tmp = [...state.clients];
  let index = tmp.findIndex((x) => x.id === action.payload.results.id);
  tmp[index] = action.payload.results;
  state.clients = tmp;
  state.updateClientStatus = "";
},
updateClientFail(state, action: PayloadAction<string>) {
  state.updateClientStatus = action.payload;
},


// -----


getProducts(state) {
  state.getProductsStatus = "loading";
},
getProductsSuccess(state, action: PayloadAction<IApiResponse<IProduct[]>>) {
  state.products = action.payload.results;
  state.getProductsStatus = "";
},
getProductsFail(state, action: PayloadAction<string>) {


  state.getProductsStatus = action.payload;
},


// -----


addProduct(state) {
  state.addProductStatus = "loading";
},
addProductSuccess(state, action: PayloadAction<IApiResponse<IProduct>>) {
  let arr = [...state.products];
  arr.push(action.payload.results)
  state.products = arr;
  state.addProductStatus = "";
},
addProductFail(state, action: PayloadAction<string>) {
  state.addProductStatus = action.payload;
},


// -----


updateProduct(state) {
  state.updateProductStatus = "loading";
},
updateProductSuccess(state, action: PayloadAction<IApiResponse<IProduct>>) {
  let tmp = [...state.products];
  let index = tmp.findIndex(x => x.id === action.payload.results.id);
  tmp[index] = action.payload.results;
  state.products = tmp;
  state.updateProductStatus = "";
},
updateProductFail(state, action: PayloadAction<string>) {
  state.updateProductStatus = action.payload;
},


// -----


addProductCategory(state) {
  state.addProductCategoryStatus = "loading";
},
addProductCategorySuccess(
  state,
  action: PayloadAction<IApiResponse<ICategory>>
) {
  let tmp = [...state.productCategories];
  tmp.push(action.payload.results);
  state.productCategories = tmp;
  state.addProductCategoryStatus = "";
},
addProductCategoryFail(state, action: PayloadAction<string>) {
  state.addProductCategoryStatus = action.payload;
},


// -----


getProductCategories(state) {
  state.getProductCategoriesStatus = "loading";
},
getProductCategoriesSuccess(
  state,
  action: PayloadAction<IApiResponse<ICategory[]>>
) {
  state.productCategories = action.payload.results;
  state.getProductCategoriesStatus = "";
},
getProductCategoriesFail(state, action: PayloadAction<string>) {
  state.getProductCategoriesStatus = action.payload;
},


// -----


updateProductCategories(state) {
  state.updateProductCategoriesStatus = "loading";
},
updateProductCategoriesSuccess(
  state,
  action: PayloadAction<IApiResponse<ICategory>>
) {
  let tmp = [...state.productCategories];
  let index = tmp.findIndex((x) => x.id === action.payload.results.id);
  tmp[index] = action.payload.results;
  state.productCategories = tmp;


  state.updateProductCategoriesStatus = "";
},
updateProductCategoriesFail(state, action: PayloadAction<string>) {
  state.updateProductCategoriesStatus = action.payload;
},


// -----


updateCustomFields(state) {
  state.updateCustomFieldsStatus = "loading";
},
updateCustomFieldsSuccess(
  state,
  action: PayloadAction<IApiResponse<ICustomFields>>
) {
  state.customFields = action.payload.results;
  state.updateCustomFieldsStatus = "";
},
updateCustomFieldsFail(state, action: PayloadAction<string>) {
  state.updateCustomFieldsStatus = action.payload;
},


// -----


getCustomFields(state) {
  state.getCustomFieldsStatus = "loading";
},
getCustomFieldsSuccess(
  state,
  action: PayloadAction<IApiResponse<ICustomFields>>
) {
  state.getCustomFieldsStatus = "";
  state.customFields = action.payload.results;
},
getCustomFieldsFail(state, action: PayloadAction<string>) {
  state.getCustomFieldsStatus = action.payload;
},




 
  getStandards(state) {
    state.getStandardsStatus = "loading";
  },
  getStandardsSuccess(state, action: PayloadAction<IApiResponse<IStandard[]>>) {


    state.standards = action.payload.results;
      state.getStandardsStatus = "";
  },
  getStandardsFail(state, action: PayloadAction<string>) {
    state.getStandardsStatus = action.payload;
  },


   // -----
 
   addStandards(state) {
    state.addStandardsStatus = "loading";
  },
  addStandardsSuccess(state, action: PayloadAction<IApiResponse<IStandard>>) {
 
      let standards = [...state.standards];
      standards.push(action.payload.results);
    state.standards = standards;
      state.addStandardsStatus = "";
  },
  addStandardsFail(state, action: PayloadAction<string>) {
    state.addStandardsStatus = action.payload;
  },
     // --------
     updateStandards(state) {
      state.updateStandardsStatus = "loading";
    },
    updateStandardsSuccess(state, action: PayloadAction<IApiResponse<IStandard>>) {
      let standards = [...state.standards];
      let index = standards.findIndex(x => x.id === action.payload.results.id);
      standards[index] = action.payload.results;
      state.standards = standards;
      state.updateStandardsStatus = "";
    },
    updateStandardsFail(state, action: PayloadAction<string>) {
      state.updateStandardsStatus = action.payload;
    },
  // -----
  getEqipment(state) {
    state.getEqipmentStatus = "loading";
  },
  getEqipmentSuccess(state, action: PayloadAction<IApiResponse<IEquipment[]>>) {


      state.equipments = action.payload.results;
      state.getEqipmentStatus = "";
  },
  getEqipmentFail(state, action: PayloadAction<string>) {
    state.getEqipmentStatus = action.payload;
  },

  // -----
 
  addEquipment(state) {
      state.addEquipmentStatus = "loading";
    },
    addEquipmentSuccess(state, action: PayloadAction<IApiResponse<IEquipment>>) {
         let equipments = [...state.equipments];
        equipments.push(action.payload.results);
        state.equipments = equipments;
        state.addEquipmentStatus = "";
    },
    addEquipmentFail(state, action: PayloadAction<string>) {
      state.addEquipmentStatus = action.payload;
    },
  // -----
 
  updateEquipment(state) {
      state.updateEquipmentStatus = "loading";
    },
    updateEquipmentSuccess(state, action: PayloadAction<IApiResponse<IEquipment>>) {
      let equipments = [...state.equipments];
      let index = equipments.findIndex(x => x.id === action.payload.results.id);
      equipments[index] = action.payload.results;
      state.equipments = equipments;
      state.updateEquipmentStatus = "";
      state.equipment = action.payload.results
    },
    updateEquipmentFail(state, action: PayloadAction<string>) {
      state.updateEquipmentStatus = action.payload;
    },
 
 



   // -----
 
  addEquipmentCategory(state) {
      state.addEquipmentCategoryStatus = "loading";
    },
    addEquipmentCategorySuccess(state, action: PayloadAction<IApiResponse<ICategory>>) {
       let equipmentCategories = [...state.equipmentCategories];
      equipmentCategories.push(action.payload.results);
      state.equipmentCategories = equipmentCategories;
      state.addEquipmentStatus = "";
         state.addEquipmentCategoryStatus = "";
    },
    addEquipmentCategoryFail(state, action: PayloadAction<string>) {
      state.addEquipmentCategoryStatus = action.payload;
    },





  // -----
 
  updateEquipmentCategory(state) {
      state.updateEquipmentCategoryStatus = "loading";
    },
    updateEquipmentCategorySuccess(state, action: PayloadAction<IApiResponse<ICategory>>) {
       let equipmentCategories = [...state.equipmentCategories];
      let index = equipmentCategories.findIndex(x => x.id === action.payload.results.id);
      equipmentCategories[index] = action.payload.results;
      state.equipmentCategories = equipmentCategories;
      state.addEquipmentStatus = "";
         state.updateEquipmentCategoryStatus = "";
    },
    updateEquipmentCategoryFail(state, action: PayloadAction<string>) {
      state.updateEquipmentCategoryStatus = action.payload;
    },
     // -----
   
    getEquipmentCategory(state) {
        state.getEquipmentCategoryStatus = "loading";
      },
      getEquipmentCategorySuccess(state, action: PayloadAction<IApiResponse<ICategory[]>>) {

          state.equipmentCategories = action.payload.results;
          state.getEquipmentCategoryStatus = "";
        },
      getEquipmentCategoryFail(state, action: PayloadAction<string>) {
        state.getEquipmentCategoryStatus = action.payload;
      },
   
 


      // -----
     
      getEquipment(state) {
        state.getEquipmentStatus = "loading";
      },
      getEquipmentSuccess(state, action: PayloadAction<IApiResponse<IEquipment>>) {
        state.equipment = action.payload.results
        state.getEquipmentStatus = "";
      },
      getEquipmentFail(state, action: PayloadAction<string>) {
        state.getEquipmentStatus = action.payload;
      },
     
     
      // -----
     
      finishCalibration(state) {
        state.finishCalibrationStatus = "loading";
      },
      finishCalibrationSuccess(state, action: PayloadAction<IApiResponse<IFinishCalibration>>) {
        state.finishCalibrationStatus = "";
      },
      finishCalibrationFail(state, action: PayloadAction<string>) {
        state.finishCalibrationStatus = action.payload;
      },


      // -----


      getAccounts(state) {
        state.getAccountsStatus = "loading";
      },
      getAccountsSuccess(state, action: PayloadAction<IApiResponse<IAccount[]>>) {
        state.getAccountsStatus = "";
        state.accounts = action.payload.results
      },
      getAccountsFail(state, action: PayloadAction<string>) {
        state.getAccountsStatus = action.payload;
      },
 
      // -----


      addAccount(state) {
        state.addAccountStatus = "loading";
      },
      addAccountSucceess(state, action: PayloadAction<IApiResponse<IAccount>>) {
        const data = [...state.accounts];
        data.push(action.payload.results);
        state.accounts = data;
        state.addAccountStatus = "";
      },
      addAccountFail(state, action: PayloadAction<string>) {
        state.addAccountStatus = action.payload;
      },


      // -----


      updateAccount(state) {
        state.updateAccountStatus = "loading";
      },
      updateAccounttSuccess(state, action: PayloadAction<IApiResponse<IAccount>>) {
        let tmp = [...state.accounts];
        let index = tmp.findIndex((x) => x.id === action.payload.results.id);
        tmp[index] = action.payload.results;
        state.accounts = tmp;
        state.updateAccountStatus = "";
      },
      updateAccountFail(state, action: PayloadAction<string>) {
        state.updateAccountStatus = action.payload;
      },




       // -----
 
  addTestMethod(state) {
    state.addTestMethodStatus = "loading";
  },
  addTestMethodSuccess(state, action: PayloadAction<IApiResponse<ITestMethod>>) {
      let tmp = [...state.testMethods];
      tmp.push(action.payload.results);
      state.testMethods = tmp;
      state.addTestMethodStatus = "";
  },
  addTestMethodFail(state, action: PayloadAction<string>) {
    state.addTestMethodStatus = action.payload;
  },


 


   // -----
 
  getTestMethods(state) {
      state.getTestMethodsStatus = "loading";
    },
    getTestMethodsSuccess(state, action: PayloadAction<IApiResponse<ITestMethod[]>>) {
       state.testMethods = action.payload.results;
        state.getTestMethodsStatus = "";
    },
    getTestMethodsFail(state, action: PayloadAction<string>) {
      state.getTestMethodsStatus = action.payload;
    },




          // -----
         
          updateTestMethod(state) {
            state.updateTestMethodStatus = "loading";
          },
          updateTestMethodSuccess(state, action: PayloadAction<IApiResponse<ITestMethod>>) {
              let tmp = [...state.testMethods];
              let index = tmp.findIndex(x => x.id === action.payload.results.id);
              tmp[index] = action.payload.results;
              state.testMethods = tmp;
              state.updateTestMethodStatus = "";
          },
          updateTestMethodFail(state, action: PayloadAction<string>) {
            state.updateTestMethodStatus = action.payload;
          },
 
         
          // -----
         
          getTestClusters(state) {
              state.getTestClustersStatus = "loading";
            },
            getTestClustersSuccess(state, action: PayloadAction<IApiResponse<ITestCluster[]>>) {
     
                state.testClusters = action.payload.results;
                state.getTestClustersStatus = "";
            },
            getTestClustersFail(state, action: PayloadAction<string>) {
              state.getTestClustersStatus = action.payload;
            },


            addTestClusters(state) {
              state.addTestClustersStatus = "loading";
            },
            addTestClustersSuccess(state, action: PayloadAction<IApiResponse<ITestCluster>>) {
     
                let tmp = [...state.testClusters];
                tmp.push(action.payload.results);
                state.testClusters = tmp;
                state.addTestClustersStatus = "";
            },
            addTestClustersFail(state, action: PayloadAction<string>) {
              state.addTestClustersStatus = action.payload;
            },
     
             // -----
     
      getMaterialTypes(state) {
        state.getMaterialTypesStatus = "loading";
      },
      getMaterialTypesSuccess(state, action: PayloadAction<IApiResponse<IMaterialType[]>>) {
       
          state.materialTypes = action.payload.results;
          state.getMaterialTypesStatus = "";
      },
      getMaterialTypesFail(state, action: PayloadAction<string>) {
        state.getMaterialTypesStatus = action.payload;
      },
         


   // -----
 
  updateTestCluster(state) {
      state.updateTestClusterStatus = "loading";
    },
    updateTestClusterSuccess(state, action: PayloadAction<IApiResponse<ITestCluster>>) {
       let tmp = [...state.testClusters];
      let index = tmp.findIndex(x => x.id === action.payload.results.id);
      tmp[index] = action.payload.results;
      state.testClusters = tmp;
      state.updateTestClusterStatus = "";
    },
    updateTestClusterFail(state, action: PayloadAction<string>) {
      state.updateTestClusterStatus = action.payload;
    },
 
 


          // -----
 
  getTestClusterDetails(state) {
    state.getTestClusterDetailsStatus = "loading";
  },
  getTestClusterDetailsSuccess(state, action: PayloadAction<IApiResponse<ITestClusterDetails>>) {
      state.testClusterDetails = action.payload.results;
      state.getTestClusterDetailsStatus = "";
  },
  getTestClusterDetailsFail(state, action: PayloadAction<string>) {
    state.getTestClusterDetailsStatus = action.payload;
  },
 
     // -----
 
     createTestModelForm(state) {
      state.createTestModelFormStatus = "loading";
    },
    createTestModelFormSuccess(state, action: PayloadAction<IApiResponse<ITestModelForm>>) {
     
        let tmp: ITestModelForm[] = [];
        if(state.testClusterDetails){
          tmp = [...state.testClusterDetails.model_forms];
          tmp.push(action.payload.results);
          state.testClusterDetails = {
            ...state.testClusterDetails,
            model_forms: tmp
          }
        }

     
    
        state.createTestModelFormStatus = "";
    },
    createTestModelFormFail(state, action: PayloadAction<string>) {
      state.createTestModelFormStatus = action.payload;
    },




  // -----
 
    updateTestModelForm(state) {
      state.updateTestModelFormStatus = "loading";
    },
    updateTestModelFormSuccess(state, action: PayloadAction<IApiResponse<ITestModelForm> & {prev_id: number | string | undefined}>) {
      if(state.testClusterDetails){
        let tmp =[...state.testClusterDetails.model_forms];
        let index = tmp.findIndex(x => x.id.toString() === action.payload.prev_id?.toString());
        if(index > -1){
          tmp[index] = action.payload.results;
          let tmpTestClusterDetails = {...state.testClusterDetails, model_forms: tmp};
          state.testClusterDetails = tmpTestClusterDetails;
        }
      }
      state.updateTestModelFormStatus = "";
    },
    updateTestModelFormFail(state, action: PayloadAction<string>) {
      state.updateTestModelFormStatus = action.payload;
    },




    setIndexOfItemForTestModelForm(state, action: PayloadAction<number>){
      state.indexToUpdateTestModel = action.payload;
    },




   // -----
 
    createMaterialType(state) {
      state.createMaterialTypeStatus = "loading";
    },
    createMaterialTypeSuccess(state, action: PayloadAction<IMaterialType>) {
         let tmp = [...state.materialTypes];
        tmp.push(action.payload);
        state.materialTypes = tmp;
        state.createMaterialTypeStatus = "";
    },
    createMaterialTypeFail(state, action: PayloadAction<string>) {
      state.createMaterialTypeStatus = action.payload;
    },
 
   // -----
 
    updateMaterialType(state) {
      state.updateMaterialTypeStatus = "loading";
    },
    updateMaterialTypeSuccess(state, action: PayloadAction<IApiResponse<IMaterialType>>) {
         let tmp = [...state.materialTypes];
        let index = tmp.findIndex(x => x.id === action.payload.results.id);
        tmp[index] = action.payload.results;
        state.materialTypes = tmp;
        state.updateMaterialTypeStatus = "";
    },
    updateMaterialTypeFail(state, action: PayloadAction<string>) {
      state.updateMaterialTypeStatus = action.payload;
    },
   // -----
 
  updateStandardRequirementsFields(state) {
      state.updateStandardRequirementsFieldsStatus = "loading";
    },
    updateStandardRequirementsFieldsSuccess(state, action: PayloadAction<IApiResponse<ITestClusterDetails>>) {

        state.updateStandardRequirementsFieldsStatus = "";
    },
    updateStandardRequirementsFieldsFail(state, action: PayloadAction<string>) {
      state.updateStandardRequirementsFieldsStatus = action.payload;
    },
 
 
 
 
 
  // -----


  getTestProducts(state) {
    state.getTestProductsStatus = "loading";
  },
  getTestProductsSuccess(state, action: PayloadAction<IApiResponse<ITestProduct[]>>) {
    state.testProducts = action.payload.results;
    state.getTestProductsStatus = "";
  },
  getTestProductsFail(state, action: PayloadAction<string>) {
    state.getTestProductsStatus = action.payload;
  },


  // -----


  getTestProduct(state) {
    state.getTestProductStatus = "loading";
  },
  getTestProductSuccess(state, action: PayloadAction<IApiResponse<ITestProduct>>) {
    state.testProduct = action.payload.results;
    state.getTestProductStatus = "";
  },
  getTestProductFail(state, action: PayloadAction<string>) {
    state.getTestProductStatus = action.payload;
  },


  // -----


  addTest(state) {
    state.addTestStatus = "loading";
  },
  addTestSuccess(state, action: PayloadAction<IApiResponse<ITestProduct[]>>) {
    state.testProducts = action.payload.results
    state.addTestStatus = "";
  },
  addTestFail(state, action: PayloadAction<string>) {
    state.addTestStatus = action.payload;
  },


  // -----


  updateProductTest(state) {
    state.updateProductTestStatus = "loading";
  },
  updateProductTestSuccess(state, action: PayloadAction<IApiResponse<ITestProduct[]>>) {
    state.testProducts = action.payload.results;
    state.updateProductTestStatus = "";
  },
  updateProductTestFail(state, action: PayloadAction<string>) {
    state.updateProductTestStatus = action.payload;
  },


  // -----


  copyTests(state) {
    state.copyTestsStatus = "loading";
  },
  copyTestsSuccess(state, action: PayloadAction<IApiResponse<ITestProduct[]>>) {
    state.testProducts = action.payload.results
    state.copyTestsStatus = "";
  },
  copyTestsFail(state, action: PayloadAction<string>) {
    state.copyTestsStatus = action.payload;
  },


   // -----
getProduct(state) {
state.getProductStatus = "loading";
},
getProductSuccess(state, action: PayloadAction<IApiResponse<IProductDetails>>) {


  state.productDetails = action.payload.results;
  state.getProductStatus = "";
},
getProductFail(state, action: PayloadAction<string>) {
state.getProductStatus = action.payload;
},


addUnitOfMesure(state) {
state.addUnitOfMesureStatus = "loading";
},
addUnitOfMesureSuccess(state, action: PayloadAction<IApiResponse<ICategory[]>>) {
  state.units = action.payload.results;
  state.addUnitOfMesureStatus = "";
},
addUnitOfMesureFail(state, action: PayloadAction<string>) {
state.addUnitOfMesureStatus = action.payload;
},


// -----
 
getUnitOfMesure(state) {
  state.getUnitOfMesureStatus = "loading";
},
getUnitOfMesureSuccess(state, action: PayloadAction<IApiResponse<ICategory[]>>) {


    state.units = action.payload.results;
    state.getUnitOfMesureStatus = "";
},
getUnitOfMesureFail(state, action: PayloadAction<string>) {
  state.getUnitOfMesureStatus = action.payload;
},
  // -----
 
  updateUnitOfMesure(state) {
      state.updateUnitOfMesureStatus = "loading";
    },
    updateUnitOfMesureSuccess(state, action: PayloadAction<IApiResponse<ICategory>>) {


        let tmp = [...state.units];
        let index = tmp.findIndex(x => x.id === action.payload.results.id);
         tmp[index] = action.payload.results;
         state.units = tmp;
        state.updateUnitOfMesureStatus = "";
    },
    updateUnitOfMesureFail(state, action: PayloadAction<string>) {
      state.updateUnitOfMesureStatus = action.payload;
    },
 
// -----


getProductTestings(state) {
  state.getProductTestingsStatus = "loading";
},
getProductTestingsSuccess(state, action: PayloadAction<IApiResponse<IProductTesting[]>>) {
  state.productTestings = action.payload.results;
  state.getProductTestingsStatus = "";
},
getProductTestingsFail(state, action: PayloadAction<string>) {
  state.getProductTestingsStatus = action.payload;
},


// -----


createProductTesting(state) {
  state.createProductTestingStatus = "loading";
},
createProductTestingSuccess(state, action: PayloadAction<IApiResponse<ITest>>) {
  state.createProductTestingStatus = "";
},
createProductTestingFail(state, action: PayloadAction<string>) {
  state.createProductTestingStatus = action.payload;
},


// -----


getProductTestingDetails(state) {
  state.getProductTestingDetailsStatus = "loading";
},
getProductTestingDetailsSuccess(state, action: PayloadAction<IApiResponse<IProductTestingDetails>>) {
  state.productTestingDetails = action.payload.results;
  state.getProductTestingDetailsStatus = "";
},
getProductTestingDetailsFail(state, action: PayloadAction<string>) {
  state.getProductTestingDetailsStatus = action.payload;
},


// -----


saveResTestModelForm(state) {
  state.saveTriesStatus = "loading";
},
saveResTestModelFormSuccess(state, action: PayloadAction<IApiResponse<ITestingTest>>) {
    state.testingTest = action.payload.results;
    state.saveTriesStatus = "";
},
saveResTestModelFormFail(state, action: PayloadAction<string>) {
  state.saveTriesStatus = action.payload;
},


 // -----

 getTestingTest(state) {
  state.getTestingTestStatus = "loading";
},
getTestingTestSuccess(state, action: PayloadAction<IApiResponse<ITestingTest>>) {
  state.testingTest = action.payload.results;
    state.getTestingTestStatus = "";
},
getTestingTestFail(state, action: PayloadAction<string>) {
  state.getTestingTestStatus = action.payload;
},



    // -----
   
    updateTestingTest(state) {
      state.updateTestingTestStatus = "loading";
    },
    updateTestingTestSuccess(state, action: PayloadAction<IApiResponse<ITestingTest>>) {
      state.testingTest = action.payload.results;
        state.updateTestingTestStatus = "";
    },
    updateTestingTestFail(state, action: PayloadAction<string>) {
      state.updateTestingTestStatus = action.payload;
    },



    updateProductTesting(state) {
      state.updateProductTestingStatus = "loading";
    },
    updateProductTestingSuccess(state, action: PayloadAction<IApiResponse<IProductTestingDetails>>) {
      state.productTestingDetails = action.payload.results;
        state.updateProductTestingStatus = "";
    },
    updateProductTestingFail(state, action: PayloadAction<string>) {
      state.updateProductTestingStatus = action.payload;
    },


       
    productTestCopy(state) {
      state.productTestCopyStatus = "loading";
    },
    productTestCopySuccess(state, action: PayloadAction<IApiResponse<ITest[]>>) {
      if(!state.productTestingDetails){
        return;
      }
      state.productTestingDetails = {
        ...state.productTestingDetails,
        tests: action.payload.results
      };
        state.productTestCopyStatus = "";
    },
    productTestCopyFail(state, action: PayloadAction<string>) {
      state.productTestCopyStatus = action.payload;
    },




     // -----
           
            getColors(state) {
                state.getColorsStatus = "loading";
              },
              getColorsSuccess(state, action: PayloadAction<IApiResponse<IColor[]>>) {
                state.colors = action.payload.results;
                  state.getColorsStatus = "";
              },
              getColorsFail(state, action: PayloadAction<string>) {
                state.getColorsStatus = action.payload;
              },


              saveColor(state) {
                state.saveColorStatus = "loading";
              },
              saveColorSuccess(state, action: PayloadAction<IApiResponse<IColor[]>>) {
       
                  state.colors = action.payload.results;
                  state.saveColorStatus = "";
              },
              saveColorFail(state, action: PayloadAction<string>) {
                state.saveColorStatus = action.payload;
              },
                  // -----
                   
                  updateColor(state) {
                    state.updateColorStatus = "loading";
                  },
                  updateColorSuccess(state, action: PayloadAction<IApiResponse<IColor>>) {
           
                      let tmp = [...state.colors];
                      let index = tmp.findIndex(x => x.id === action.payload.results.id);
                      tmp[index] = action.payload.results;
                      state.colors = tmp;
                      state.updateColorStatus = "";
                  },
                  updateColorFail(state, action: PayloadAction<string>) {
                    state.updateColorStatus = action.payload;
                  },


                    // -----
 
  getReportData(state) {
    state.getReportDataStatus = "loading";
  },
  getReportDataSuccess(state, action: PayloadAction<IApiResponse<any>>) {




      state.getReportDataStatus = "";
  },
  getReportDataFail(state, action: PayloadAction<string>) {
    state.getReportDataStatus = action.payload;
  },
               
 


},
});


// Thunk function


export default allSlice.reducer;



