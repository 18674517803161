import { Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks";
import _Header from "../navigation/Header";
import _Sider from "../navigation/Sider";

const { Content } = Layout;

const Wraper: React.FC = () => {
  const auth: boolean = useAppSelector(state => !!state.userReducer.user.token.access);
  const location = useLocation();
  let reportbro = location.pathname.includes("reportbro")
  return (
    <>
      {(auth && !reportbro) && <_Sider />}
      <Layout
        className="containerWraperMinHeight"
        style={{ marginLeft: auth && !reportbro ? 200 : 0 }}
      >
        { auth && <_Header authed={auth} /> }
        <Content className="site-layout" >
          <div 
            className="site-layout-background" 
            style={{ 
              position: "relative",
              padding:  reportbro ? 0 : 24, 
              minHeight: auth ? 
                'calc(100vh - 64px - 48px)' :
                'calc(100vh - 48px)'
              }}
          >
            <Outlet />
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default Wraper;
