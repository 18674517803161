import { Checkbox } from "antd";
import React, { useEffect } from "react";


interface IProps{
    onChange: (value: boolean) => void,
    defaultValue: boolean,
    value: boolean
}

const CustomCheckbox:React.FC<IProps> = ({onChange, defaultValue, value}) => {

    const [check, set_check] = React.useState<boolean>(defaultValue)

    useEffect(() => {
        set_check(value)
    }
    , [value])

    return   <Checkbox
    checked={check}
    onChange={() => {
        set_check(!check)
        onChange(!check)
    }}
    style={{ marginLeft: 10 }}
  />
}

export default CustomCheckbox;